/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlankMaker from '../Components/BlankMaker';
import CustomTable from '../Components/CustomTable';
import DarkButton from '../Components/DarkButton';
import DivInTable from '../Components/DivInTable';
import LoadMoreButton from '../Components/LoadMoreButton';
import SelectWindow from '../Components/SelectWindow';
import styles from '../Css/Main.module.css';
import { useReserveState, useAdminList } from '../Middle/useETC';
import useInfiniteLoad from '../Middle/useInfiniteLoad';
import useLogin from '../Middle/useLogin';

const PAGE_SIZE = 10;

function Main() {
  const { getLoginConfig } = useLogin();
  const navigate = useNavigate();
  const [URL, setURL] = useState(`/api/admin/alert?limit=${PAGE_SIZE}`);
  const [SearchParam, setSearchParam] = useState({
    start_date: '',
    end_date: '',
    is_confirmed: -1,
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'type': string,
    'name': string,
    'title': string,
    'is_confirmed': number,
    'created_at': string,
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'alert',
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    query.append('limit', `${PAGE_SIZE}`);
    const checkAndAppend = (txt: keyof typeof SearchParam) => {
      const tmp = SearchParam[txt];
      if (typeof tmp === 'string' && tmp.trim().length > 0) {
        query.append(txt, tmp);
      } else if (tmp !== -1) {
        query.append(txt, `${tmp}`);
      }
    };
    checkAndAppend('start_date');
    checkAndAppend('end_date');
    if (SearchParam.is_confirmed !== -1) query.append('is_confirmed', SearchParam.is_confirmed === 1 ? 'true' : 'false');
    setURL(`/api/admin/alert?${query.toString()}`);
  };
  const alertList = [
    ['2022-02-20', '예약접수', '알프스', '[대구출발] 제주도 가을 차귀도 억새밭 3박4일 노팁노옵션', true],
    ['2022-02-19', '질문등록', '여행사', '여행 관련 문의 드립니다.', false],
    ['2022-02-18', '예약접수', '테스트', '[대구출발] 제주도 메밀꽃축제 3박4일', false],
    ['2022-02-17', '회원가입', '홍길동', '홍길동(gildong)님이 회원가입 하셨습니다.', true],
  ];
  navigate('/user/list');
  return (
    <div className={styles.mainDiv}>
      <SelectWindow leftWidth={40}>
        <div>
          <div>
            <div>읽음</div>
            <div>
              <input type="radio" checked={SearchParam.is_confirmed === -1} onClick={() => setSearchParam({ ...SearchParam, is_confirmed: -1 })} />
              <label htmlFor="radioAll">전체</label>
              <input type="radio" checked={SearchParam.is_confirmed === 1} onClick={() => setSearchParam({ ...SearchParam, is_confirmed: 1 })} />
              <label htmlFor="radioAll">읽음</label>
              <input type="radio" checked={SearchParam.is_confirmed === 0} onClick={() => setSearchParam({ ...SearchParam, is_confirmed: 0 })} />
              <label htmlFor="radioAll">읽지 않음</label>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>일자</div>
            <div>
              <input type="date" value={SearchParam.start_date} onChange={(e) => setSearchParam({ ...SearchParam, start_date: e.target.value })} />
              <span>~</span>
              <input type="date" value={SearchParam.end_date} onChange={(e) => setSearchParam({ ...SearchParam, end_date: e.target.value })} />
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={100} />
      <CustomTable>
        <thead>
          <tr>
            <td>일자</td>
            <td>분류</td>
            <td>고객명</td>
            <td>제목</td>
            <td>확인여부</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((d) => (
              <tr>
                <td>{d.created_at}</td>
                <td>{d.type}</td>
                <td>{d.name}</td>
                <td>{d.title}</td>
                <td>
                  <DivInTable>
                    <div
                      className={`${styles.tableRead} ${d.is_confirmed === 1 ? styles.tableReadYes : styles.tableReadNo}`}
                      onClick={async () => {
                        try {
                          const res = await axios.patch<{statusCode: number}>(`/api/admin/alert/${d.idx}`, {}, getLoginConfig());
                          const { statusCode } = res.data;
                          if (statusCode !== 201) throw Error;
                          mutate();
                        } catch {
                          alert('오류가 발생했습니다.');
                        }
                      }}
                    >
                      {d.is_confirmed === 1 ? '읽음' : '읽지 않음'}
                    </div>
                  </DivInTable>
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default Main;
