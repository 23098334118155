/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/Setting.module.css';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function Popular() {
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/setting/promotion/good?limit=${PAGE_SIZE}`);
  const [SearchParam, setSearchParam] = useState({
    title: '',
  });
  const [Show, setShow] = useState(false);
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'title': string,
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'goods',
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    const {
      title,
    } = SearchParam;
    query.append('limit', `${PAGE_SIZE}`);
    if (title.trim().length > 0) query.append('title', title.trim());
    setURL(`/api/setting/promotion/good?${query.toString()}`);
  };
  const { data, mutate: dataMutate } = useSWR('/api/setting/promotion', async (url) => {
    const res = await axios.get<{result: {
      good_idx: number
      title: string
      thumb_image: string
    }[]}>(url, getLoginConfig());
    return res.data.result;
  });
  const modalOn = () => {
    setSearchParam({ title: '' });
    makeURL();
    setShow(true);
  };
  const deleteGood = async (idx: number) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/setting/promotion/${idx}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      dataMutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const addGood = async (idx: number) => {
    try {
      const res = await axios.post<{statusCode: number}>(`/api/setting/promotion/${idx}`, {}, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      dataMutate();
      setShow(false);
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.mainDiv}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.bannerModal}>
          <div className={styles.modalSearch}>
            상품명
            <ThemeInput value={SearchParam.title} onChange={(e) => setSearchParam({ title: e.target.value })} style={{ width: '300px', height: '36px' }} />
            <DarkButton width={55} height={36} onClick={makeURL}>검색</DarkButton>
          </div>
          <BlankMaker height={30} />
          <div className={styles.modalResult}>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>No.</td>
                  <td>상품명</td>
                </tr>
              </thead>
              <tbody>
                {
                  issues.map((d) => (
                    <tr>
                      <td>{d.idx}</td>
                      <td><Link to="" onClick={() => addGood(d.idx)}>{d.title}</Link></td>
                    </tr>
                  ))
                }
              </tbody>
            </CustomTable>
            <LoadMoreButton {...{
              isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
            }}
            />
          </div>
        </div>
      </ModalWrapper>
      <InnerTitle>인기 상품 관리</InnerTitle>
      <BlankMaker height={24} />
      <div className={styles.allLeft}><DarkButton width={80} bg="#3386FF" onClick={modalOn}>등록</DarkButton></div>
      <BlankMaker height={15} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>이미지</td>
            <td>상품명</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d) => (
              <tr>
                <td>{d.good_idx}</td>
                <td>
                  <DivInTable>
                    <div className={styles.tableImg}>
                      <img src={d.thumb_image} alt="" />
                    </div>
                  </DivInTable>
                </td>
                <td>{d.title}</td>
                <td>
                  <DarkButton width={50} onClick={() => deleteGood(d.good_idx)}>삭제</DarkButton>
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

export default Popular;
