import axios from 'axios';
import React from 'react';
import styles from '../Css/Login.module.css';
import useLogin from '../Middle/useLogin';

function Login() {
  const { loginMutate } = useLogin();
  return (
    <div className={styles.loginDiv}>
      <img src="/logo.png" alt="" />
      <div className={styles.loginTitle}>로그인</div>
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          const id: string = (e.currentTarget[0] as any).value;
          const pw: string = (e.currentTarget[1] as any).value;
          try {
            const res = await axios.post<{result: {token: {accessToken: string}}}>('/api/admin/sign-in', {
              id,
              password: pw,
            });
            const token = res.data.result.token.accessToken;
            if (!token) throw Error;
            localStorage.setItem('alps_admin', token);
            loginMutate();
          } catch {
            alert('아이디 또는 비밀번호가 일치하지 않습니다.');
          }
        }}
      >
        <div className={styles.loginInfoDiv}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
          <input type="text" />
        </div>
        <div className={styles.loginInfoDiv}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <g fill="none">
              <path d="M0 0h24v24H0V0z" />
              <path d="M0 0h24v24H0V0z" opacity=".87" />
            </g>
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
          </svg>
          <input type="password" />
        </div>
        <button type="submit">관리자 로그인</button>
      </form>
    </div>
  );
}

export default Login;
