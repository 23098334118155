import React, { CSSProperties, ReactChild } from 'react';
import styles from '../Css/SelectWindow.module.css';
import argsToPX from '../Middle/argsToPX';

function SelectWindow(props: {
  children?: ReactChild|ReactChild[],
  leftWidth: string|number
}) {
  const { children, leftWidth } = props;
  const tmpStyle = { '--leftWidth': argsToPX(leftWidth) } as CSSProperties;
  return (
    <div className={styles.selectWindowDiv} style={tmpStyle}>
      {children}
    </div>
  );
}

export default SelectWindow;
