import axios from 'axios';
import useSWR from 'swr';

export interface useNewDropdownReturnI {
  region: {
      idx: number;
      region: string;
  }[];
  theme: {
      idx: number;
      theme: string;
  }[];
  channels: {
    idx: number;
    channels: string;
  }[];
  group: {
    idx: number;
    title: string;
  }[];
}

export function useNewDropdown() {
  const { data } = useSWR('NEW_DROPDOWN', async () => {
    const regionGet = axios.get<{ result: { idx: number, region: string }[]}>('/api/admin/offuser/drop/region');
    const themeGet = axios.get<{ result: { idx: number, theme: string }[]}>('/api/admin/offuser/drop/travel');
    const channelsGet = axios.get<{ result: { idx: number, channels: string }[]}>('/api/admin/offuser/drop/channels');
    const groupGet = axios.get<{ result: { idx: number, title: string }[]}>('/api/admin/offuser/drop/group');
    const datas = (await Promise.all([
      regionGet, themeGet, channelsGet, groupGet])).map((t) => t.data.result);
    return {
      region: datas[0] as { idx: number, region: string }[],
      theme: datas[1] as { idx: number, theme: string }[],
      channels: datas[2] as { idx: number, channels: string }[],
      group: datas[3] as { idx: number, title: string }[],
    };
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return data || {
    region: [], theme: [], channels: [], group: [],
  };
}

export default useNewDropdown;
