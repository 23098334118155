/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BlankMaker from '../../../Components/BlankMaker';
import CustomTable from '../../../Components/CustomTable';
import DarkButton from '../../../Components/DarkButton';
import InnerTitle from '../../../Components/InnerTitle';
import LoadMoreButton from '../../../Components/LoadMoreButton';
import SelectWindow from '../../../Components/SelectWindow';
import ThemeInput from '../../../Components/ThemeInput';
import styles from '../../../Css/ReservationList.module.css';
import sstyles from '../../../Css/EmployeeMyInfo.module.css';
import { useAdminList, useReserveState } from '../../../Middle/useETC';
import useInfiniteLoad from '../../../Middle/useInfiniteLoad';
import useLogin from '../../../Middle/useLogin';
import info from './info';

const PAGE_SIZE = 10;

function NoticeList() {
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/admin/board/${info.url}?limit=${PAGE_SIZE}`);
  const { data: reserveState } = useReserveState();
  const { data: adminData } = useAdminList();
  const [SearchParam, setSearchParam] = useState({
    name: '',
    keyword: '',
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'title': string,
    author: string,
    created_at: string,
    views: number
  }>(
    (index) => `${URL}&page=${index + 1}`,
    info.url,
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    query.append('limit', `${PAGE_SIZE}`);
    const checkAndAppend = (txt: keyof typeof SearchParam) => {
      const tmp = SearchParam[txt];
      if (typeof tmp === 'string' && tmp.trim().length > 0) {
        query.append(txt, tmp);
      }
    };
    Object.keys(SearchParam).map((key) => checkAndAppend(key as keyof typeof SearchParam));
    setURL(`/api/admin/board/${info.url}?${query.toString()}`);
  };
  const onDelete = async (idx: number) => {
    if (confirm('삭제하시겠습니까?')) {
      try {
        const res = await axios.delete(`/api/admin/board/${info.url}/${idx}`, getLoginConfig());
        alert('삭제했습니다.');
        mutate();
      } catch {
        alert('오류가 발생했습니다.');
      }
    }
  };
  return (
    <div className={styles.reservationList}>
      <InnerTitle>{info.name}</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={100}>
        <div>
          <div>
            <div>검색어</div>
            <div><ThemeInput value={SearchParam.keyword} onChange={(e) => setSearchParam({ ...SearchParam, keyword: e.target.value })} style={{ width: '500px', height: '36px' }} type="text" /></div>
          </div>
        </div>
        <div>
          <div>
            <div>작성자</div>
            <div><ThemeInput value={SearchParam.name} onChange={(e) => setSearchParam({ ...SearchParam, name: e.target.value })} style={{ width: '500px', height: '36px' }} type="text" /></div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={80} />
      <div className={sstyles.toEnd}>
        <Link to="./new"><DarkButton width={80} bg="#3386FF">추가</DarkButton></Link>
      </div>
      <BlankMaker height={15} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>제목</td>
            <td>작성자</td>
            <td>조회수</td>
            <td>작성일</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((d) => (
              <tr>
                <td>{d.idx}</td>
                <td><Link to={`./${d.idx}`}>{d.title}</Link></td>
                <td>{d.author}</td>
                <td>{d.views.toLocaleString('ko-KR')}</td>
                <td>{d.created_at}</td>
                <td>
                  {
                    (d.idx !== 18 && d.idx !== 19 && d.idx !== 20 && d.idx !== 21) ? (
                      <DarkButton
                        width={50}
                        height={36}
                        onClick={() => onDelete(d.idx)}
                      >
                        삭제
                      </DarkButton>
                    ) : '삭제불가'
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default NoticeList;
