/* eslint-disable camelcase */
import React, { useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../../Css/NewUserTravel.module.css';
import InnerTitle from '../../Components/InnerTitle';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import BlankMaker from '../../Components/BlankMaker';
import DarkButton from '../../Components/DarkButton';
import CustomTable from '../../Components/CustomTable';
import ModalWrapper from '../../Components/ModalWrapper';
import deleteBlank from '../../Middle/deleteBlank';
import useLogin from '../../Middle/useLogin';
import { NewUserListInfoI } from './NewUserList';
import NewUserAdd from './NewUserAdd';

export function NewUserTravel() {
  const { getLoginConfig } = useLogin();
  const navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [AddShow, setAddShow] = useState(false);
  const [TravelInfo, setTravelInfo] = useState({ travel_name: '', travel_date: '' });
  const [Selected, setSelected] = useState<{
     name: string, mobile: string, idx: number, travel_count: number
    }[]>([]);
  const [SearchParam, setSearchParam] = useState({ name: '', mobile: '' });
  const [SearchedParam, setSearchedParam] = useState({ name: '', mobile: '' });
  const urlparam = new URLSearchParams();
  const deleted = deleteBlank({ str: ['name', 'mobile'], num: ['page', 'limit'] }, { ...SearchedParam, page: 1, limit: 100 });
  Object.keys(deleted).map((t) => urlparam.set(t, `${deleted[t]}`));
  const { data: userData } = useSWR(`/api/admin/offuser?${urlparam.toString()}`, async (url) => {
    const d = await axios.get<{result: NewUserListInfoI[]}>(url, getLoginConfig());
    return d?.data?.result || [];
  });
  return (
    <div className={styles.NewUserTravelDiv}>
      <InnerTitle>여행기록 등록</InnerTitle>
      <BlankMaker height={30} />
      <div className={styles.inputsDiv}>
        <div>여행명</div>
        <ThemeInput type="text" value={TravelInfo.travel_name} onChange={(e) => setTravelInfo((now) => ({ ...now, travel_name: e.target.value }))} />
        <p />
        <div>여행일</div>
        <ThemeInput type="date" value={TravelInfo.travel_date} onChange={(e) => setTravelInfo((now) => ({ ...now, travel_date: e.target.value }))} />
      </div>
      <BlankMaker height={30} />
      <div className={styles.buttonsDiv}>
        <DarkButton onClick={() => setAddShow(true)} width={140}>신규 회원 추가</DarkButton>
        <DarkButton onClick={() => setShow(true)}>회원 추가</DarkButton>
        <DarkButton
          bg="#3386FF"
          onClick={async () => {
            if (Selected.length === 0) {
              alert('선택된 회원이 없습니다.');
              return;
            }
            const { travel_name, travel_date } = TravelInfo;
            if (travel_name === '' || travel_date === '') {
              alert('여행명과 여행일 모두 공란이 아니어야 합니다.');
              return;
            }
            const d = await axios.post<{statusCode: number}>('/api/admin/offuser/travel', {
              travel_date, travel_name, user_arr: Selected.map((t) => t.idx),
            }, getLoginConfig());
            if (d.data.statusCode !== 201) {
              alert('오류가 발생했습니다.');
              return;
            }
            alert('여행기록을 등록했습니다.');
            navigate('/user/list');
          }}
        >
          등록
        </DarkButton>
      </div>
      <BlankMaker height={30} />
      <CustomTable>
        <thead>
          <tr>
            <td>이름</td>
            <td>전화번호</td>
            <td>여행횟수</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            Selected.map((t) => (
              <tr>
                <td>{t.name}</td>
                <td>{(t?.mobile || '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                <td>{t.travel_count}</td>
                <td>
                  <DarkButton
                    width={60}
                    onClick={() => setSelected((now) => now.filter((v) => v.idx !== t.idx))}
                  >
                    삭제
                  </DarkButton>

                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      {
        AddShow && (
          <ModalWrapper Show={AddShow} onShowOff={() => setAddShow(false)}>
            <div className={styles.modalDiv}>
              <NewUserAdd onAdd={(name, phone, id) => {
                setSelected((t) => ([...t, {
                  name, mobile: phone, idx: id, travel_count: 0,
                }]));
                setAddShow(false);
              }}
              />
            </div>
          </ModalWrapper>
        )
      }
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.modalDiv}>
          <div className={styles.modalWindow}>
            <div>이름</div>
            <ThemeInput type="text" onChange={(e) => setSearchParam((before) => ({ ...before, name: e.target.value }))} />
            <div>전화번호</div>
            <ThemeInput type="tel" onChange={(e) => setSearchParam((before) => ({ ...before, mobile: e.target.value }))} />
          </div>
          <DarkButton onClick={() => setSearchedParam(SearchParam)}>검색</DarkButton>
          <div className={styles.modalTableWrapper}>
            <CustomTable>
              <thead>
                <tr>
                  <td>이름</td>
                  <td>전화번호</td>
                  <td>여행횟수</td>
                  <td>추가</td>
                </tr>
              </thead>
              <tbody>
                {
                  userData && userData.map((t) => (
                    <tr>
                      <td>{t.name}</td>
                      <td>{(t?.mobile || '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                      <td>{t?.travel_count || '0'}</td>
                      <td>
                        <DarkButton
                          width={60}
                          onClick={() => setSelected((now) => {
                            const isDup = now.reduce(
                              (prev, curr) => (prev || curr.idx === t.idx),
                              false,
                            );
                            if (isDup) return now;
                            return [...now, {
                              idx: t.idx, name: t.name, mobile: t?.mobile || '', travel_count: t?.travel_count || 0,
                            }];
                          })}
                        >
                          추가
                        </DarkButton>

                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </CustomTable>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
}

export default NewUserTravel;
