import axios from 'axios';
import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import { useGrade } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import styles from '../../Css/UserInfo.module.css';

export interface userInfoInfoI {
  idx: number
  name: string
  english_last_name: string
  english_first_name: string
  email: string
  phone: string
  userid: string
  gender: number | null | undefined
  birth: string | null | undefined
  created_at: string
  recent_sign_date: string | null | undefined
  sign_count: number
  tour_count: number
  grade: number
  mileage: number
  travel_type: number
  travel_goal: number
  email_check: number
  sms_check: number
  memo: string
}

export interface userInfoChangeI {
  name: string
  password: string | null | undefined
  mobile: string
  english_last_name: string | null | undefined
  english_first_name: string | null | undefined
  email: string | null | undefined
  gender: number | null | undefined
  birth: string | null | undefined
  tour_count: number | null | undefined
  sms_check: number | null | undefined
  email_check: number | null | undefined
  travel_type: number | null | undefined
  travel_goal: number | null | undefined
  memo: string | null | undefined
  grade: number
}

function UserInfoInfo(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const { data: gradeData } = useGrade();
  const [NewData, setNewData] = useState<userInfoChangeI | null>(null);
  const [Show, setShow] = useState(false);
  const { data, mutate } = useSWR(`/api/admin/user/${idx}`, async (url) => {
    const res = await axios.get<{result: userInfoInfoI}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: mileageData, mutate: mileageMutate } = useSWR(`/api/admin/user/${idx}/mileage`, async (url) => {
    const res = await axios.get<{result: {
      total: number,
      mileage: {
        amount: number,
        content: string,
        created_at: string
      }[]
    }}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [MileInfo, setMileInfo] = useState({
    save: 1,
    amount: 0,
    content: '',
  });
  useEffect(() => {
    if (data) {
      setNewData({
        birth: data.birth && data.birth.slice(0, 10),
        password: '',
        mobile: data.phone,
        name: data.name,
        english_last_name: data.english_last_name,
        english_first_name: data.english_first_name,
        email: data.email,
        gender: data.gender,
        tour_count: data.tour_count,
        sms_check: data.sms_check,
        email_check: data.email_check,
        travel_goal: data.travel_goal,
        travel_type: data.travel_type,
        memo: data.memo,
        grade: data.grade,
      });
    }
  }, [data]);
  useEffect(() => {
    setMileInfo({
      save: 1, amount: 0, content: '',
    });
  }, [Show]);
  if (!data || !NewData) return <div />;
  const onNext = async () => {
    try {
      const res = await axios.put<{statusCode: number}>(`/api/admin/user/${idx}`, {
        ...NewData,
        grade: NewData.grade === -1 ? null : NewData.grade,
        gender: NewData.gender === -1 ? null : NewData.gender,
        password: (
          !NewData.password || NewData.password.trim().length === 0
        ) ? null : NewData.password,
      }, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.mainDiv} ${styles.infoDiv}`}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.modalDiv}>
          <InnerTitle>적립금 내역</InnerTitle>
          <BlankMaker height={25} />
          <div className={styles.tableWrapper}>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>내용</td>
                  <td>금액</td>
                  <td>일시</td>
                </tr>
              </thead>
              <tbody>
                {
                  mileageData?.mileage.map((d) => (
                    <tr>
                      <td>{d.content || ''}</td>
                      <td>{`${d.amount > 0 ? '+' : ''}${d.amount.toLocaleString()}`}</td>
                      <td>{d.created_at}</td>
                    </tr>
                  ))
                }
                <tr className={styles.tableEnding}>
                  <td>총 합계</td>
                  <td colSpan={2}>{`${mileageData?.total.toLocaleString()}`}</td>
                </tr>
              </tbody>
            </CustomTable>
          </div>
          <BlankMaker height={30} />
          <LeftRight leftWidth={50}>
            <div>
              <div>
                <div>구분</div>
                <div>
                  <select
                    value={MileInfo.save}
                    onChange={(e) => setMileInfo({
                      ...MileInfo, save: parseInt(e.target.value, 10),
                    })}
                  >
                    <option value={1}>적립</option>
                    <option value={0}>사용</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>내용</div>
                <div>
                  <ThemeInput
                    value={MileInfo.content || ''}
                    onChange={(e) => setMileInfo({
                      ...MileInfo,
                      content: e.target.value,
                    })}
                    style={{ width: '300px', height: '36px' }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>금액</div>
                <div>
                  <ThemeInput
                    value={MileInfo.amount || 0}
                    onChange={(e) => setMileInfo({
                      ...MileInfo,
                      amount: parseInt(e.target.value, 10),
                    })}
                    style={{ width: '300px', height: '36px' }}
                    type="number"
                  />
                </div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={30} />
          <DarkButton onClick={async () => {
            try {
              const res = await axios.post<{statusCode: number}>(`/api/admin/user/${idx}/mileage`, MileInfo, getLoginConfig());
              const { statusCode } = res.data;
              if (statusCode !== 201) throw Error;
              alert('추가했습니다.');
              mutate();
              mileageMutate();
            } catch {
              alert('오류가 발생했습니다.');
            }
          }}
          >
            추가
          </DarkButton>
        </div>
      </ModalWrapper>
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>이름</div>
            <div>
              <ThemeInput
                value={NewData.name}
                onChange={(e) => setNewData({ ...NewData, name: e.target.value })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>영문 성</div>
            <div>
              <ThemeInput
                value={NewData.english_last_name || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  english_last_name: e.target.value.toLowerCase(),
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
          <div>
            <div>영문 이름</div>
            <div>
              <ThemeInput
                value={NewData.english_first_name || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  english_first_name: e.target.value.toLowerCase(),
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>아이디</div>
            <div>
              <ThemeInput
                value={data.userid}
                disabled
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
          <div>
            <div>비밀번호</div>
            <div>
              <ThemeInput
                value={NewData.password || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  password: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>성별</div>
            <div>
              <select
                value={typeof NewData.gender === 'number' ? NewData.gender : -1}
                onChange={(e) => setNewData({
                  ...NewData,
                  gender: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>없음</option>
                <option value={1}>남</option>
                <option value={0}>여</option>
              </select>
            </div>
          </div>
          <div>
            <div>생년월일</div>
            <div>
              <ThemeInput
                value={NewData.birth || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  birth: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="date"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>전화번호</div>
            <div>
              <ThemeInput
                value={NewData.mobile || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  mobile: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="tel"
              />
            </div>
          </div>
          <div>
            <div>이메일</div>
            <div>
              <ThemeInput
                value={NewData.email || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  email: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="email"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>가입일자</div>
            <div>
              <ThemeInput
                value={data.created_at || '-'}
                disabled
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
          <div>
            <div>최근 로그인</div>
            <div>
              <ThemeInput
                value={data.recent_sign_date || '-'}
                disabled
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>로그인 횟수</div>
            <div>
              <ThemeInput
                value={data.sign_count || '-'}
                disabled
                style={{ width: '200px', height: '36px' }}
                type="number"
              />
            </div>
          </div>
          <div>
            <div>여행 횟수</div>
            <div>
              <ThemeInput
                value={NewData.tour_count || 0}
                onChange={(e) => setNewData({
                  ...NewData,
                  tour_count: parseInt(e.target.value, 10),
                })}
                style={{ width: '200px', height: '36px' }}
                type="number"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              적립금
              <BlankMaker height={10} />
              <DarkButton width="100%" onClick={() => setShow(true)}>내역 보기</DarkButton>
            </div>
            <div>
              <ThemeInput
                value={data.mileage || '-'}
                disabled
                style={{ width: '200px', height: '36px' }}
                type="number"
              />
            </div>
          </div>
          <div>
            <div>등급</div>
            <div>
              <select
                value={typeof NewData.grade === 'number' ? NewData.grade : -1}
                onChange={(e) => setNewData({
                  ...NewData,
                  grade: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>없음</option>
                {
                  gradeData?.idxList.map((i) => (
                    <option value={i}>{gradeData[i].title}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>이메일 수신</div>
            <div>
              <select
                value={typeof NewData.email_check === 'number' ? NewData.email_check : 0}
                onChange={(e) => setNewData({
                  ...NewData,
                  email_check: parseInt(e.target.value, 10),
                })}
              >
                <option value={0}>동의 안함</option>
                <option value={1}>동의</option>
              </select>
            </div>
          </div>
          <div>
            <div>SMS 수신</div>
            <div>
              <select
                value={typeof NewData.sms_check === 'number' ? NewData.sms_check : 0}
                onChange={(e) => setNewData({
                  ...NewData,
                  sms_check: parseInt(e.target.value, 10),
                })}
              >
                <option value={0}>동의 안함</option>
                <option value={1}>동의</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>메모</div>
            <div>
              <textarea
                value={NewData.memo || ''}
                onChange={(e) => setNewData({ ...NewData, memo: e.target.value })}
                style={{
                  width: '100%', height: '100px', fontFamily: 'inherit', marginRight: '100px',
                }}
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={onNext}>수정</DarkButton>
    </div>
  );
}

export default UserInfoInfo;
