import axios from 'axios';
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import Menu from './Components/Menu';
import pageMap from './Meta/PageMap';
import useLogin from './Middle/useLogin';
import NoticeLinker from './Pages/Board/Notice/Linker';
import FAQLinker from './Pages/Board/FAQ/Linker';
import ReviewLinker from './Pages/Board/Review/Linker';
import QNALinker from './Pages/Board/QNA/Linker';
import EmployeeBoardContent from './Pages/Employee/EmployeeBoardContent';
import EmployeeBoardEdit from './Pages/Employee/EmployeeBoardEdit';
import EmployeeBoardNew from './Pages/Employee/EmployeeBoardNew';
import EmployeeScheduleInfo from './Pages/Employee/EmployeeScheduleInfo';
import ExpenseList from './Pages/Expense/ExpenseList';
import GoodInfo from './Pages/Good/GoodInfo';
import GoodInfoAdd from './Pages/Good/GoodInfoAdd';
import GoodInfoDate from './Pages/Good/GoodInfoDate';
import GoodList from './Pages/Good/GoodList';
import HotelInfo from './Pages/Hotel/HotelInfo';
import Login from './Pages/Login';
import Main from './Pages/Main';
import ConsultingInfo from './Pages/Reservation/ConsultingInfo';
import ConsultingList from './Pages/Reservation/ConsultingList';
import ReservationInfo from './Pages/Reservation/ReservationInfo';
import ReservationList from './Pages/Reservation/ReservationList';
import SightInfo from './Pages/Sight/SightInfo';
import UserInfo from './Pages/User/UserInfo';
import UserList from './Pages/User/UserList';
import Logout from './Pages/Logout';
import Layout from './Pages/Layout';

function App() {
  useEffect(() => {
    axios.defaults.baseURL = 'https://api.alpstour.co.kr';
  }, []);
  const { loginData } = useLogin();
  return loginData?.login ? <Layout /> : (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      marginBottom: '100px',
    }}
    >
      {
        loginData?.login ? (
          <div style={{
            width: '100%',
            maxWidth: '1200px',
            display: 'grid',
            gridTemplateColumns: '180px 1fr',
            gridTemplateRows: '120px 1fr',
            columnGap: '30px',
            rowGap: '50px',
          }}
          >
            <div style={{
              gridColumn: '1 / 3',
              gridRow: '1 / 2',
            }}
            >
              <Header />
            </div>
            <div style={{
              gridColumn: '1 / 2',
              gridRow: '2 / 3',
            }}
            >
              <Menu />
            </div>
            <div style={{
              gridColumn: '2 / 3',
              gridRow: '2 / 3',
            }}
            >
              <Routes>
                <Route path="/" element={<Main />} />
                {
                  pageMap.map((cate) => (
                    cate.bigSubList.map((bigSub) => (
                      bigSub.subList.map((sub) => (
                        <Route path={`/${cate.link}/${sub.link}`} element={<sub.Component />} />
                      ))
                    ))
                  )).flat()
                }
                <Route path="/good/list/:idx" element={<GoodInfo />} />
                <Route path="/good/list/:idx/:page" element={<GoodInfo />} />
                <Route path="/hotel/list/:idx" element={<HotelInfo />} />
                <Route path="/hotel/list/:idx/:page" element={<HotelInfo />} />
                <Route path="/sight/list/:idx" element={<SightInfo />} />
                <Route path="/sight/list/:idx/:page" element={<SightInfo />} />
                <Route path="/good/list/:idx/schedule/add" element={<GoodInfoAdd />} />
                <Route path="/good/list/:idx/schedule/:date" element={<GoodInfoDate />} />
                <Route path="/reservation/consulting/list/:idx" element={<ConsultingInfo />} />
                <Route path="/reservation/list/:idx" element={<ReservationInfo />} />
                <Route path="/user/list/:idx" element={<UserInfo />} />
                {
                  ['notice', 'faq', 'review'].map((t) => {
                    let Target = NoticeLinker;
                    if (t === 'notice') Target = NoticeLinker;
                    if (t === 'faq') Target = FAQLinker;
                    if (t === 'review') Target = ReviewLinker;
                    return (
                      <>
                        <Route path={`/board/${t}/:idx`} element={<Target type="content" />} />
                        <Route path={`/board/${t}/:idx/edit`} element={<Target type="edit" />} />
                        <Route path={`/board/${t}/new`} element={<Target type="new" />} />
                      </>
                    );
                  })
                }
                <Route path="/board/qna/:idx" element={<QNALinker type="content" />} />
                <Route path="/board/qna/:idx/edit" element={<QNALinker type="edit" />} />
                <Route path="/board/qna/:idx/answer" element={<QNALinker type="new" />} />
                <Route path="/employee/board/:idx" element={<EmployeeBoardContent />} />
                <Route path="/employee/board/:idx/edit" element={<EmployeeBoardEdit />} />
                <Route path="/employee/board/new" element={<EmployeeBoardNew />} />
                <Route path="/employee/schedule/:date" element={<EmployeeScheduleInfo />} />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Login />
        )
      }

    </div>
  );
}

export default App;
