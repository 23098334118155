/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios from 'axios';
import React, { useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import ImageInput from '../../Components/ImageInput';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import LinkList from '../../Components/LinkList';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/Setting.module.css';
import useLogin from '../../Middle/useLogin';

export interface BannerI {
  idx: number
  title: string
  sequence_number: number
  link: string
  image: string
  is_used: number
  created_at: string
}

function TopBanner() {
  const { getLoginConfig } = useLogin();
  const [Type, setType] = useState<'web'|'mobile'>('web');
  const [AddData, setAddData] = useState({
    image: 'https://alps-tour.s3.ap-northeast-2.amazonaws.com/goods/1646059905387_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5%20%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5.png',
    type: 'web',
    title: '',
    url: 'https://',
    sequence_number: 0,
  });
  const [EditIdx, setEditIdx] = useState(-1);
  const [Show, setShow] = useState(false);
  const { data, mutate } = useSWR(`/api/setting/banner?type=${Type}`, async (url: string) => {
    const res = await axios.get<{result: BannerI[]}>(url, getLoginConfig());
    return res.data.result;
  });
  const changeUse = async (idx: number) => {
    try {
      const res = await axios.patch<{statusCode: number}>(`/api/setting/banner/${idx}/state?type=${Type}`, {}, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const deleteBanner = async (idx: number) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/setting/banner/${idx}?type=${Type}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const openModal = () => {
    setEditIdx(-1);
    setAddData({
      image: 'https://alps-tour.s3.ap-northeast-2.amazonaws.com/goods/1646059905387_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5%20%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5.png',
      type: Type,
      title: '',
      url: 'https://',
      sequence_number: 0,
    });
    setShow(true);
  };
  const openEdit = (idx: number, info: typeof AddData) => {
    setEditIdx(idx);
    setAddData(info);
    setShow(true);
  };
  const addBanner = async () => {
    try {
      const res = EditIdx === -1 ? await axios.post<{statusCode: number}>('/api/setting/banner', AddData, getLoginConfig()) : await axios.patch<{statusCode: number}>(`/api/setting/banner/${EditIdx}`, AddData, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      mutate();
      setShow(false);
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.mainDiv}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.bannerModal}>
          <LeftRight leftWidth={70}>
            <div>
              <div>
                <div>유형</div>
                <div>
                  <select
                    value={AddData.type}
                    onChange={(e) => setAddData({
                      ...AddData, type: e.target.value,
                    })}
                  >
                    <option value="web">웹</option>
                    <option value="mobile">모바일</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>이미지</div>
                <div className={styles.imageInputDiv}>
                  <img src={AddData.image} alt="" />
                  <BlankMaker width={10} />
                  <ImageInput onChange={async (e) => {
                    if (!e.target.files) return;
                    try {
                      const formData = new FormData();
                      formData.append('image', e.target.files[0]);
                      const res = await axios.post<{statusCode: number, result: string}>('/api/setting/banner/image', formData, getLoginConfig());
                      if (res.data.statusCode !== 201) throw Error;
                      alert('이미지를 업로드했습니다.');
                      setAddData({ ...AddData, image: res.data.result });
                    } catch {
                      alert('오류가 발생했습니다.');
                    }
                  }}
                  >
                    <DarkButton width={140}>이미지 업로드</DarkButton>
                  </ImageInput>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>제목</div>
                <div>
                  <ThemeInput style={{ width: '100%', height: '36px' }} value={AddData.title} onChange={(e) => setAddData({ ...AddData, title: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>URL</div>
                <div>
                  <ThemeInput style={{ width: '100%', height: '36px' }} value={AddData.url} onChange={(e) => setAddData({ ...AddData, url: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>순서</div>
                <div>
                  <ThemeInput style={{ width: '100px', height: '36px' }} value={AddData.sequence_number} onChange={(e) => setAddData({ ...AddData, sequence_number: parseInt(e.target.value, 10) })} />
                </div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={20} />
          <div>
            웹 배너 사이즈 : 1792x680 / 모바일 배너 사이즈 : 750x410
          </div>
          <BlankMaker height={20} />
          <DarkButton onClick={addBanner}>{EditIdx === -1 ? '등록' : '수정'}</DarkButton>
        </div>
      </ModalWrapper>
      <InnerTitle>상단 배너 관리</InnerTitle>
      <BlankMaker height={30} />
      <LinkList>
        <button
          type="button"
          onClick={() => {
            if (Type !== 'web') setType('web');
          }}
          className={`${Type === 'web' && styles.selectedBtn}`}
        >
          웹
        </button>
        <button
          type="button"
          onClick={() => {
            if (Type !== 'mobile') setType('mobile');
          }}
          className={`${Type === 'mobile' && styles.selectedBtn}`}
        >
          모바일
        </button>
      </LinkList>
      <BlankMaker height={30} />
      <div className={styles.allLeft}>
        <DarkButton width={80} bg="#3386FF" onClick={openModal}>등록</DarkButton>
      </div>
      <BlankMaker height={15} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>사용</td>
            <td>No.</td>
            <td>순서</td>
            <td>이미지</td>
            <td>제목</td>
            <td>URL</td>
            <td>등록일</td>
            <td>수정</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d) => (
              <tr>
                <td style={{ cursor: 'pointer' }} onClick={() => changeUse(d.idx)}>{d.is_used === 1 ? '✅' : '🚫'}</td>
                <td>{d.idx}</td>
                <td>{d.sequence_number}</td>
                <td><DivInTable><div className={styles.tableImg}><img src={d.image} alt="" /></div></DivInTable></td>
                <td>{d.title}</td>
                <td>{d.link}</td>
                <td>{d.created_at}</td>
                <td>
                  <DarkButton
                    width={50}
                    onClick={() => openEdit(d.idx, {
                      image: d.image,
                      type: Type,
                      title: d.title,
                      url: d.link,
                      sequence_number: d.sequence_number,
                    })}
                  >
                    수정
                  </DarkButton>

                </td>
                <td><DarkButton width={50} onClick={() => deleteBanner(d.idx)}>삭제</DarkButton></td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

export default TopBanner;
