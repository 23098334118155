import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/EmployeeMyInfo.module.css';
import useLogin from '../../Middle/useLogin';

export const pwRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;

export interface EmployeeMyInfoI {
  idx: number
  adminid: string
  name: string
  gender: number
  email: string
  mobile: string
  birth: string
  department: string
  position: string
}

export interface EmployeeMyInfoChangeI {
  password: string | null
  name: string
  gender: number
  email: string
  mobile: string
  birth: string
  department: string
  position: string
}

function EmployeeMyInfo() {
  const { getLoginConfig } = useLogin();
  const [NewData, setNewData] = useState<EmployeeMyInfoChangeI | null>(null);
  const { data, mutate } = useSWR('/api/admin/info', async (url) => {
    const res = await axios.get<{result: EmployeeMyInfoI}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (data) {
      setNewData({
        password: '',
        name: data.name,
        gender: data.gender,
        email: data.email,
        mobile: data.mobile,
        birth: data.birth,
        department: data.department,
        position: data.position,
      });
    }
  }, [data]);

  if (!data || !NewData) return <div />;
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>내 정보</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight haveLine leftWidth={70}>
        <div>
          <div>
            <div>성명</div>
            <div>
              <ThemeInput
                value={NewData.name}
                onChange={(e) => setNewData({ ...NewData, name: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>아이디</div>
            <div>
              <ThemeInput disabled value={data.adminid} />
            </div>
          </div>
          <div>
            <div>비밀번호</div>
            <div>
              <ThemeInput
                value={NewData.password || ''}
                onChange={(e) => setNewData({ ...NewData, password: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>성별</div>
            <div>
              <select
                value={NewData.gender}
                onChange={(e) => setNewData({ ...NewData, gender: parseInt(e.target.value, 10) })}
              >
                <option value={1}>남</option>
                <option value={0}>여</option>
              </select>
            </div>
          </div>
          <div>
            <div>생년월일</div>
            <div>
              <ThemeInput
                value={NewData.birth}
                onChange={(e) => setNewData({ ...NewData, birth: e.target.value })}
                type="date"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>전화번호</div>
            <div>
              <ThemeInput
                value={NewData.mobile}
                onChange={(e) => setNewData({ ...NewData, mobile: e.target.value })}
                type="tel"
              />
            </div>
          </div>
          <div>
            <div>이메일</div>
            <div>
              <ThemeInput
                value={NewData.email}
                onChange={(e) => setNewData({ ...NewData, email: e.target.value })}
                type="email"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>부서</div>
            <div>
              <ThemeInput
                value={NewData.department}
                onChange={(e) => setNewData({ ...NewData, department: e.target.value })}
              />
            </div>
          </div>
          <div>
            <div>직급</div>
            <div>
              <ThemeInput
                value={NewData.position}
                onChange={(e) => setNewData({ ...NewData, position: e.target.value })}
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={async () => {
        try {
          const outData = { ...NewData };
          if ((!outData.password) || outData.password.trim().length === 0) {
            outData.password = null;
          } else if (!pwRegExp.test(outData.password)) {
            alert('비밀번호가 규칙에 맞지 않습니다.');
            return;
          }
          const res = await axios.put<{statusCode: number}>('/api/admin/info', outData, getLoginConfig());
          const { statusCode } = res.data;
          if (statusCode !== 201) throw Error;
          alert('수정했습니다.');
          mutate();
        } catch {
          alert('오류가 발생했습니다.');
        }
      }}
      >
        수정
      </DarkButton>
    </div>
  );
}

export default EmployeeMyInfo;
