/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactChild, useRef } from 'react';

function ImageInput(props: {
  children: ReactChild | ReactChild[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const { children, onChange } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  return (
    <div onClick={() => fileInput.current?.click()}>
      <input
        type="file"
        ref={fileInput}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={async (e) => onChange(e)}
      />
      {children}
    </div>
  );
}

export default ImageInput;
