/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import ImageInput from '../../Components/ImageInput';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import LinkList from '../../Components/LinkList';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodInfo.module.css';
import useCategory from '../../Middle/useCategory';
import { useAdminList, useCity, useCountry } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import GoodInfoDefault from './GoodInfoDefault';
import GoodInfoHotel from './GoodInfoHotel';
import GoodInfoImage from './GoodInfoImage';
import GoodInfoSetting from './GoodInfoSetting';
import GoodInfoSight from './GoodInfoSight';
import GoodInfoTable from './GoodInfoTable';

type screenType = 'info'|'image'|'hotel'|'sight'|'table'|'schedule';

export interface goodDefaultInfo {
  first_category: number
  second_category: number|null
  country: number
  city: number|null
  title: string
  description: string
  price: number
  domestic: number
  stay: number
  nights: number
  days: number
  description_perks: string|null
  description_include: string|null
  description_exclude: string|null
  description_notice: string|null
  staff: number
}

const stateToComponent = {
  info: GoodInfoDefault,
  image: GoodInfoImage,
  hotel: GoodInfoHotel,
  sight: GoodInfoSight,
  table: GoodInfoTable,
  schedule: GoodInfoSetting,
};

function GoodInfo() {
  const { idx, page } = useParams();
  const Screen = page || 'info';
  const NowComponent = stateToComponent[Screen as screenType];
  const navigate = useNavigate();
  const setScreen = (a: string) => navigate(`/good/list/${idx}/${a}`);
  if (!idx) return <div />;
  return (
    <div className={styles.goodInfoDiv}>
      <InnerTitle>여행관리</InnerTitle>
      <BlankMaker height={30} />
      <LinkList>
        <button
          type="button"
          onClick={() => { if (Screen !== 'info') setScreen('info'); }}
          className={`${Screen === 'info' && styles.selected}`}
        >
          기본정보
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'image') setScreen('image'); }}
          className={`${Screen === 'image' && styles.selected}`}
        >
          이미지
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'hotel') setScreen('hotel'); }}
          className={`${Screen === 'hotel' && styles.selected}`}
        >
          숙박정보
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'sight') setScreen('sight'); }}
          className={`${Screen === 'sight' && styles.selected}`}
        >
          관광정보
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'table') setScreen('table'); }}
          className={`${Screen === 'table' && styles.selected}`}
        >
          일정표
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'schedule') setScreen('schedule'); }}
          className={`${Screen === 'schedule' && styles.selected}`}
        >
          출발일정관리
        </button>
      </LinkList>
      <BlankMaker height={30} />
      <NowComponent idx={parseInt(idx, 10)} />
    </div>
  );
}

export default GoodInfo;
