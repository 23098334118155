import axios from 'axios';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../../Components/BlankMaker';
import DarkButton from '../../../Components/DarkButton';
import InnerTitle from '../../../Components/InnerTitle';
import LeftRight from '../../../Components/LeftRight';
import styles from '../../../Css/EmployeeMyInfo.module.css';
import useLogin from '../../../Middle/useLogin';
import info from './info';

function BoardContent() {
  const { idx } = useParams();
  const { getLoginConfig } = useLogin();
  const { data } = useSWR(`/api/admin/board/${info.url}/${idx}`, async (url: string) => {
    const res = await axios.get<{result: {title: string, content: string}}>(url, getLoginConfig());
    return res.data.result;
  });
  if (!idx || !data) return <div />;
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>{info.name}</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight leftWidth={40}>
        <div>
          <div>
            <div>제목</div>
            <div className={styles.title}>{data.title}</div>
          </div>
        </div>
        <div>
          <div>
            <div>내용</div>
            <div><div className={`ck-content ${styles.content}`} dangerouslySetInnerHTML={{ __html: data.content }} /></div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <Link to="./edit"><DarkButton>수정</DarkButton></Link>
    </div>
  );
}

export default BoardContent;
