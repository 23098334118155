import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InnerTitle from '../Components/InnerTitle';
import useLogin from '../Middle/useLogin';

function Logout() {
  const { loginData, loginMutate, loginIsValidating } = useLogin();
  const navigate = useNavigate();
  const doLogout = async () => {
    // console.log(loginData, loginIsValidating);
    if (loginData?.login) {
      try {
        const res = await axios.post('/api/admin/sign-out', '', loginData.axiosConfig);
        localStorage.removeItem('alps_admin');
        loginMutate();
      } catch (e) {
        alert('오류가 발생했습니다.');
      }
    }
    if (!loginIsValidating) navigate('/');
  };
  useEffect(() => {
    doLogout();
  }, [loginIsValidating]);

  return (
    <div>
      <InnerTitle>잠시만 기다려주세요</InnerTitle>
    </div>
  );
}

export default Logout;
