/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/ReservationList.module.css';
import { useAdminList, useReserveState } from '../../Middle/useETC';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function ReservationList() {
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/admin/reservation?limit=${PAGE_SIZE}`);
  const { data: reserveState } = useReserveState();
  const { data: adminData } = useAdminList();
  const [SearchParam, setSearchParam] = useState({
    name: '',
    phone: '',
    rsv_status: -1,
    rsv_date_start: '',
    rsv_date_end: '',
    departure_date_start: '',
    departure_date_end: '',
    manager_idx: -1,
    title: '',
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'created_at': string,
    'status': string,
    'rsv_name': string,
    'count': number,
    'good_title': string,
    'departure_date': string,
    'total_price': number
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'reservation',
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    query.append('limit', `${PAGE_SIZE}`);
    const checkAndAppend = (txt: keyof typeof SearchParam) => {
      const tmp = SearchParam[txt];
      if (typeof tmp === 'string' && tmp.trim().length > 0) {
        query.append(txt, tmp);
      } else if (tmp !== -1) {
        query.append(txt, `${tmp}`);
      }
    };
    Object.keys(SearchParam).map((key) => checkAndAppend(key as keyof typeof SearchParam));
    setURL(`/api/admin/reservation?${query.toString()}`);
  };
  return (
    <div className={styles.reservationList}>
      <InnerTitle>예약내역</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={130}>
        <div>
          <div>
            <div>상품명</div>
            <div><ThemeInput value={SearchParam.title} onChange={(e) => setSearchParam({ ...SearchParam, title: e.target.value })} style={{ width: '180px', height: '36px' }} type="text" /></div>
          </div>
        </div>
        <div>
          <div>
            <div>예약자명</div>
            <div><ThemeInput value={SearchParam.name} onChange={(e) => setSearchParam({ ...SearchParam, name: e.target.value })} style={{ width: '180px', height: '36px' }} type="text" /></div>
          </div>
        </div>
        <div>
          <div>
            <div>예약자 전화번호</div>
            <div><ThemeInput value={SearchParam.phone} onChange={(e) => setSearchParam({ ...SearchParam, phone: e.target.value })} style={{ width: '180px', height: '36px' }} type="tel" /></div>
          </div>
        </div>
        <div>
          <div>
            <div>예약상태</div>
            <div>
              <select
                value={SearchParam.rsv_status}
                onChange={(e) => setSearchParam({
                  ...SearchParam,
                  rsv_status: parseInt(e.target.value, 10),
                })}
              >
                <option value="-1">전체</option>
                {
                  reserveState?.idxList.map((i) => (
                    <option value={i}>{reserveState[i].status}</option>))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>예약 접수일</div>
            <div>
              <ThemeInput value={SearchParam.rsv_date_start} onChange={(e) => setSearchParam({ ...SearchParam, rsv_date_start: e.target.value })} style={{ width: '180px', height: '36px' }} type="date" />
              <div className={styles.waveDiv}>~</div>
              <ThemeInput value={SearchParam.rsv_date_end} onChange={(e) => setSearchParam({ ...SearchParam, rsv_date_end: e.target.value })} style={{ width: '180px', height: '36px' }} type="date" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>출발일</div>
            <div>
              <ThemeInput value={SearchParam.departure_date_start} onChange={(e) => setSearchParam({ ...SearchParam, departure_date_start: e.target.value })} style={{ width: '180px', height: '36px' }} type="date" />
              <div className={styles.waveDiv}>~</div>
              <ThemeInput value={SearchParam.departure_date_end} onChange={(e) => setSearchParam({ ...SearchParam, departure_date_end: e.target.value })} style={{ width: '180px', height: '36px' }} type="date" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>담당자</div>
            <div>
              <select
                value={SearchParam.manager_idx}
                onChange={(e) => setSearchParam({
                  ...SearchParam,
                  manager_idx: parseInt(e.target.value, 10),
                })}
              >
                <option value="-1">전체</option>
                {
                  adminData?.idxList.map(
                    (idx) => <option value={idx}>{adminData[idx].name}</option>,
                  )
                }
              </select>
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={100} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>접수일</td>
            <td>상태</td>
            <td>고객명</td>
            <td>인원수</td>
            <td>제목</td>
            <td>출발일</td>
            <td>금액</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((d) => (
              <tr>
                <td>{d.idx}</td>
                <td>{d.created_at}</td>
                <td>{d.status}</td>
                <td>{d.rsv_name}</td>
                <td>{d.count}</td>
                <td><Link to={`./${d.idx}`}>{d.good_title}</Link></td>
                <td>{d.departure_date}</td>
                <td>{`${d.total_price.toLocaleString('ko-kr')}원`}</td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default ReservationList;
