import React, { ReactChild } from 'react';

function DivInTable(props: {
  children: ReactChild | ReactChild[]
}) {
  const { children } = props;
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {children}
    </div>
  );
}

export default DivInTable;
