import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/EmployeeMyInfo.module.css';
import useLogin from '../../Middle/useLogin';

function EmployeeBoardNew() {
  const navigate = useNavigate();
  const [NewData, setNewData] = useState<{title: string, content: string}>({
    title: '', content: '',
  });
  const { getLoginConfig } = useLogin();
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>사내 게시판</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight leftWidth={40}>
        <div>
          <div>
            <div>제목</div>
            <div className={styles.titleInput}>
              <ThemeInput
                value={NewData.title}
                onChange={(e) => setNewData({ ...NewData, title: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>내용</div>
            <div>
              <CustomEditor
                data={NewData.content}
                onChange={(event, editor) => {
                  setNewData({ ...NewData, content: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={async () => {
        try {
          const res = await axios.post<{statusCode: number}>('/api/admin/board/intranet', NewData, getLoginConfig());
          const { statusCode } = res.data;
          if (statusCode !== 201) throw Error;
          alert('등록했습니다.');
          navigate(-1);
        } catch {
          alert('오류가 발생했습니다.');
        }
      }}
      >
        저장
      </DarkButton>
    </div>
  );
}

export default EmployeeBoardNew;
