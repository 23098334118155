import React, { CSSProperties, ReactChild } from 'react';
import styles from '../Css/CustomTable.module.css';
import argsToPX from '../Middle/argsToPX';

function CustomTable(props: {
  children: ReactChild | ReactChild[],
  haveVerticalLine?: boolean,
  eachHeight?: number|string,
}) {
  const { children, haveVerticalLine, eachHeight } = props;
  const tmpStyle = { '--eachHeight': argsToPX(eachHeight) || '45px' } as CSSProperties;
  return (
    <table style={tmpStyle} className={`${styles.customTable} ${haveVerticalLine && styles.verticalLine}`}>
      {children}
    </table>
  );
}

export default CustomTable;
