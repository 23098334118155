import GoodList from '../Pages/Good/GoodList';
import GoodRegister from '../Pages/Good/GoodRegister';
import ConsultingRegister from '../Pages/Reservation/ConsultingRegister';
import ConsultingList from '../Pages/Reservation/ConsultingList';
import ReservationList from '../Pages/Reservation/ReservationList';
import ExpenseList from '../Pages/Expense/ExpenseList';
import ExpenseGood from '../Pages/Expense/ExpenseGood';
import UserList from '../Pages/User/UserList';
import UserDeleted from '../Pages/User/UserDeleted';
import UserRegister from '../Pages/User/UserRegister';
import EmployeeMyInfo from '../Pages/Employee/EmployeeMyInfo';
import EmployeeRegister from '../Pages/Employee/EmployeeRegister';
import EmployeeBoardList from '../Pages/Employee/EmployeeBoardList';
import EmployeeSchedule from '../Pages/Employee/EmployeeSchedule';
import HotelRegister from '../Pages/Hotel/HotelRegister';
import HotelList from '../Pages/Hotel/HotelList';
import SightList from '../Pages/Sight/SightList';
import SightRegister from '../Pages/Sight/SightRegister';
import NoticeList from '../Pages/Board/Notice/NoticeList';
import FAQList from '../Pages/Board/FAQ/FAQList';
import ReviewList from '../Pages/Board/Review/ReviewList';
import QNAList from '../Pages/Board/QNA/QNAList';
import Logout from '../Pages/Logout';
import TopBanner from '../Pages/Setting/TopBanner';
import BottomBanner from '../Pages/Setting/BottomBanner';
import Popular from '../Pages/Setting/Popular';
import Category from '../Pages/Setting/Category';
import Region from '../Pages/Setting/Region';
import Airline from '../Pages/Setting/Airline';
import Company from '../Pages/Setting/Company';
import NewUserList from '../Pages/NewUser/NewUserList';
import NewUserAdd from '../Pages/NewUser/NewUserAdd';
import NewUserTravel from '../Pages/NewUser/NewUserTravel';
import iconList from './iconList';

export const pageMap = [
  {
    name: '상담/예약',
    link: 'reservation',
    icon: iconList.clockLines,
    bigSubList: [
      {
        name: '상담관리',
        subList: [
          {
            name: '상담내역',
            link: 'consulting/list',
            Component: ConsultingList,
          },
          {
            name: '상담등록',
            link: 'consulting/register',
            Component: ConsultingRegister,
          },
        ],
      },
      {
        name: '예약관리',
        subList: [
          {
            name: '예약내역',
            link: 'list',
            Component: ReservationList,
          },
        ],
      },
    ],
  },
  {
    name: '정산',
    link: 'expense',
    icon: iconList.chart,
    bigSubList: [
      {
        name: '정산',
        subList: [
          {
            name: '정산조회',
            link: 'list',
            Component: ExpenseList,
          },
          {
            name: '수익현황',
            link: 'good',
            Component: ExpenseGood,
          },
        ],
      },
    ],
  },
  {
    name: '여행',
    link: 'good',
    icon: iconList.airplane,
    bigSubList: [
      {
        name: '여행',
        subList: [
          {
            name: '여행관리',
            link: 'list',
            Component: GoodList,
          },
          {
            name: '여행등록',
            link: 'register',
            Component: GoodRegister,
          },
        ],
      },
    ],
  },
  {
    name: '호텔',
    link: 'hotel',
    icon: iconList.building,
    bigSubList: [
      {
        name: '호텔',
        subList: [
          {
            name: '호텔관리',
            link: 'list',
            Component: HotelList,
          },
          {
            name: '호텔등록',
            link: 'register',
            Component: HotelRegister,
          },
        ],
      },
    ],
  },
  {
    name: '관광(지)',
    link: 'sight',
    icon: iconList.routing,
    bigSubList: [
      {
        name: '관광지',
        subList: [
          {
            name: '관광지관리',
            link: 'list',
            Component: SightList,
          },
          {
            name: '관광지등록',
            link: 'register',
            Component: SightRegister,
          },
        ],
      },
    ],
  },
  {
    name: '웹회원',
    link: 'webuser',
    icon: iconList.idcard,
    bigSubList: [
      {
        name: '웹회원',
        subList: [
          {
            name: '웹회원조회',
            link: 'list',
            Component: UserList,
          },
          {
            name: '웹회원등록',
            link: 'register',
            Component: UserRegister,
          },
          {
            name: '탈퇴웹회원',
            link: 'deleted',
            Component: UserDeleted,
          },
        ],
      },
    ],
  },
  {
    name: '회원',
    link: 'user',
    icon: iconList.user,
    bigSubList: [
      {
        name: '회원',
        subList: [
          {
            name: '회원조회',
            link: 'list',
            Component: NewUserList,
          },
          {
            name: '회원추가',
            link: 'register',
            Component: NewUserAdd,
          },
          {
            name: '여행기록 등록',
            link: 'trip',
            Component: NewUserTravel,
          },
        ],
      },
    ],
  },
  {
    name: '게시판',
    link: 'board',
    icon: iconList.pencil,
    bigSubList: [
      {
        name: '게시판',
        subList: [
          {
            name: '공지사항',
            link: 'notice',
            Component: NoticeList,
          },
          {
            name: '자주 묻는 질문',
            link: 'faq',
            Component: FAQList,
          },
          {
            name: '1:1 문의',
            link: 'qna',
            Component: QNAList,
          },
          {
            name: '여행후기',
            link: 'review',
            Component: ReviewList,
          },
        ],
      },
    ],
  },
  {
    name: '설정',
    link: 'setting',
    icon: iconList.gear,
    bigSubList: [
      {
        name: '메인페이지',
        subList: [
          {
            name: '배너관리',
            link: 'topbanner',
            Component: TopBanner,
          },
          {
            name: '하단 띠 배너',
            link: 'bottombanner',
            Component: BottomBanner,
          },
          {
            name: '인기 상품 관리',
            link: 'popular',
            Component: Popular,
          },
        ],
      },
      {
        name: '분류 설정',
        subList: [
          {
            name: '카테고리',
            link: 'category',
            Component: Category,
          },
          {
            name: '지역 분류',
            link: 'region',
            Component: Region,
          },
          {
            name: '항공사 관리',
            link: 'airline',
            Component: Airline,
          },
        ],
      },
      {
        name: '기초 설정',
        subList: [
          {
            name: '회사 정보',
            link: 'company',
            Component: Company,
          },
        ],
      },
    ],
  },
  {
    name: '임직원',
    link: 'employee',
    icon: iconList.suitcase,
    bigSubList: [
      {
        name: '임직원',
        subList: [
          {
            name: '내 정보',
            link: 'myinfo',
            Component: EmployeeMyInfo,
          },
          {
            name: '관리자 등록',
            link: 'admin',
            Component: EmployeeRegister,
          },
          {
            name: '사내 게시판',
            link: 'board',
            Component: EmployeeBoardList,
          },
          {
            name: '일정 관리',
            link: 'schedule',
            Component: EmployeeSchedule,
          },
        ],
      },
    ],
  },
];

export default pageMap;
