/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styles from '../Css/ThemeInput.module.css';

function ThemeInput(props: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  return (
    <input {...{ ...props, className: `${props.className || ''} ${styles.themeInput}` }} />
  );
}

export default ThemeInput;
