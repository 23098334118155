import React, { ReactChild } from 'react';
import styles from '../Css/InnerTitle.module.css';

function InnerTitle(props: {
  children: ReactChild | ReactChild[]
}) {
  const { children } = props;
  return (
    <div className={styles.innerTitle}>{children}</div>
  );
}

export default InnerTitle;
