/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import ImageInput from '../../Components/ImageInput';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/Setting.module.css';
import useLogin from '../../Middle/useLogin';

const firstInitial = {
  title: '',
  sequence_number: '',
};

const typeToString = {
  continent: '대륙',
  country: '국가',
  city: '도시',
};

function Comp(props: {
  type: 'continent'|'country'|'city',
  Continent: number|null,
  Country: number|null,
  setContinent: (n: number) => void,
  setCountry: (n: number) => void
}) {
  const {
    type, Continent, Country, setContinent, setCountry,
  } = props;
  const haveUpper = type !== 'continent';
  const Upper = type === 'country' ? Continent : Country;
  const name = typeToString[type];
  const getURL = () => {
    if (type === 'continent') return '/api/setting/region/continent';
    if (type === 'country') return `/api/setting/region/country?continent=${Upper}`;
    return `/api/setting/region/city?country=${Upper}`;
  };
  const { getLoginConfig } = useLogin();
  const [Info, setInfo] = useState({ ...firstInitial });
  const [NewData, setNewData] = useState({ ...firstInitial });
  const [Target, setTarget] = useState(-1);
  const [Show, setShow] = useState(false);
  const { data, mutate } = useSWR(getURL(), async (url) => {
    const res = await axios.get<{result: {
      idx: number
      title: string
      code: string
      state: number
      sequence_number: string
      count: number
    }[]}>(url, getLoginConfig());
    return res.data.result;
  });
  if (!data || (haveUpper && typeof Upper !== 'number')) return <div />;
  const changeUse = async (idx: number) => {
    try {
      const res = await axios.patch<{statusCode: number}>(`/api/setting/region/${idx}/state?type=${type}`, {}, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const addCate = async () => {
    try {
      const dataToSend = haveUpper ? { ...Info, upper_idx: Upper } : { ...Info };
      const res = await axios.post<{statusCode: number}>(`/api/setting/region?type=${type}`, dataToSend, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('등록했습니다.');
      mutate();
      setInfo({ ...firstInitial });
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const editCate = async () => {
    try {
      const res = await axios.put<{statusCode: number}>(`/api/setting/region?type=${type}`, { ...NewData, idx: Target }, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      mutate();
      setShow(false);
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const deleteCate = async (idx: number) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/setting/region/${idx}?type=${type}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const openModal = (i: number) => {
    const now = data[i];
    setNewData({
      title: now.title,
      sequence_number: now.sequence_number,
    });
    setTarget(now.idx);
    setShow(true);
  };
  return (
    <div className={styles.mainDiv}>
      <ModalWrapper
        Show={Show}
        onShowOff={() => setShow(false)}
      >
        <div className={styles.bannerModal}>
          <LeftRight leftWidth={70}>
            <div>
              <div>
                <div>{`${name}명`}</div>
                <div>
                  <ThemeInput style={{ width: '100%', height: '36px' }} value={NewData.title} onChange={(e) => setNewData({ ...NewData, title: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>순서</div>
                <div>
                  <ThemeInput style={{ width: '100px', height: '36px' }} value={NewData.sequence_number} onChange={(e) => setNewData({ ...NewData, sequence_number: e.target.value })} />
                </div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={30} />
          <DarkButton onClick={editCate}>등록</DarkButton>
        </div>
      </ModalWrapper>
      <SelectWindow leftWidth={70}>
        <div>
          <div>
            <div>{`${name}명`}</div>
            <div>
              <ThemeInput value={Info.title} onChange={(e) => setInfo({ ...Info, title: e.target.value })} style={{ width: '100%', height: '36px' }} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>순서</div>
            <div>
              <ThemeInput value={Info.sequence_number} onChange={(e) => setInfo({ ...Info, sequence_number: e.target.value })} style={{ width: '100px', height: '36px' }} />
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={addCate}>등록</DarkButton>
      <BlankMaker height={100} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>사용</td>
            <td>No.</td>
            <td>순서</td>
            <td>{`${name}명`}</td>
            {
              type !== 'city' && (
                <td>{type === 'continent' ? '국가' : '도시'}</td>
              )
            }
            <td>관리</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d, i) => (
              <tr className={`${((type === 'continent' && Continent === d.idx) || (type === 'country' && Country === d.idx)) && styles.nowRow}`}>
                <td style={{ cursor: 'pointer' }} onClick={() => changeUse(d.idx)}>{d.state === 1 ? '✅' : '🚫'}</td>
                <td>{d.idx}</td>
                <td>{d.sequence_number}</td>
                {
                  type !== 'city' ? (
                    <td>
                      <Link
                        onClick={() => {
                          if (type === 'continent') setContinent(d.idx);
                          else setCountry(d.idx);
                        }}
                        to=""
                      >
                        {d.title}
                      </Link>
                    </td>
                  ) : (
                    <td>{d.title}</td>
                  )
                }
                {
                  type !== 'city' && (
                    <td>{d.count}</td>
                  )
                }
                <td>
                  <div className={styles.buttons}>
                    <DarkButton width={60} height={32} bg="#3386FF" onClick={() => openModal(i)}>수정</DarkButton>
                    <DarkButton width={60} height={32} bg="#FF4E40" onClick={() => deleteCate(d.idx)}>삭제</DarkButton>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

function Region() {
  const [Continent, setContinent] = useState<number|null>(null);
  const [Country, setCountry] = useState<number|null>(null);
  useEffect(() => {
    setCountry(null);
  }, [Continent]);
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>지역 분류</InnerTitle>
      <BlankMaker height={24} />
      <div className={styles.lines}>
        <div style={{ width: '33.3%' }}>
          <Comp type="continent" Country={Country} Continent={Continent} setContinent={setContinent} setCountry={setCountry} />
        </div>
        <div style={{ width: '33.3%' }}>
          <Comp type="country" Country={Country} Continent={Continent} setContinent={setContinent} setCountry={setCountry} />
        </div>
        <div style={{ width: '33.3%' }}>
          <Comp type="city" Country={Country} Continent={Continent} setContinent={setContinent} setCountry={setCountry} />
        </div>
      </div>
    </div>
  );
}

export default Region;
