/* eslint-disable react/button-has-type */
import React, { ReactChild } from 'react';
import styles from '../Css/DarkButton.module.css';
import argsToPX from '../Middle/argsToPX';

function DarkButton(props: {
  children?: ReactChild,
  onClick?: () => void,
  width?: string|number
  height?: string|number,
  bg?: string
  type?: 'button' | 'submit' | 'reset',
}) {
  const {
    children, onClick, width, height, bg, type = 'button',
  } = props;
  return (
    <button type={type} style={{ width: argsToPX(width) || '100px', height: argsToPX(height) || '40px', background: bg || '#4F5968' }} onClick={onClick} className={styles.btn}>{children}</button>
  );
}

export default DarkButton;
