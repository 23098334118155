import React, { useEffect, useRef, useState } from 'react';
import styles from '../Css/ExpenseList.module.css';
import LeftRight from './LeftRight';
import ThemeInput from './ThemeInput';
import BlankMaker from './BlankMaker';
import DarkButton from './DarkButton';
import getDateString from '../Middle/getDateString';

export interface TravelInfo {
  travel_name: string;
  travel_date: string;
}

export type AddTravelHandler = (
  show: null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number},
  info: TravelInfo) => void

export function AddTravel(props: {
   Show: null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number},
   handler: AddTravelHandler}) {
  const { Show, handler } = props;
  const [Info, setInfo] = useState<TravelInfo>(Show?.isEdit ? Show.info : { travel_date: getDateString(new Date()), travel_name: '' });
  useEffect(() => {
    setInfo(Show?.isEdit ? Show.info : { travel_date: getDateString(new Date()), travel_name: '' });
  }, [Show]);

  return (
    <form
      className={styles.modal}
      onChange={(e) => {
        const { target } = e;
        if (!target) return;
        setInfo((t) => ({
          ...t,
          [(target as HTMLInputElement).name]: (target as HTMLInputElement).value,
        }));
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>여행명</div>
            <div>
              <ThemeInput type="text" name="travel_name" style={{ width: '300px', height: '36px' }} value={Info.travel_name} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>여행일</div>
            <div>
              <ThemeInput type="date" name="travel_date" style={{ width: '300px', height: '36px' }} value={Info.travel_date} />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={30} />
      <DarkButton onClick={() => {
        if (!Info.travel_date || !Info.travel_name) {
          alert('여행일이나 상품명을 공란으로 둘 수 없습니다.');
          return;
        }
        handler(Show, Info);
      }}
      >
        {Show?.isEdit ? '수정' : '추가'}
      </DarkButton>
    </form>
  );
}
export default AddTravel;
