export interface deleteBlankType {
  num?: string[],
  str?: string[],
}

export const deleteBlank = (type: deleteBlankType, data: any) => {
  const { num = [], str = [] } = type;
  const numData = num.reduce<{[x: string]: number}>((prev, curr) => {
    const target = data?.[curr];
    if ((typeof target !== 'string' && typeof target !== 'number') || target === 'ALL' || target === '-1' || target === '') return prev;
    return { ...prev, [curr]: parseInt(`${target}`, 10) };
  }, {});
  const strData = str.reduce<{[x: string]: string}>((prev, curr) => {
    const target = data?.[curr];
    if ((typeof target !== 'string' && typeof target !== 'number') || target === 'ALL' || target === '-1' || target === '') return prev;
    return { ...prev, [curr]: `${target}` };
  }, {});
  return { ...numData, ...strData };
};

export default deleteBlank;
