/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { ReactNode, useState } from 'react';

export function ModalWrapper(props: {
  children: ReactNode | ReactNode[],
  Show: boolean
  onShowOff: () => void,
}) {
  const { children, Show, onShowOff } = props;
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: '#00000090',
        display: Show ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        top: '0',
        left: '0',
        zIndex: '5',
      }}
      onClick={() => {
        onShowOff();
      }}
    >
      <div onClick={(e) => {
        e.stopPropagation();
      }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default ModalWrapper;
