/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import ModalWrapper from '../../Components/ModalWrapper';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/ConsultingInfo.module.css';
import { useAdminList } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

const mobileRegExp = /^\d{10,11}$/;
const nameRegExp = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,10}$/;

export interface consultingRegisterI {
  user_idx: number | null | undefined
  userid: string | null | undefined
  user_name: string
  phone_number: string
  email: string | null | undefined
  title: string
  destination: string | null | undefined
  departure_date: string | null | undefined
  people_count: number | null | undefined
  days: number | null | undefined
  content: string | null | undefined
}

function ConsultingRegister() {
  const { getLoginConfig } = useLogin();
  const navigate = useNavigate();
  const [NowData, setNowData] = useState<consultingRegisterI>({
    user_idx: null,
    user_name: '',
    phone_number: '',
    email: '',
    title: '',
    destination: '',
    departure_date: '',
    people_count: null,
    days: null,
    content: null,
    userid: null,
  });
  const { data: adminData } = useAdminList();
  const [URL, setURL] = useState(`/api/admin/user?limit=${PAGE_SIZE}&page=1`);
  const [SearchParam, setSearchParam] = useState({
    type: 'userid',
    keyword: '',
    page: 1,
  });
  const { data: userData } = useSWR<{
    idx: number
    email_check: number
    sms_check: number
    userid: string
    name: string
    phone: string
    email: string
    created_at: string
    tour_count: number
  }[]>(URL, async (url) => {
    const res = await axios.get(url, getLoginConfig());
    return res.data.result;
  });
  const [Show, setShow] = useState(false);
  const makeURL = (pageDiff?: number) => {
    const query = new URLSearchParams();
    const {
      type, keyword, page,
    } = SearchParam;
    query.append('limit', `${PAGE_SIZE}`);
    query.append('page', `${Math.max(page + (pageDiff || 0), 1)}`);
    setSearchParam({ ...SearchParam, page: pageDiff ? Math.max(page + (pageDiff || 0), 1) : 1 });
    if (keyword.trim().length > 0) {
      query.append(type, keyword);
    }
    setURL(`/api/admin/user?${query.toString()}`);
  };
  const onUserClick = (i: number) => {
    const tmpData = userData && userData[i];
    if (!tmpData) return;
    setNowData({
      ...NowData,
      user_idx: tmpData.idx,
      userid: tmpData.userid,
      user_name: tmpData.name,
      phone_number: tmpData.phone,
      email: tmpData.email,
    });
    setShow(false);
  };
  const checkData = () => {
    const error = {
      isError: false,
      comment: '',
    };
    const setError = (where: string) => {
      if (!error.isError) {
        error.isError = true;
        error.comment = `${where}을(를) 확인해주세요`;
      }
    };
    if (!mobileRegExp.test(NowData.phone_number)) setError('전화번호');
    if (!nameRegExp.test(NowData.user_name)) setError('고객 이름');
    if (NowData.title.trim().length === 0) setError('제목');
    return error;
  };
  return (
    <div className={styles.mainDiv}>
      <ModalWrapper onShowOff={() => setShow(false)} Show={Show}>
        <div className={styles.modalDiv}>
          <div className={styles.modalInputDiv}>
            <select name="type" id="type" value={SearchParam.type} onChange={(e) => setSearchParam({ ...SearchParam, type: e.target.value })}>
              <option value="userid">아이디</option>
              <option value="name">이름</option>
              <option value="phone">핸드폰</option>
            </select>
            <ThemeInput name="keyword" type="text" value={SearchParam.keyword} onChange={(e) => setSearchParam({ ...SearchParam, keyword: e.target.value })} style={{ width: '600px', height: '36px' }} />
            <DarkButton width={60} height={36} onClick={() => makeURL()}>검색</DarkButton>
          </div>
          <div className={styles.modalResultDiv}>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>아이디</td>
                  <td>이름</td>
                  <td>핸드폰</td>
                  <td>이메일</td>
                </tr>
              </thead>
              <tbody>
                {
                  userData?.map((d, i) => (
                    <tr>
                      <td onClick={() => onUserClick(i)}>{d.userid}</td>
                      <td onClick={() => onUserClick(i)}>{d.name}</td>
                      <td onClick={() => onUserClick(i)}>{d.phone}</td>
                      <td onClick={() => onUserClick(i)}>{d.email}</td>
                    </tr>
                  ))
                }
              </tbody>
            </CustomTable>
            <BlankMaker height={30} />
            <div className={styles.pageButtonDiv}>
              <DarkButton
                onClick={() => {
                  setSearchParam({
                    ...SearchParam, page: Math.max(SearchParam.page - 1, 1),
                  });
                  makeURL(-1);
                }}
                width={140}
              >
                이전 페이지
              </DarkButton>
              <div>{`${SearchParam.page} 페이지`}</div>
              <DarkButton
                onClick={() => {
                  makeURL(1);
                }}
                width={140}
              >
                다음 페이지
              </DarkButton>
            </div>
          </div>
        </div>
      </ModalWrapper>
      <InnerTitle>상담등록</InnerTitle>
      <BlankMaker height={30} />
      <div className={styles.littleTitle}>회원정보</div>
      <SelectWindow leftWidth={80}>
        <div>
          <div>
            <div>고객 ID</div>
            <div>
              <ThemeInput value={NowData.userid || ''} disabled style={{ width: '200px', height: '40px' }} />
              <BlankMaker width={10} />
              <DarkButton width={60} height={40} onClick={() => setShow(true)}>검색</DarkButton>
            </div>
          </div>
          <div>
            <div>고객 이름</div>
            <div><ThemeInput value={NowData.user_name} onChange={(e) => setNowData({ ...NowData, user_name: e.target.value })} style={{ width: '270px', height: '40px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>휴대폰</div>
            <div><ThemeInput value={NowData.phone_number} onChange={(e) => setNowData({ ...NowData, phone_number: e.target.value })} type="tel" style={{ width: '270px', height: '40px' }} /></div>
          </div>
          <div>
            <div>이메일</div>
            <div><ThemeInput value={NowData.email || ''} onChange={(e) => setNowData({ ...NowData, email: e.target.value })} type="email" style={{ width: '270px', height: '40px' }} /></div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={100} />
      <div className={styles.littleTitle}>상담내용</div>
      <SelectWindow leftWidth={70}>
        <div>
          <div>
            <div>제목</div>
            <div><ThemeInput value={NowData.title} onChange={(e) => setNowData({ ...NowData, title: e.target.value })} style={{ width: '80%', height: '40px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>여행지</div>
            <div><ThemeInput value={NowData.destination || ''} onChange={(e) => setNowData({ ...NowData, destination: e.target.value })} style={{ width: '240px', height: '40px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>출발일</div>
            <div><ThemeInput value={NowData.departure_date || ''} onChange={(e) => setNowData({ ...NowData, departure_date: e.target.value })} type="date" style={{ width: '240px', height: '40px', marginRight: '50px' }} /></div>
          </div>
          <div>
            <div>여행인원</div>
            <div>
              <ThemeInput onChange={(e) => setNowData({ ...NowData, people_count: parseInt(e.target.value, 10) })} type="number" value={NowData.people_count || ''} style={{ width: '50px', height: '40px', marginRight: '10px' }} />
              명
            </div>
          </div>
          <div>
            <div>여행기간</div>
            <div>
              <ThemeInput onChange={(e) => setNowData({ ...NowData, days: parseInt(e.target.value, 10) })} type="number" value={NowData.days || ''} style={{ width: '50px', height: '40px', marginRight: '10px' }} />
              일
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상담내용</div>
            <div>
              <textarea value={NowData.content || ''} onChange={(e) => setNowData({ ...NowData, content: e.target.value })} />
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={50} />
      <div className={styles.buttonsDiv}>
        <DarkButton onClick={async () => {
          const { isError, comment } = checkData();
          if (isError) {
            alert(comment);
            return;
          }
          try {
            const res = await axios.post<{statusCode: number}>('/api/consulting', NowData, getLoginConfig());
            if (res.data.statusCode !== 201) throw Error;
            alert('등록했습니다.');
            navigate('/reservation/consulting/list');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
        >
          저장

        </DarkButton>
      </div>
    </div>
  );
}

export default ConsultingRegister;
