/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../Css/Sidebar.module.css';
import DarkButton from './DarkButton';
import pageMap from '../Meta/PageMap';
import iconList from '../Meta/iconList';

interface EachSideVIEWProps{
  isOpen: boolean,
  onToggle: () => void,
  isNow: boolean,
  topName: string,
  icon: ReactElement,
  subList: ({ type: 'super', name: string } | { type: 'sub', name: string, link: string, isNow: boolean })[]
  topLink: string
}

function EachSideVIEW(props: EachSideVIEWProps) {
  const {
    isOpen, isNow, topName, subList, onToggle, icon, topLink,
  } = props;
  return (
  // <div className={`${styles.EachSideDiv}
  // ${isOpen && styles.EachSideOpen} ${isNow && styles.EachSideNow}`}>
  //   <button type="button" className={styles.EachSideTopName}
  // onClick={onToggle}>{topName}</button>
  //   <div className={styles.EachSideSubs}>
  //     {
  //       subList.map((t) => (
  //         t.type === 'super' ? (
  //           <div>{t.name}</div>
  //         ) : (
  //           <Link to={t.link} className={`${t.isNow && styles.subNow}`}>{t.name}</Link>
  //         )
  //       ))
  //     }
  //   </div>
  // </div>
    <div className={styles.tileWrapper}>
      <Link to={topLink}>
        <div className={`${styles.tile} ${isNow && styles.tileNow}`}>
          {icon}
          <div>{topName}</div>
        </div>
      </Link>
      <div className={styles.subList}>
        {
            subList.map((t) => (
              t.type === 'super' ? (
                <div className={styles.subSuper}>{t.name}</div>
              ) : (
                <Link to={t.link} className={`${t.isNow && styles.subNow} ${styles.subLink}`}>{t.name}</Link>
              )
            ))
          }
      </div>
    </div>
  );
}

function EachSide({ index }: {index: number}) {
  const location = useLocation();
  const targetMap = pageMap[index];
  const {
    name, link, bigSubList, icon,
  } = targetMap;

  const isNow = location.pathname.startsWith(`/${link}`);
  const [isOpen, setIsOpen] = useState(isNow);
  useEffect(() => setIsOpen(isNow), [location.pathname]);

  const onToggle = () => setIsOpen((t) => !t);

  const subList = bigSubList.map((t) => ([{ type: 'super', name: t.name }, ...t.subList.map((tt) => ({
    type: 'sub', name: tt.name, link: `/${link}/${tt.link}`, isNow: location.pathname.startsWith(`/${link}/${tt.link}`),
  }))])).flat() as any;
  const topLink = subList.filter((t: any) => t.type === 'sub')[0].link;

  const subProps: EachSideVIEWProps = {
    isOpen,
    onToggle,
    isNow,
    icon,
    topName: name,
    subList,
    topLink,
  };

  return <EachSideVIEW {...subProps} />;
}

export function Sidebar() {
  return (
    <div className={styles.sidebarDiv}>
      <Link className={styles.logoLink} to="/">
        <div>
          <img src="/logosquare.png" alt="" />
        </div>
      </Link>
      <div className={styles.sideMenuDiv}>
        {
          pageMap.map((_, i) => <EachSide index={i} />)
        }
      </div>
      <Link to="/logout" className={styles.tileLink}>
        <div className={`${styles.tile} ${styles.dark}`}>
          {iconList.logout}
          <div>로그아웃</div>
        </div>
      </Link>
    </div>
  );
}

export default Sidebar;
