import axios, { AxiosRequestConfig } from 'axios';
import useSWR from 'swr';

type numBool = 0|1

interface userInfo {
  idx: number,
  userId: string,
  name: string,
  english_last_name: string,
  english_first_name: string,
  email: string,
  mobile: string,
  gender: numBool,
  birth: string,
  sms_check: numBool,
  email_check: numBool,
  tour_count: number,
  travel_type: number,
  travel_goal: number,
  create_date: string
}

export function useLogin() {
  const { data, mutate, isValidating } = useSWR<{login: false} | {login: true, info: userInfo, axiosConfig: AxiosRequestConfig}>('login', async () => {
    const accessToken = localStorage.getItem('alps_admin');
    if (accessToken) {
      try {
        const res = await axios.get<{statusCode: 201, result: userInfo} | {statusCode: 400}>('/api/setting/company', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const resData = res.data;
        if (resData.statusCode === 201) {
          return {
            login: true,
            info: resData.result,
            axiosConfig: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          };
        }
      } catch {
        return {
          login: false,
        };
      }
    }
    return {
      login: false,
    };
  });
  return {
    loginData: data,
    loginMutate: mutate,
    loginIsValidating: isValidating,
    getLoginConfig: () => {
      if (!data?.login) return {};
      return data.axiosConfig;
    },
  };
}

export default useLogin;
