import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import InnerTitle from '../../Components/InnerTitle';
import LinkList from '../../Components/LinkList';
import styles from '../../Css/UserInfo.module.css';
import UserInfoInfo from './UserInfoInfo';
import UserInfoReservation from './UserInfoReservation';

const stateToComponent = {
  default: UserInfoInfo,
  reservation: UserInfoReservation,
};

function UserInfo() {
  const [Screen, setScreen] = useState<keyof typeof stateToComponent>('default');
  const { idx } = useParams();
  const NowComponent = stateToComponent[Screen];
  if (!idx) return <div />;
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>회원조회</InnerTitle>
      <BlankMaker height={30} />
      <LinkList>
        <button
          type="button"
          onClick={() => { if (Screen !== 'default') setScreen('default'); }}
          className={`${Screen === 'default' && styles.selected}`}
        >
          기본정보
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'reservation') setScreen('reservation'); }}
          className={`${Screen === 'reservation' && styles.selected}`}
        >
          예약내역
        </button>
      </LinkList>
      <BlankMaker height={30} />
      <NowComponent idx={parseInt(idx, 10)} />
    </div>
  );
}

export default UserInfo;
