import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/Setting.module.css';
import useLogin from '../../Middle/useLogin';

export interface companyI {
  company_name: string
  phone: string
  fax: string
  post_code: string
  address: string
  business_number: string
  business_certificate: string
  business_report: string
  ceo_name: string
  privacy_manager_email: string
  privacy_manager_name: string
  officehour_weekday: string
  officehour_saturday: string
  officehour_holiday: string
  email: string
}

function Company() {
  const { getLoginConfig } = useLogin();
  const [NewData, setNewData] = useState<null | companyI>(null);
  const { data, mutate } = useSWR('/api/setting/company', async (url) => {
    const res = await axios.get<{result: companyI}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (data) setNewData(data);
  }, [data]);
  if (!data || !NewData) return <div />;
  const getInput = (name: keyof companyI) => (
    <ThemeInput
      value={NewData[name]}
      onChange={(e) => setNewData({ ...NewData, [name]: e.target.value })}
    />
  );
  const onChange = async () => {
    try {
      const res = await axios.put<{statusCode: number}>('/api/setting/company', NewData, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.mainDiv} ${styles.company}`}>
      <InnerTitle>회사 정보</InnerTitle>
      <BlankMaker height={40} />
      <LeftRight leftWidth={200}>
        <div>
          <div>
            <div>회사명</div>
            <div>{getInput('company_name')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>대표전화번호</div>
            <div>{getInput('phone')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>회사팩스번호</div>
            <div>{getInput('fax')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>우편번호</div>
            <div>{getInput('post_code')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>주소</div>
            <div>{getInput('address')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>사업자등록번호</div>
            <div>{getInput('business_number')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>관광사업자등록증</div>
            <div>{getInput('business_certificate')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>통신판매업신고</div>
            <div>{getInput('business_report')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>대표지</div>
            <div>{getInput('ceo_name')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>개인정보관리책임자</div>
            <div>
              <LeftRight leftWidth={60}>
                <div>
                  <div>
                    <div>성명</div>
                    <div>{getInput('privacy_manager_name')}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>이메일</div>
                    <div>{getInput('privacy_manager_email')}</div>
                  </div>
                </div>
              </LeftRight>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>업무시간(평일)</div>
            <div>{getInput('officehour_weekday')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>업무시간(토요일)</div>
            <div>{getInput('officehour_saturday')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>업무시간(휴일)</div>
            <div>{getInput('officehour_holiday')}</div>
          </div>
        </div>
        <div>
          <div>
            <div>발신전용메일</div>
            <div>{getInput('email')}</div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={onChange}>수정</DarkButton>
    </div>
  );
}

export default Company;
