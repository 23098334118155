import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/EmployeeMyInfo.module.css';
import useLogin from '../../Middle/useLogin';
import { EmployeeMyInfoChangeI } from './EmployeeMyInfo';

export const pwRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
export const idRegExp = /^[a-z0-9]{4,20}$/;

function EmployeeRegister() {
  const { getLoginConfig } = useLogin();
  const navigate = useNavigate();
  const [NewData, setNewData] = useState<EmployeeMyInfoChangeI & {adminid: string}>({
    password: '',
    name: '',
    gender: 1,
    email: '',
    mobile: '',
    birth: '',
    department: '',
    position: '',
    adminid: '',
  });
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>관리자 등록</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight haveLine leftWidth={70}>
        <div>
          <div>
            <div>아이디</div>
            <div>
              <ThemeInput
                value={NewData.adminid}
                onChange={(e) => setNewData({ ...NewData, adminid: e.target.value })}
              />
              <BlankMaker width={20} />
              <DarkButton
                height={36}
                onClick={async () => {
                  if (!idRegExp.test(NewData.adminid)) {
                    alert('규칙에 맞지 않는 아이디입니다.');
                    return;
                  }
                  try {
                    const res = await axios.post<{result: boolean}>('/api/admin/sign/check', { userid: NewData.adminid }, getLoginConfig());
                    if (res.data.result) {
                      alert('사용할 수 있는 아이디입니다.');
                    } else {
                      alert('사용할 수 없는 아이디입니다.');
                    }
                  } catch {
                    alert('오류가 발생했습니다.');
                  }
                }}
              >
                중복확인
              </DarkButton>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>성명</div>
            <div>
              <ThemeInput
                value={NewData.name}
                onChange={(e) => setNewData({ ...NewData, name: e.target.value })}
              />
            </div>
          </div>
          <div>
            <div>비밀번호</div>
            <div>
              <ThemeInput
                value={NewData.password || ''}
                onChange={(e) => setNewData({ ...NewData, password: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>성별</div>
            <div>
              <select
                value={NewData.gender}
                onChange={(e) => setNewData({ ...NewData, gender: parseInt(e.target.value, 10) })}
              >
                <option value={1}>남</option>
                <option value={0}>여</option>
              </select>
            </div>
          </div>
          <div>
            <div>생년월일</div>
            <div>
              <ThemeInput
                value={NewData.birth}
                onChange={(e) => setNewData({ ...NewData, birth: e.target.value })}
                type="date"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>전화번호</div>
            <div>
              <ThemeInput
                value={NewData.mobile}
                onChange={(e) => setNewData({ ...NewData, mobile: e.target.value })}
                type="tel"
              />
            </div>
          </div>
          <div>
            <div>이메일</div>
            <div>
              <ThemeInput
                value={NewData.email}
                onChange={(e) => setNewData({ ...NewData, email: e.target.value })}
                type="email"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>부서</div>
            <div>
              <ThemeInput
                value={NewData.department}
                onChange={(e) => setNewData({ ...NewData, department: e.target.value })}
              />
            </div>
          </div>
          <div>
            <div>직급</div>
            <div>
              <ThemeInput
                value={NewData.position}
                onChange={(e) => setNewData({ ...NewData, position: e.target.value })}
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={async () => {
        try {
          const res = await axios.post<{statusCode: number}>('/api/admin/sign-up', NewData, getLoginConfig());
          const { statusCode } = res.data;
          if (statusCode !== 201) throw Error;
          alert('등록했습니다.');
          navigate(-1);
        } catch {
          alert('오류가 발생했습니다.');
        }
      }}
      >
        수정
      </DarkButton>
    </div>
  );
}

export default EmployeeRegister;
