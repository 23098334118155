/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import useCategory from '../../Middle/useCategory';
import {
  useCountry, useCity, useAdminList, useHotelType, useSightType,
} from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import { goodDefaultInfo } from '../Good/GoodInfo';
import styles from '../../Css/GoodInfo.module.css';
import InnerTitle from '../../Components/InnerTitle';
import ImageInput from '../../Components/ImageInput';

export interface sightDefaultInfo {
  title: string
  sight_type: number | null | undefined
  continent: number | null | undefined
  country: number | null | undefined
  city: number | null | undefined
  siteurl: string | null | undefined
  content: string | null | undefined
}

function SightRegister() {
  const { getLoginConfig } = useLogin();
  const category = useCategory();
  const navigate = useNavigate();
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const { data: adminData } = useAdminList();
  const { data: sightData } = useSightType();
  const [NowInfoData, setNowInfoData] = useState<sightDefaultInfo & {thumb_image: string}
  | undefined | null>({
    title: '',
    sight_type: -1,
    continent: -1,
    country: -1,
    city: -1,
    siteurl: '',
    content: '',
    thumb_image: 'https://alps-tour.s3.ap-northeast-2.amazonaws.com/goods/1646059905387_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5%20%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5.png',
  });
  if (!NowInfoData || category.firstCategoryIdxList.length === 0) return <div />;
  const checkData = () => {
    const dataToCheck = { ...NowInfoData };
    const error = {
      isError: false,
      reason: '',
    };
    const setError = (where: string) => {
      if (!error.isError) {
        error.isError = true;
        error.reason = `${where}을(를) 확인해주세요`;
      }
    };
    if (dataToCheck.title.trim().length === 0) setError('호텔명');
    if (dataToCheck.continent === -1) setError('대륙');
    if (dataToCheck.country === -1) setError('국가');
    if (dataToCheck.city === -1) dataToCheck.city = null;
    if (dataToCheck.sight_type === -1) setError('분류');
    return {
      error,
      data: {
        ...dataToCheck,
      },
    };
  };
  const onNext = async () => {
    const { error, data } = checkData();
    if (error.isError) {
      alert(error.reason);
      return;
    }
    try {
      const res = await axios.post<{statusCode: number, result: {insertId: number}}>('/api/sight', data, getLoginConfig());
      if (res.data.statusCode === 201) {
        alert('등록했습니다.');
        navigate(`/sight/list/${res.data.result.insertId}`);
      } else {
        throw Error;
      }
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  if (!countryData) return <div />;
  return (
    <div className={`${styles.innerMain}`}>
      <InnerTitle>관광지등록</InnerTitle>
      <BlankMaker height={30} />
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>관광지명</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.title}
                onChange={(e) => setNowInfoData({ ...NowInfoData, title: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>관광지구분</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.sight_type || -1}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  sight_type: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>분류</option>
                {
                  sightData?.idxList.map((i) => (
                    <option value={`${sightData[i].idx}`}>{sightData[i].title}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>지역</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.country || -1}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  continent: countryData[parseInt(e.target.value, 10)].continent,
                  country: parseInt(e.target.value, 10),
                  city: -1,
                })}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.city || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  city: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${NowInfoData.country}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>홈페이지</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.siteurl || ''}
                onChange={(e) => setNowInfoData({ ...NowInfoData, siteurl: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>관광지 소개</div>
            <div>
              <ThemeInput
                style={{
                  width: '100%',
                  height: '135px',
                }}
                value={NowInfoData.content || ''}
                onChange={(e) => setNowInfoData({ ...NowInfoData, content: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>대표 이미지</div>
            <div className={styles.imageDiv}>
              <img src={NowInfoData.thumb_image} alt="" />
              <BlankMaker width={40} />
              <ImageInput onChange={async (e) => {
                if (!e.target.files) return;
                try {
                  const formData = new FormData();
                  formData.append('image', e.target.files[0]);
                  const res = await axios.post<{statusCode: number, result: string}>('/api/sight/thumb-image', formData);
                  if (res.data.statusCode !== 201) throw Error;
                  setNowInfoData({ ...NowInfoData, thumb_image: res.data.result });
                  alert('이미지를 업로드했습니다.');
                } catch {
                  alert('오류가 발생했습니다.');
                }
              }}
              >
                <DarkButton bg="#3386FF" width={140}>이미지 업로드</DarkButton>
              </ImageInput>
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={() => onNext()}>등록</DarkButton>
    </div>
  );
}

export default SightRegister;
