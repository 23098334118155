import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import CustomTable from '../../Components/CustomTable';
import styles from '../../Css/UserInfo.module.css';
import useLogin from '../../Middle/useLogin';

export interface UserInfoReservationI {
  'rsv_name': string,
  'created_at': string,
  'status': string,
  'count': number,
  'price': number,
  'title': string,
  'departure_date': string,
}

function UserInfoReservation(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const { data } = useSWR(`/api/admin/user/${idx}/reservation`, async (url) => {
    const res = await axios.get<{result: UserInfoReservationI[]}>(url, getLoginConfig());
    return res.data.result;
  });
  return (
    <div className={`${styles.mainDiv} ${styles.resDiv}`}>
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>접수일</td>
            <td>상태</td>
            <td>고객명</td>
            <td>인원</td>
            <td>상품명</td>
            <td>출발일</td>
            <td>금액</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d) => (
              <tr>
                <td>{d.created_at}</td>
                <td>{d.status}</td>
                <td>{d.rsv_name}</td>
                <td>{d.count}</td>
                <td>{d.title}</td>
                <td>{d.departure_date}</td>
                <td>{`${d.price.toLocaleString()}원`}</td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

export default UserInfoReservation;
