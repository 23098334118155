/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unstable-nested-components */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import Calendar from '../../Components/Calendar';
import DarkButton from '../../Components/DarkButton';
import styles from '../../Css/GoodInfo.module.css';
import useLogin from '../../Middle/useLogin';

export interface GoodInfoSettingI {
  'idx': number,
  'departure_date': string,
  'maximum': number,
  'current': number,
  'state': string
}

function GoodInfoSetting(props: { idx: number }) {
  const { idx } = props;
  const today = new Date();
  const { getLoginConfig } = useLogin();
  const [Year, setYear] = useState(today.getFullYear());
  const [Month, setMonth] = useState(today.getMonth() + 1);
  const { data, mutate } = useSWR(`/api/admin/good/${idx}/schedule/month?date=${Year}-${Month}`, async (url) => {
    const res = await axios.get<{result: GoodInfoSettingI[]}>(url, getLoginConfig());
    const tmp: {[day: number]: GoodInfoSettingI[]} = {};
    res.data.result.map((d) => {
      const day = parseInt(d.departure_date.slice(8, 10), 10);
      if (!tmp[day]) tmp[day] = [];
      tmp[day].push(d);
      return 0;
    });
    return tmp;
  });
  const onDelete = async (d: GoodInfoSettingI) => {
    if (!confirm('삭제하시겠습니까?')) return;
    if (d.current > 0 && !confirm('해당 일정에 예약이 존재합니다. 그래도 삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/admin/good/${idx}/schedule?date=${d.departure_date}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.innerMain}`}>
      <div className={styles.leftRight}>
        <div>
          <select value={Year} onChange={(e) => setYear(parseInt(e.target.value, 10))}>
            {
              Array(11).fill(0).map((a, i) => (
                <option value={today.getFullYear() + i - 5}>
                  {`${today.getFullYear() + i - 5}년`}
                </option>
              ))
            }
          </select>
          <select value={Month} onChange={(e) => setMonth(parseInt(e.target.value, 10))}>
            {
              Array(12).fill(0).map((a, i) => (
                <option value={i + 1}>
                  {`${i + 1}월`}
                </option>
              ))
            }
          </select>
        </div>
        <div>
          <Link to="./add"><DarkButton width={140}>출발일정 등록</DarkButton></Link>
        </div>
      </div>
      <BlankMaker height={15} />
      <Calendar
        year={Year}
        month={Month}
        dayRender={(year, month, day) => (
          <div>
            {
              data && data[day] && data[day].map((d) => (
                <div className={styles.calendarEach}>
                  <Link to={`./${d.departure_date}`}>{`${d.state} (${d.current}/${d.maximum})`}</Link>
                  <DarkButton onClick={() => onDelete(d)} width={40} height={25}>삭제</DarkButton>
                </div>
              ))
            }
          </div>
        )}
      />
    </div>
  );
}

export default GoodInfoSetting;
