/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unstable-nested-components */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import Calendar from '../../Components/Calendar';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodInfo.module.css';
import getDateString from '../../Middle/getDateString';
import useLogin from '../../Middle/useLogin';

export interface EmployeeScheduleI {
  'date': string,
  'schedule': {
    'idx': number,
    'manager_idx': number,
    'manager_name': string,
    'title': string,
    'content': string | null | undefined
  }[]
}

function EmployeeSchedule() {
  const today = new Date();
  const { getLoginConfig } = useLogin();
  const [Year, setYear] = useState(today.getFullYear());
  const [Month, setMonth] = useState(today.getMonth() + 1);
  const [Show, setShow] = useState(false);
  const [NewData, setNewData] = useState({
    date: getDateString(today), title: '', content: '',
  });
  const { data, mutate } = useSWR(`/api/admin/schedule/month?date=${Year}-${Month}`, async (url) => {
    const res = await axios.get<{result: EmployeeScheduleI[]}>(url, getLoginConfig());
    const tmp: {[day: number]:EmployeeScheduleI} = {};
    res.data.result.map((d) => {
      const day = parseInt(d.date.slice(8, 10), 10);
      tmp[day] = d;
      return 0;
    });
    return tmp;
  });
  useEffect(() => {
    setNewData({
      date: getDateString(today), title: '', content: '',
    });
  }, [Show]);
  const onRegister = async () => {
    try {
      const res = await axios.post<{statusCode: number}>(`/api/admin/schedule?date=${NewData.date}`, {
        title: NewData.title, content: NewData.content,
      }, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('등록했습니다.');
      setShow(false);
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.innerMain}`}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.addDiv}>
          <LeftRight leftWidth={40}>
            <div>
              <div>
                <div>날짜</div>
                <div><ThemeInput type="date" value={NewData.date} onChange={(e) => setNewData({ ...NewData, date: e.target.value })} style={{ width: '200px', height: '36px' }} /></div>
              </div>
            </div>
            <div>
              <div>
                <div>제목</div>
                <div><ThemeInput value={NewData.title} onChange={(e) => setNewData({ ...NewData, title: e.target.value })} style={{ width: '400px', height: '36px' }} /></div>
              </div>
            </div>
            <div>
              <div>
                <div>내용</div>
                <div><ThemeInput value={NewData.content} onChange={(e) => setNewData({ ...NewData, content: e.target.value })} style={{ width: '100%', height: '36px' }} /></div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={50} />
          <DarkButton onClick={onRegister}>등록</DarkButton>
        </div>
      </ModalWrapper>
      <InnerTitle>일정 관리</InnerTitle>
      <BlankMaker height={30} />
      <div className={styles.leftRight}>
        <div>
          <select value={Year} onChange={(e) => setYear(parseInt(e.target.value, 10))}>
            {
              Array(11).fill(0).map((a, i) => (
                <option value={today.getFullYear() + i - 5}>
                  {`${today.getFullYear() + i - 5}년`}
                </option>
              ))
            }
          </select>
          <select value={Month} onChange={(e) => setMonth(parseInt(e.target.value, 10))}>
            {
              Array(12).fill(0).map((a, i) => (
                <option value={i + 1}>
                  {`${i + 1}월`}
                </option>
              ))
            }
          </select>
        </div>
        <div>
          <DarkButton width={80} bg="#3386FF" onClick={() => setShow(true)}>등록</DarkButton>
        </div>
      </div>
      <BlankMaker height={15} />
      <Calendar
        year={Year}
        month={Month}
        dayRender={(year, month, day) => (
          <div className={styles.schedule}>
            {
              data && data[day] && data[day].schedule.map((d) => (
                <div className={styles.calendarEach}>
                  <Link to={`./${data[day].date}`}>{`[${d.manager_name}]${d.title}`}</Link>
                </div>
              ))
            }
          </div>
        )}
      />
    </div>
  );
}

export default EmployeeSchedule;
