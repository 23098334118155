import React, { ReactElement } from 'react';
import BoardContent from './BoardContent';
import BoardEdit from './BoardEdit';
import BoardNew from './BoardNew';

type pageType = 'content' | 'edit' | 'new'

const typeToComponent: {[t: string]: () => ReactElement} = {
  content: BoardContent,
  edit: BoardEdit,
  new: BoardNew,
};

export function Linker(props: { type: pageType }) {
  const { type } = props;
  const Component = typeToComponent[type];
  return <Component />;
}

export default Linker;
