/* eslint-disable prefer-spread */
import React, {
  ReactChild, ReactComponentElement, useEffect, useState,
} from 'react';
import styles from '../Css/Calendar.module.css';

const getWeekNumber = (dateFrom = new Date()) => {
  // 해당 날짜 (일)
  const currentDate = dateFrom.getDate();

  // 이번 달 1일로 지정
  const startOfMonth = new Date(dateFrom.setDate(1));

  // 이번 달 1일이 무슨 요일인지 확인
  const weekDay = startOfMonth.getDay(); // 0: Sun ~ 6: Sat

  // ((요일 - 1) + 해당 날짜) / 7일로 나누기 = N 주차
  return Math.floor(((weekDay - 1) + currentDate) / 7) + 1;
};

function Calendar(props: {
  year: number,
  month: number,
  dayRender: (year: number, month: number, day: number) => ReactChild
}) {
  const { year, month, dayRender } = props;
  const [DayArray, setDayArray] = useState<number[][] | null>(null);
  useEffect(() => {
    const tmpArray = Array.apply(null, Array(7)).map(
      () => Array.apply(null, Array(7)).map(() => 0),
    );
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);
    const firstWeekDay = firstDay.getDay();
    const lastDate = lastDay.getDate();
    Array(lastDate).fill(0).map((a, i) => {
      const today = i + 1;
      const todayDate = new Date(year, month - 1, today);
      const todayWeek = Math.floor(((firstWeekDay - 1) + todayDate.getDate()) / 7);
      const todayDay = todayDate.getDay();
      tmpArray[todayWeek][todayDay] = today;
      return 0;
    });
    const lastWeek = Math.floor(((firstWeekDay - 1) + lastDate) / 7);
    setDayArray(tmpArray.slice(0, lastWeek + 1));
  }, [year, month]);
  if (!DayArray) return <div />;
  return (
    <table className={styles.calendar}>
      <thead>
        <tr>
          <td>일</td>
          <td>월</td>
          <td>화</td>
          <td>수</td>
          <td>목</td>
          <td>금</td>
          <td>토</td>
        </tr>
      </thead>
      <tbody>
        {
          DayArray.map((week) => (
            <tr>
              {
                week.map((day) => (
                  <td>
                    <div className={styles.dayText}>{day !== 0 ? day : ''}</div>
                    <div className={styles.dayContents}>
                      {day !== 0 && dayRender(year, month, day)}
                    </div>
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}

export default Calendar;
