/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import ImageInput from '../../Components/ImageInput';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/Setting.module.css';
import useLogin from '../../Middle/useLogin';

const firstInitial = {
  image: 'https://alps-tour.s3.ap-northeast-2.amazonaws.com/goods/1646059905387_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5%20%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5.png',
  title: '',
  title_en: '',
  code: '',
};

function FirstComp() {
  const { getLoginConfig } = useLogin();
  const [Info, setInfo] = useState({ ...firstInitial });
  const [NewData, setNewData] = useState({ ...firstInitial });
  const [Target, setTarget] = useState(-1);
  const [Show, setShow] = useState(false);
  const { data, mutate } = useSWR('/api/setting/airline', async (url) => {
    const res = await axios.get<{result: {
      idx: number
      title: string
      title_en: string
      in_use: number
      code: string
      thumb_image: string
    }[]}>(url, getLoginConfig());
    return res.data.result;
  });
  if (!data) return <div />;
  const changeUse = async (idx: number) => {
    try {
      const res = await axios.patch<{statusCode: number}>(`/api/setting/airline/${idx}/state`, {}, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const addCate = async () => {
    try {
      const res = await axios.post<{statusCode: number}>('/api/setting/airline', Info, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('등록했습니다.');
      mutate();
      setInfo({ ...firstInitial });
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const editCate = async () => {
    try {
      const res = await axios.put<{statusCode: number}>(`/api/setting/airline/${Target}`, NewData, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      mutate();
      setShow(false);
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const deleteCate = async (idx: number) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/setting/airline/${idx}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const openModal = (i: number) => {
    const now = data[i];
    setNewData({
      title: now.title,
      image: now.thumb_image,
      title_en: now.title_en,
      code: now.code,
    });
    setTarget(now.idx);
    setShow(true);
  };
  return (
    <div className={styles.mainDiv}>
      <ModalWrapper
        Show={Show}
        onShowOff={() => setShow(false)}
      >
        <div className={styles.bannerModal}>
          <LeftRight leftWidth={120}>
            <div>
              <div>
                <div>항공사코드</div>
                <div>
                  <ThemeInput style={{ width: '100px', height: '36px' }} value={NewData.code} onChange={(e) => setNewData({ ...NewData, code: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>항공사명(한)</div>
                <div>
                  <ThemeInput style={{ width: '100%', height: '36px' }} value={NewData.title} onChange={(e) => setNewData({ ...NewData, title: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>항공사명(영)</div>
                <div>
                  <ThemeInput style={{ width: '100%', height: '36px' }} value={NewData.title_en} onChange={(e) => setNewData({ ...NewData, title_en: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>로고</div>
                <div className={styles.iconInputDiv}>
                  <img src={NewData.image} alt="" />
                  <ImageInput onChange={async (e) => {
                    if (!e.target.files) return;
                    try {
                      const formData = new FormData();
                      formData.append('image', e.target.files[0]);
                      const res = await axios.post<{statusCode: number, result: string}>('/api/setting/airline/image', formData, getLoginConfig());
                      if (res.data.statusCode !== 201) throw Error;
                      alert('이미지를 업로드했습니다.');
                      setNewData({ ...NewData, image: res.data.result });
                    } catch {
                      alert('오류가 발생했습니다.');
                    }
                  }}
                  >
                    <DarkButton width={140}>이미지 업로드</DarkButton>
                  </ImageInput>
                </div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={30} />
          <DarkButton onClick={editCate}>등록</DarkButton>
        </div>
      </ModalWrapper>
      <SelectWindow leftWidth={120}>
        <div>
          <div>
            <div>항공사코드</div>
            <div>
              <ThemeInput style={{ width: '100px', height: '36px' }} value={Info.code} onChange={(e) => setInfo({ ...Info, code: e.target.value })} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>항공사명(한)</div>
            <div>
              <ThemeInput style={{ width: '100%', height: '36px' }} value={Info.title} onChange={(e) => setInfo({ ...Info, title: e.target.value })} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>항공사명(영)</div>
            <div>
              <ThemeInput style={{ width: '100%', height: '36px' }} value={Info.title_en} onChange={(e) => setInfo({ ...Info, title_en: e.target.value })} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>로고</div>
            <div className={styles.iconInputDiv}>
              <img src={Info.image} alt="" />
              <ImageInput onChange={async (e) => {
                if (!e.target.files) return;
                try {
                  const formData = new FormData();
                  formData.append('image', e.target.files[0]);
                  const res = await axios.post<{statusCode: number, result: string}>('/api/setting/airline/image', formData, getLoginConfig());
                  if (res.data.statusCode !== 201) throw Error;
                  alert('이미지를 업로드했습니다.');
                  setInfo({ ...Info, image: res.data.result });
                } catch {
                  alert('오류가 발생했습니다.');
                }
              }}
              >
                <DarkButton width={140}>이미지 업로드</DarkButton>
              </ImageInput>
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={addCate}>등록</DarkButton>
      <BlankMaker height={100} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>사용</td>
            <td>No.</td>
            <td>항공사코드</td>
            <td>항공사명(한)</td>
            <td>항공사명(영)</td>
            <td>항공사 로고</td>
            <td>관리</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d, i) => (
              <tr>
                <td style={{ cursor: 'pointer' }} onClick={() => changeUse(d.idx)}>{d.in_use === 1 ? '✅' : '🚫'}</td>
                <td>{d.idx}</td>
                <td>{d.code}</td>
                <td>{d.title}</td>
                <td>{d.title_en}</td>
                <td><img src={d.thumb_image || ''} alt="" className={styles.icon} /></td>
                <td>
                  <div className={styles.buttons}>
                    <DarkButton width={60} height={32} bg="#3386FF" onClick={() => openModal(i)}>수정</DarkButton>
                    <DarkButton width={60} height={32} bg="#FF4E40" onClick={() => deleteCate(d.idx)}>삭제</DarkButton>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

function Airline() {
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>항공사 관리</InnerTitle>
      <BlankMaker height={24} />
      <FirstComp />
    </div>
  );
}

export default Airline;
