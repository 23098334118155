import axios from 'axios';
import useSWR from 'swr';

export const useCountry = () => useSWR('/api/region/country?count=true&state=true', async (url) => {
  const res = await axios.get<{ result: {
    idx: number
    title: string
    state: number
    city_count: number
    continent: number
  }[]}>(url);
  return res.data.result;
});

export const useCity = () => useSWR('/api/region/city?state=true', async (url) => {
  const res = await axios.get<{ result: {
    idx: number
    title: string
    state: number
    country: number
  }[]}>(url);
  return res.data.result;
});

export const useAdminList = () => useSWR('/api/admin/users', async (url) => {
  const res = await axios.get<{ result: {
    idx: number
    name: string
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, name: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useReserveState = () => useSWR('/api/category/reservation', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    status: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, status: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useGoodState = () => useSWR('/api/category/good/state', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useGrade = () => useSWR('/api/category/grade', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useAirline = () => useSWR('/api/category/airline', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useTransportation = () => useSWR('/api/category/transportation', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useHotelType = () => useSWR('/api/category/hotel', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export const useSightType = () => useSWR('/api/category/sight', async (url) => {
  const res = await axios.get<{ result: {
    idx: number,
    title: string,
  }[]}>(url);
  const result: {
    idxList: number[], [i:number]: {idx: number, title: string}
  } = { idxList: [] };
  result.idxList = res.data.result.map((info) => {
    result[info.idx] = info;
    return info.idx;
  });
  return result;
});

export default 'asdf';
