/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/ExpenseList.module.css';
import getDateString from '../../Middle/getDateString';
import useLogin from '../../Middle/useLogin';

function ExpenseGood() {
  const { getLoginConfig } = useLogin();
  const today = new Date();
  const ago = new Date();
  ago.setMonth(ago.getMonth() - 1);
  const [URL, setURL] = useState(`/api/admin/expense/good?start_date=${getDateString(ago)}&end_date=${getDateString(today)}`);
  const [SearchParam, setSearchParam] = useState({
    start_date: getDateString(ago),
    end_date: getDateString(today),
  });
  const { data, mutate } = useSWR(URL, async (url) => {
    const res = await axios.get<{result: {
      good_title: string, price: number
    }[]}>(url, getLoginConfig());
    return res.data.result;
  });
  const makeURL = () => {
    const { start_date, end_date } = SearchParam;
    if (start_date.length === 0 || end_date.length === 0) {
      alert('시작일과 종료일 모두 지정되어야 합니다.');
      return;
    }
    setURL(`/api/admin/expense/good?start_date=${start_date}&end_date=${end_date}`);
  };
  if (!data) return <div />;
  return (
    <div className={styles.expenseListDiv}>
      <InnerTitle>수익현황</InnerTitle>
      <div className={styles.optionListDiv}>
        <ThemeInput type="date" style={{ width: '180px', height: '40px' }} value={SearchParam.start_date} onChange={(e) => setSearchParam({ ...SearchParam, start_date: e.target.value })} />
        <div>~</div>
        <ThemeInput type="date" style={{ width: '180px', height: '40px' }} value={SearchParam.end_date} onChange={(e) => setSearchParam({ ...SearchParam, end_date: e.target.value })} />
        <DarkButton width={60} height={40} onClick={() => makeURL()}>검색</DarkButton>
      </div>
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>상품명</td>
            <td>총 판매가</td>
          </tr>
        </thead>
        <tbody>
          {
            data.map((d) => (
              <tr>
                <td>{d.good_title}</td>
                <td>{`${d.price.toLocaleString('ko-KR')}원`}</td>
              </tr>
            ))
          }
          <tr className={styles.lastTable}>
            <td>계</td>
            <td>{`${data.map((d) => d.price).reduce((pV, cV) => pV + cV, 0).toLocaleString('ko-KR')}원`}</td>
          </tr>
        </tbody>
      </CustomTable>
    </div>
  );
}

export default ExpenseGood;
