/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  MouseEvent,
  MouseEventHandler,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import axios from 'axios';
import styles from '../../Css/NewUserList.module.css';
import CustomTable from '../../Components/CustomTable';
import ThemeInput from '../../Components/ThemeInput';
import DarkButton from '../../Components/DarkButton';
import NewUserInfoEdit from '../../Components/NewUserInfoEdit';
import deleteBlank, { deleteBlankType } from '../../Middle/deleteBlank';
import useNewDropdown, { useNewDropdownReturnI } from '../../Middle/useNewDropdown';
import useQuery from '../../Middle/useQuery';
import useLogin from '../../Middle/useLogin';

const PAGE_LIMIT = 50;

const deleteBlankTypeThis: deleteBlankType = {
  num: ['page', 'limit', 'gender', 'region_idx', 'theme_idx', 'age', 'channel_idx', 'count_min', 'count_max', 'group_idx'],
  str: ['name', 'mobile', 'order'],
};

export interface NewUserListInfoI {
  idx: number,
  name: string,
  mobile?: string,
  travel_count: number,
  region?: string,
  travel_theme?: string,
  age?: number,
  birth?: string,
  channels?: string,
  email?: string,
  memo?: string,
  address?: string,
  gender?: string,
  group_title?: string,
  order?: string,
}

const dummyData = [
  {
    idx: 2,
    name: '김이박',
    mobile: '01056785678',
    travel_count: 2,
    region: '서울',
    travel_theme: '패키지(품격)',
    age: 40,
    birth: '1980-05-27',
    channels: '타사',
    email: 'mail@mail.com',
    memo: 'memo',
    address: '서울시 중구',
  },
  {
    idx: 1,
    name: '홍길동',
    mobile: '01012341234',
    travel_count: 3,
    region: '인천',
    travel_theme: '패키지(특가)',
    age: 20,
    birth: '2001-10-13',
    channels: '자사',
    email: 'test@test.com',
    memo: 'memo',
    address: '서울시 마포구',
  },
];

interface NewUserListVIEWProps {
  innerStyle: {
    '--left': string,
    '--right': string
  },
  showRight: boolean,
  rightId: number | null,
  infoList: NewUserListInfoI[],
  infoHandler: (idx: number) => void,
  onFormSubmit: React.FormEventHandler<HTMLFormElement>,
  onFormChange: React.FormEventHandler<HTMLFormElement>,
  onSubMutate: () => void,
  dropdownData: useNewDropdownReturnI,
  SearchInfo: {[x: string]: string},
  onPageChange: (type: 'next' | 'prev') => void,
  onDownload: () => void,
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void,
  isChecked: (name: string) => boolean,
  onDelete: () => void,
}

function NewUserListVIEW(props: NewUserListVIEWProps) {
  const {
    innerStyle, showRight, rightId, infoList, onSubMutate, onPageChange,
    infoHandler, onFormSubmit, dropdownData, SearchInfo, onFormChange,
    onDownload, onCheck, isChecked, onDelete,
  } = props;

  return (
    <div className={styles.newUserListDiv} style={{ ...innerStyle as any }}>
      <form className={styles.formDiv} onChange={onFormChange} onSubmit={onFormSubmit}>
        <input placeholder="이름" name="name" value={SearchInfo?.name || ''} />
        <select defaultValue="ALL" name="gender" value={SearchInfo?.gender || 'ALL'}>
          <option value="ALL">성별</option>
          <option value="1">남</option>
          <option value="0">여</option>
        </select>
        <input placeholder="전화번호" name="mobile" value={SearchInfo?.mobile || ''} />
        <select defaultValue="ALL" name="group_idx" value={SearchInfo?.group_idx || 'ALL'}>
          <option value="ALL">그룹</option>
          {
            dropdownData.group.map((t) => (
              <option value={t.idx} key={t.title}>{t.title}</option>
            ))
          }
        </select>
        <select defaultValue="ALL" name="region_idx" value={SearchInfo?.region_idx || 'ALL'}>
          <option value="ALL">지역</option>
          {
            dropdownData.region.map((t) => (
              <option value={t.idx} key={t.region}>{t.region}</option>
            ))
          }
        </select>
        <select defaultValue="ALL" name="theme_idx" value={SearchInfo?.theme_idx || 'ALL'}>
          <option value="ALL">여행테마</option>
          {
            dropdownData.theme.map((t) => (
              <option value={t.idx} key={t.theme}>{t.theme}</option>
            ))
          }
        </select>
        <select defaultValue="ALL" name="age" value={SearchInfo?.age || 'ALL'}>
          <option value="ALL">연령대</option>
          {
            Array(9).fill(0).map((_, t) => {
              const age = 10 * (t + 1);
              return <option value={age} key={`${age}대`}>{`${age}대`}</option>;
            })
          }
        </select>
        <select defaultValue="ALL" name="channel_idx" value={SearchInfo?.channel_idx || 'ALL'}>
          <option value="ALL">유입경로</option>
          {
            dropdownData.channels.map((t) => (
              <option value={t.idx} key={t.channels}>{t.channels}</option>
            ))
          }
        </select>
        <div className={styles.travelTime}>
          <div>여행횟수</div>
          <input defaultValue="0" name="count_min" value={SearchInfo?.count_min || '0'} />
          <div>~</div>
          <input defaultValue="999" name="count_max" value={SearchInfo?.count_max || '999'} />
        </div>
        <select defaultValue="" name="order" value={SearchInfo?.order || ''} style={{ width: '150px' }}>
          <option value="">최신순</option>
          <option value="nameasc">이름 오름차순</option>
          <option value="namedesc">이름 내림차순</option>
          <option value="countasc">여행횟수 오름차순</option>
          <option value="countdesc">여행횟수 내림차순</option>
        </select>
        <button type="submit">검색</button>
      </form>
      <div className={styles.bottomWrapper}>
        <div className={styles.leftWrapper}>
          <div className={styles.leftInner}>
            <div className={styles.leftContent}>
              <CustomTable haveVerticalLine>
                <thead>
                  <tr>
                    <td>
                      <input type="checkbox" name="ALL" onChange={onCheck} checked={isChecked('ALL')} />
                    </td>
                    <td>이름</td>
                    <td>전화번호</td>
                    <td>여행횟수</td>
                    <td>성별</td>
                    <td>지역</td>
                    <td>그룹</td>
                    <td>여행테마</td>
                    <td>연령대</td>
                    <td>생년월일</td>
                    <td>유입경로</td>
                    <td>이메일</td>
                    <td style={{ maxWidth: '200px' }}>메모</td>
                    <td style={{ maxWidth: '200px' }}>주소</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    infoList.map((d) => (
                      <tr style={d.idx === rightId ? { background: '#f0f0f0' } : {}}>
                        <td>
                          <input type="checkbox" name={`${d.idx}`} onChange={onCheck} checked={isChecked(`${d.idx}`)} />
                        </td>
                        <td onClick={() => infoHandler(d.idx)}><button className={styles.textButton} type="button">{d.name}</button></td>
                        <td onClick={() => infoHandler(d.idx)}>{(d?.mobile || '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d.travel_count}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.gender}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.region || ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.group_title || ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.travel_theme || ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.age ? `${d.age}대` : ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.birth || ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.channels || ''}</td>
                        <td onClick={() => infoHandler(d.idx)}>{d?.email || ''}</td>
                        <td onClick={() => infoHandler(d.idx)} style={{ maxWidth: '200px' }}>{d?.memo || ''}</td>
                        <td onClick={() => infoHandler(d.idx)} style={{ maxWidth: '200px' }}>{d?.address || ''}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className={styles.leftFooter}>
            <div>
              <DarkButton width={75} onClick={() => onPageChange('prev')}>이전</DarkButton>
              <div>{`${SearchInfo?.page || 1} 페이지`}</div>
              <DarkButton width={75} onClick={() => onPageChange('next')}>다음</DarkButton>
            </div>
            <DarkButton onClick={onDelete}>선택삭제</DarkButton>
            <DarkButton onClick={onDownload} width={140}>엑셀 파일로 저장</DarkButton>
          </div>
        </div>
        {
          (showRight && rightId) && (
          <>
            <div className={styles.bottomSlider} id="bottomSlider"><div id="bottomSliderInner" /></div>
            <div className={styles.rightWrapper}>
              <div className={styles.rightInner}>
                <NewUserInfoEdit idx={rightId} onMutate={onSubMutate} />
              </div>
            </div>
          </>
          )
        }
      </div>
    </div>
  );
}

export function NewUserList() {
  const { getLoginConfig } = useLogin();
  const [Checked, setChecked] = useState<string[]>([]);
  const dropdownData = useNewDropdown();
  // const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const queryData = Object.fromEntries(query.entries());
  const [SearchInfo, setSearchInfo] = useState({
    page: '1', limit: `${PAGE_LIMIT}`, count_min: '0', count_max: '999', ...queryData,
  });
  useEffect(() => {
    setSearchInfo({
      page: '1', limit: `${PAGE_LIMIT}`, count_min: '0', count_max: '999', ...queryData,
    });
  }, [query]);
  const qD: { [a: string] : string|number} = {
    page: '1', limit: `${PAGE_LIMIT}`, count_min: '0', count_max: '999', ...queryData,
  };
  const params = new URLSearchParams();
  Object.keys(qD).map((t) => params.set(t, `${qD[t]}`));
  const { data: userData, mutate } = useSWR(`/api/admin/offuser?${params.toString()}`, async (url) => {
    const d = await axios.get<{result: NewUserListInfoI[]}>(url, getLoginConfig());
    return d?.data?.result;
  });
  useEffect(() => {
    setChecked([]);
  }, [userData]);
  const [Left, setLeft] = useState(50);
  const [Target, setTarget] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const topRef = useRef<HTMLDivElement>(null);
  const onDragStart:MouseEventHandler<HTMLDivElement> = (e) => {
    const { target } = e;
    if (!target || !((target as HTMLDivElement).id === 'bottomSlider' || (target as HTMLDivElement).id === 'bottomSliderInner') || !topRef?.current) return;
    setIsDragging(true);
  };
  const onDragEnd:MouseEventHandler<HTMLDivElement> = (e) => {
    // const { target } = e;
    // if (!target || !((target as HTMLDivElement).id === 'bottomSlider' || (tar
    // get as HTMLDivElement).id === 'bottomSliderInner') || !topRef?.current) return;
    setIsDragging(false);
  };
  const onDragging:MouseEventHandler<HTMLDivElement> = (e) => {
    const { target } = e;
    if (!isDragging || !topRef?.current) return;
    const topperLeft = topRef.current.offsetLeft;
    const topperWidth = topRef.current.clientWidth;
    const targetNow = e.clientX - 12;
    const percent = ((targetNow - topperLeft) / topperWidth) * 100;
    let realPercent = percent;
    if (percent < 10) realPercent = 10;
    if (percent > 90) realPercent = 90;
    if (realPercent <= 10 || realPercent >= 90) setIsDragging(false);
    setLeft(realPercent);
  };
  const infoHandler = (idx: number) => setTarget((t) => (t === idx ? null : idx));
  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const deleted = deleteBlank(deleteBlankTypeThis, SearchInfo);
    const newParams = new URLSearchParams();
    Object.keys(deleted).map((t) => newParams.set(t, `${deleted[t]}`));
    navigate(`/user/list?${newParams.toString()}`);
  };
  const onFormChange: NewUserListVIEWProps['onFormChange'] = (e) => {
    const { target } = e;
    if (!target) return;
    setSearchInfo((t) => ({
      ...t,
      [(target as HTMLInputElement).name]: (target as HTMLInputElement).value,
    }));
  };
  const onDownload = async () => {
    const downQuery = new URLSearchParams(params);
    downQuery.delete('page');
    downQuery.delete('limit');
    try {
      const d = await axios.post(`/api/admin/offuser/download?${downQuery.toString()}`, undefined, { ...getLoginConfig(), responseType: 'blob' });
      const url = URL.createObjectURL(d.data);
      // eslint-disable-next-line no-restricted-globals
      location.href = url;
    } catch (e) {
      alert('오류가 발생했습니다.');
      console.error(e);
    }
  };
  const onPageChange = (type: 'next' | 'prev') => {
    const nowPage = parseInt(SearchInfo.page, 10);
    if (nowPage === 1 && type === 'prev') {
      alert('첫번째 페이지입니다.');
      return;
    }
    if ((userData?.length || 0) < PAGE_LIMIT && type === 'next') {
      alert('다음 페이지가 없습니다.');
      return;
    }
    const newPage = nowPage + (type === 'next' ? 1 : -1);
    const deleted = deleteBlank(deleteBlankTypeThis, { ...SearchInfo, page: `${newPage}` });
    const newParams = new URLSearchParams();
    Object.keys(deleted).map((t) => newParams.set(t, `${deleted[t]}`));
    navigate(`/user/list?${newParams.toString()}`);
  };
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.stopPropagation();
    const { name, checked } = e.target;
    if (name === 'ALL') {
      if (checked) {
        setChecked(userData ? userData.map((t) => `${t.idx}`) : []);
      } else {
        setChecked([]);
      }
    } else {
      if (checked) {
        setChecked((t) => [...t, name]);
      } else {
        setChecked((t) => t.filter((d) => d !== name));
      }
    }
  };
  const isChecked = (name: string) => {
    if (name === 'ALL') return Checked.length === userData?.length;
    return Checked.indexOf(name) !== -1;
  };
  const onDelete = async () => {
    if (Checked.length === 0) {
      alert('선택된 회원이 없습니다.');
      return;
    }
    const intList = Checked.map((t) => parseInt(t, 10));
    if (!confirm(`회원 ${intList.length}명을 삭제하시겠습니까?`)) return;
    const d = await axios.delete<{statusCode: number}>('/api/admin/offuser', { ...getLoginConfig(), data: { user_arr: intList } });
    if (d.data.statusCode === 201) {
      setChecked([]);
      mutate();
      alert('삭제했습니다.');
    }
  };
  const subProps: NewUserListVIEWProps = {
    innerStyle: {
      '--left': `${Target === null ? 100 : Left}%`,
      '--right': `${100 - Left}%`,
    },
    showRight: Target !== null,
    rightId: Target,
    infoList: userData || [],
    infoHandler,
    onFormSubmit,
    dropdownData,
    SearchInfo,
    onFormChange,
    onSubMutate: () => mutate(),
    onPageChange,
    onDownload,
    onCheck,
    isChecked,
    onDelete,
  };
  return (
    <div
      className={styles.eventWrapper}
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      onMouseMove={onDragging}
      ref={topRef}
    >
      <NewUserListVIEW {...subProps} />
    </div>
  );
}

export default NewUserList;
