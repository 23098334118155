import React, { useEffect, useState } from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import styles from '../Css/Header.module.css';
import pageMap from '../Meta/PageMap';

function Header() {
  const location = useLocation();

  return (
    <div className={styles.HeaderDiv}>
      <div className={styles.logout}><Link to="/logout">로그아웃</Link></div>
      <div className={styles.HeaderInner}>
        <Link className={styles.logoLink} to="/"><img src="/logo.png" alt="" /></Link>
        <nav>
          <ul>
            {
              pageMap.map((cate, idx) => (
                <li key={`header${cate.link}`} className={`${useMatch(`/${cate.link}/*`) && styles.nowLink}`}>
                  {/* <li key={`header${cate.link}`} className={`${location.pat */}
                  {/* hname.startsWith(`/${cate.link}`) && styles.nowLink}`}> */}
                  <Link to={`/${cate.link}/${cate.bigSubList[0].subList[0].link}`}>{cate.name}</Link>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
      <div className={styles.HeaderLine} />
    </div>
  );
}

export default Header;
