/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/UserList.module.css';
import { useReserveState, useAdminList, useGrade } from '../../Middle/useETC';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function UserList() {
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/admin/user?limit=${PAGE_SIZE}`);
  const { data: reserveState } = useReserveState();
  const { data: adminData } = useAdminList();
  const { data: gradeData } = useGrade();
  const [SearchParam, setSearchParam] = useState({
    name: '',
    userid: '',
    gender: -1,
    grade: -1,
    phone: '',
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    idx: number
    email_check: number
    sms_check: number
    userid: string
    name: string
    phone: string
    email: string
    created_at: string
    tour_count: number
  }>(
    (index) => `${URL}&page=${index + 1}`,
    '',
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    query.append('limit', `${PAGE_SIZE}`);
    const checkAndAppend = (txt: keyof typeof SearchParam) => {
      const tmp = SearchParam[txt];
      if (typeof tmp === 'string' && tmp.trim().length > 0) {
        query.append(txt, tmp);
      } else if (tmp !== -1) {
        query.append(txt, `${tmp}`);
      }
    };
    Object.keys(SearchParam).map((key) => checkAndAppend(key as keyof typeof SearchParam));
    setURL(`/api/admin/user?${query.toString()}`);
  };
  const yes = <div className={`${styles.yesorno} ${styles.yes}`}>O</div>;
  const no = <div className={`${styles.yesorno} ${styles.no}`}>X</div>;
  const onDelete = async (idx: number, name: string, id: string) => {
    if (!confirm(`${name}(${id}) 회원을 탈퇴처리 하시겠습니까?`)) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/admin/user/${idx}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('탈퇴처리 했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.userListDiv}>
      <InnerTitle>회원조회</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={70}>
        <div>
          <div>
            <div>이름</div>
            <div><ThemeInput value={SearchParam.name} onChange={(e) => setSearchParam({ ...SearchParam, name: e.target.value })} type="text" style={{ width: '120px', height: '36px' }} /></div>
          </div>
          <div>
            <div>성별</div>
            <div>
              <select
                value={SearchParam.gender}
                onChange={(e) => setSearchParam({
                  ...SearchParam, gender: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>전체</option>
                <option value={1}>남성</option>
                <option value={0}>여성</option>
              </select>
            </div>
          </div>
          <div>
            <div>등급</div>
            <div>
              <select
                value={SearchParam.grade}
                onChange={(e) => setSearchParam({
                  ...SearchParam, grade: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>전체</option>
                {
                  gradeData?.idxList.map((i) => (
                    <option value={i}>{gradeData[i].title}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>아이디</div>
            <div><ThemeInput value={SearchParam.userid} onChange={(e) => setSearchParam({ ...SearchParam, userid: e.target.value })} type="text" style={{ width: '180px', height: '36px' }} /></div>
          </div>
          <div>
            <div>전화번호</div>
            <div><ThemeInput value={SearchParam.phone} onChange={(e) => setSearchParam({ ...SearchParam, phone: e.target.value })} type="tel" style={{ width: '180px', height: '36px' }} /></div>
          </div>
        </div>
        {/* <div>
          <div>
            <div>여행목적</div>
            <div>
              <input type="checkbox" />
              <label>가족여행</label>
              <input type="checkbox" />
              <label>친구모임</label>
              <input type="checkbox" />
              <label>부부모임</label>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>여행형태</div>
            <div>
              <input type="checkbox" />
              <label>자유여행</label>
              <input type="checkbox" />
              <label>패키지여행</label>
              <input type="checkbox" />
              <label>골프여행</label>
            </div>
          </div>
        </div> */}
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={80} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>메일</td>
            <td>SMS</td>
            <td>아이디</td>
            <td>이름</td>
            <td>전화번호</td>
            <td>이메일</td>
            <td>가입일</td>
            <td>여행횟수</td>
            <td>탈퇴하기</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((d) => (
              <tr>
                <td><DivInTable>{d.sms_check === 1 ? yes : no}</DivInTable></td>
                <td><DivInTable>{d.email_check === 1 ? yes : no}</DivInTable></td>
                <td><Link to={`./${d.idx}`}>{d.userid}</Link></td>
                <td><Link to={`./${d.idx}`}>{d.name}</Link></td>
                <td>{d.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                <td>{d.email}</td>
                <td>{d.created_at}</td>
                <td>{d.tour_count}</td>
                <td>
                  <DarkButton
                    onClick={() => onDelete(d.idx, d.name, d.userid)}
                    width={50}
                  >
                    탈퇴
                  </DarkButton>
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default UserList;
