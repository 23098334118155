/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import useCategory from '../../Middle/useCategory';
import {
  useCountry, useCity, useAdminList, useHotelType, useSightType,
} from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import { goodDefaultInfo } from '../Good/GoodInfo';
import styles from '../../Css/GoodInfo.module.css';
import InnerTitle from '../../Components/InnerTitle';
import ImageInput from '../../Components/ImageInput';
import { sightDefaultInfo } from './SightRegister';

function SightInfoDefault(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const category = useCategory();
  const navigate = useNavigate();
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const { data: adminData } = useAdminList();
  const { data: sightData } = useSightType();
  const { data: fetchData, mutate } = useSWR(`/api/sight/${idx}/info`, async (url) => {
    const res = await axios.get<{result: sightDefaultInfo}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [NowInfoData, setNowInfoData] = useState<sightDefaultInfo | undefined | null>(null);
  useEffect(() => {
    if (fetchData) setNowInfoData(fetchData);
  }, [fetchData]);
  if (!NowInfoData || category.firstCategoryIdxList.length === 0) return <div />;
  const checkData = () => {
    const dataToCheck = { ...NowInfoData };
    const error = {
      isError: false,
      reason: '',
    };
    const setError = (where: string) => {
      if (!error.isError) {
        error.isError = true;
        error.reason = `${where}을(를) 확인해주세요`;
      }
    };
    if (dataToCheck.title.trim().length === 0) setError('호텔명');
    if (dataToCheck.continent === -1) setError('대륙');
    if (dataToCheck.country === -1) setError('국가');
    if (dataToCheck.city === -1) dataToCheck.city = null;
    if (dataToCheck.sight_type === -1) setError('분류');
    return {
      error,
      data: {
        ...dataToCheck,
      },
    };
  };
  const onNext = async () => {
    const { error, data } = checkData();
    if (error.isError) {
      alert(error.reason);
      return;
    }
    try {
      const res = await axios.put<{statusCode: number, result: {insertId: number}}>(`/api/sight/${idx}/info`, data, getLoginConfig());
      if (res.data.statusCode === 201) {
        alert('수정했습니다.');
        mutate();
      } else {
        throw Error;
      }
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  if (!countryData) return <div />;
  return (
    <div className={`${styles.innerMain}`}>
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>관광지명</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.title}
                onChange={(e) => setNowInfoData({ ...NowInfoData, title: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>관광지구분</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.sight_type || -1}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  sight_type: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>분류</option>
                {
                  sightData?.idxList.map((i) => (
                    <option value={`${sightData[i].idx}`}>{sightData[i].title}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>지역</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.country || -1}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  continent: countryData[parseInt(e.target.value, 10)].continent,
                  country: parseInt(e.target.value, 10),
                  city: -1,
                })}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.city || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  city: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${NowInfoData.country}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>홈페이지</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.siteurl || ''}
                onChange={(e) => setNowInfoData({ ...NowInfoData, siteurl: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>관광지 소개</div>
            <div>
              <ThemeInput
                style={{
                  width: '100%',
                  height: '135px',
                }}
                value={NowInfoData.content || ''}
                onChange={(e) => setNowInfoData({ ...NowInfoData, content: e.target.value })}
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={() => onNext()}>수정</DarkButton>
    </div>
  );
}

export default SightInfoDefault;
