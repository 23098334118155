/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unstable-nested-components */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import Calendar from '../../Components/Calendar';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodInfo.module.css';
import getDateString from '../../Middle/getDateString';
import useLogin from '../../Middle/useLogin';
import { EmployeeScheduleI } from './EmployeeSchedule';

export interface EmployeeScheduleInfoI {
  'idx': number,
  'manager_idx': number,
  'manager_name': string,
  'title': string,
  'content': string,
  'is_author': boolean
}

function EmployeeScheduleInfo() {
  const today = new Date();
  const { date } = useParams();
  const { getLoginConfig } = useLogin();
  const [Year, setYear] = useState(today.getFullYear());
  const [Month, setMonth] = useState(today.getMonth() + 1);
  const [Show, setShow] = useState(false);
  const [NewData, setNewData] = useState({
    idx: -1, title: '', content: '',
  });
  const { data, mutate } = useSWR(`/api/admin/schedule?date=${date}`, async (url) => {
    const res = await axios.get<{result: EmployeeScheduleInfoI[]}>(url, getLoginConfig());
    return res.data.result;
  });
  useEffect(() => {
    if (Show) return;
    setNewData({
      idx: -1, title: '', content: '',
    });
  }, [Show]);
  if (!date || !data) return <div />;
  const openChange = (i: number) => {
    const tmp = data[i];
    setNewData({
      idx: tmp.idx, title: tmp.title, content: tmp.content,
    });
    setShow(true);
  };
  const onRegister = async () => {
    try {
      const res = await axios.put<{statusCode: number}>(`/api/admin/schedule/${NewData.idx}`, {
        title: NewData.title, content: NewData.content,
      }, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      setShow(false);
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  const onDelete = async (idx: number) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      const res = await axios.delete<{statusCode: number}>(`/api/admin/schedule/${idx}`, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('삭제했습니다.');
      setShow(false);
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.innerMain}`}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.addDiv}>
          <LeftRight leftWidth={40}>
            <div>
              <div>
                <div>제목</div>
                <div><ThemeInput value={NewData.title} onChange={(e) => setNewData({ ...NewData, title: e.target.value })} style={{ width: '400px', height: '36px' }} /></div>
              </div>
            </div>
            <div>
              <div>
                <div>내용</div>
                <div><ThemeInput value={NewData.content} onChange={(e) => setNewData({ ...NewData, content: e.target.value })} style={{ width: '100%', height: '36px' }} /></div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={50} />
          <DarkButton onClick={onRegister}>등록</DarkButton>
        </div>
      </ModalWrapper>
      <InnerTitle>{`일정 관리 - ${date}`}</InnerTitle>
      <BlankMaker height={50} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>직원명</td>
            <td>제목</td>
            <td>내용</td>
            <td>관리</td>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((d, i) => (
              <tr>
                <td>{d.manager_name}</td>
                <td>{d.title}</td>
                <td>{d.content}</td>
                <td>
                  {
                    !d.is_author ? '-'
                      : (
                        <div className={styles.buttonsDiv}>
                          <DarkButton width={60} height={36} bg="#3386FF" onClick={() => openChange(i)}>수정</DarkButton>
                          <BlankMaker width={10} />
                          <DarkButton width={60} height={36} bg="#FF4E40" onClick={() => onDelete(d.idx)}>삭제</DarkButton>
                        </div>
                      )
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
    </div>
  );
}

export default EmployeeScheduleInfo;
