/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodList.module.css';
import useCategory from '../../Middle/useCategory';
import { useCity, useCountry } from '../../Middle/useETC';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function HotelList() {
  const category = useCategory();
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/hotel?limit=${PAGE_SIZE}`);
  const [SearchParam, setSearchParam] = useState({
    keyword: '',
    country: -1,
    city: -1,
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'title': string,
    'thumb_image': string,
    'created_at': string,
    'in_use': number
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'hotel',
    PAGE_SIZE,
  );
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const makeURL = () => {
    const query = new URLSearchParams();
    const {
      keyword, country, city,
    } = SearchParam;
    query.append('limit', `${PAGE_SIZE}`);
    if (keyword.trim().length > 0) query.append('keyword', keyword.trim());
    if (country !== -1) query.append('country', `${country}`);
    if (city !== -1) query.append('city', `${city}`);
    setURL(`/api/hotel?${query.toString()}`);
  };
  const deleteGood = async (idx: number, title: string) => {
    if (confirm(`${title} 상품을 삭제하시겠습니까?`)) {
      try {
        const res = await axios.delete(`/api/hotel/${idx}`, getLoginConfig());
        alert('삭제했습니다.');
        mutate();
      } catch {
        alert('오류가 발생했습니다.');
      }
    }
  };
  return (
    <div className={styles.goodListDiv}>
      <InnerTitle>호텔관리</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={70}>
        <div>
          <div>
            <div>호텔명</div>
            <div>
              <ThemeInput
                type="text"
                value={SearchParam.keyword}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, keyword: e.target.value,
                }))}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>국가</div>
            <div>
              <select
                value={SearchParam.country}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, country: parseInt(e.target.value, 10), city: -1,
                }))}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                value={SearchParam.city}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, city: parseInt(e.target.value, 10),
                }))}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${SearchParam.country}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={100} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>이미지</td>
            <td>호텔명</td>
            <td>등록일</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((lis) => (
              <tr>
                <td>{lis.idx}</td>
                <td><DivInTable><img src={`${lis.thumb_image}`} alt={`${lis.title}`} /></DivInTable></td>
                <td><Link to={`./${lis.idx}/info`}>{lis.title}</Link></td>
                <td>{lis.created_at}</td>
                <td><DivInTable><button type="button" onClick={() => deleteGood(lis.idx, lis.title)} className={styles.deleteButton}>삭제</button></DivInTable></td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default HotelList;
