import axios from 'axios';
import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import ThemeInput from '../../Components/ThemeInput';
import { useGrade } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import styles from '../../Css/UserInfo.module.css';

export interface userRegisterI {
  userid: string
  name: string
  password: string
  mobile: string
  english_last_name: string
  english_first_name: string
  email: string
  gender: number
  birth: string
  travel_type: number
  travel_goal: number
}

function UserRegister() {
  const { getLoginConfig } = useLogin();
  const { data: gradeData } = useGrade();
  const navigate = useNavigate();
  const [NewData, setNewData] = useState<userRegisterI | null>({
    userid: '',
    name: '',
    password: '',
    mobile: '',
    english_first_name: '',
    english_last_name: '',
    email: '',
    gender: 1,
    birth: '',
    travel_type: 0,
    travel_goal: 0,
  });
  const onNext = async () => {
    try {
      const res = await axios.post<{statusCode: number}>('/api/admin/user', NewData, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('등록했습니다.');
      navigate('/user/list');
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  if (!NewData) return <div />;
  return (
    <div className={`${styles.mainDiv} ${styles.infoDiv}`}>
      <InnerTitle>회원등록</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>이름 *</div>
            <div>
              <ThemeInput
                value={NewData.name}
                onChange={(e) => setNewData({ ...NewData, name: e.target.value })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>아이디 *</div>
            <div>
              <ThemeInput
                value={NewData.userid}
                onChange={(e) => setNewData({
                  ...NewData,
                  userid: e.target.value.toLowerCase(),
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
          <div>
            <div>비밀번호 *</div>
            <div>
              <ThemeInput
                value={NewData.password || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  password: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>영문 성</div>
            <div>
              <ThemeInput
                value={NewData.english_last_name || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  english_last_name: e.target.value.toLowerCase(),
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
          <div>
            <div>영문 이름</div>
            <div>
              <ThemeInput
                value={NewData.english_first_name || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  english_first_name: e.target.value.toLowerCase(),
                })}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>성별</div>
            <div>
              <select
                value={typeof NewData.gender === 'number' ? NewData.gender : -1}
                onChange={(e) => setNewData({
                  ...NewData,
                  gender: parseInt(e.target.value, 10),
                })}
              >
                <option value={1}>남</option>
                <option value={0}>여</option>
              </select>
            </div>
          </div>
          <div>
            <div>생년월일</div>
            <div>
              <ThemeInput
                value={NewData.birth || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  birth: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="date"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>전화번호 *</div>
            <div>
              <ThemeInput
                value={NewData.mobile || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  mobile: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="tel"
              />
            </div>
          </div>
          <div>
            <div>이메일</div>
            <div>
              <ThemeInput
                value={NewData.email || ''}
                onChange={(e) => setNewData({
                  ...NewData,
                  email: e.target.value,
                })}
                style={{ width: '200px', height: '36px' }}
                type="email"
              />
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={onNext}>등록</DarkButton>
    </div>
  );
}

export default UserRegister;
