import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import useLogin from '../../Middle/useLogin';
import styles from '../../Css/ReservationInfo.module.css';
import InnerTitle from '../../Components/InnerTitle';
import BlankMaker from '../../Components/BlankMaker';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import { useAdminList, useReserveState } from '../../Middle/useETC';
import LeftRight from '../../Components/LeftRight';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';

export interface reservationDetailI {
  'reservation': {
    'event_detail_idx': number,
    'rsv_number': string,
    'userid': string,
    'rsv_name': string,
    'rsv_gender': number,
    'rsv_birth': string,
    'rsv_phone': string,
    'rsv_email': string,
    'rsv_comment': string,
    'manager_idx': number,
    'rsv_status': number
  },
  'good': {
    'title': string,
    'days': number,
    'nights': number,
    'departure_start_time': string,
    'departure_start_time_korea': number,
    'departure_end_time': string,
    'departure_end_time_korea': number,
    'departure_transportation': string,
    'arrival_start_time': string,
    'arrival_start_time_korea': number,
    'arrival_end_time': string,
    'arrival_end_time_korea': number,
    'arrival_transportation': string,
  },
  'option': {
    'option_name': string,
    'adult_count': number,
    'child_count': number,
    'infant_count': number,
    'baby_count': number
  }[],
  'additional_option': {
    'option_name': string,
    'count': number,
  }[],
  'people': {
      'idx': number,
      'age_type': string,
      'korean_name': string,
      'english_last_name': string,
      'english_first_name': string,
      'birth': string,
      'phone': string,
      'gender': number,
      'passport_number': string|null|undefined,
      'passport_expired_date': string|null|undefined,
      'comments': string|null|undefined
  }[],
  'payment'?: {
      'state': string,
      'good_price': number,
      'mileage': number,
      'total_price': number,
      'payment_date'?: string,
      'payment_info'?: string,
  }
}

export interface changeReservationI {
  'rsv_name' : string
  'rsv_phone' : string
  'rsv_email' : string
  'rsv_gender' : number,
  'rsv_birth' : string
  'manager_idx' : number,
  'rsv_status' : number,
  'people' : {
      'idx' : number,
      'korean_name' : string
      'english_first_name' : string
      'english_last_name' : string
      'birth' : string,
      'gender' : number,
      'phone' : string,
      'passport_number': string|null|undefined,
      'passport_expired_date': string|null|undefined,
      'comments': string|null|undefined
    }[]
}

function ReservationInfo() {
  const { idx } = useParams();
  const { getLoginConfig } = useLogin();
  const { data: reserveState } = useReserveState();
  const { data: adminData } = useAdminList();
  const [NewData, setNewData] = useState<null | changeReservationI>(null);
  const { data, mutate } = useSWR(`/api/admin/reservation/${idx}`, async (url) => {
    const res = await axios.get<{result: reservationDetailI}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (data) {
      setNewData({
        rsv_name: data.reservation.rsv_name,
        rsv_phone: data.reservation.rsv_phone,
        rsv_email: data.reservation.rsv_email,
        rsv_gender: data.reservation.rsv_gender,
        rsv_birth: data.reservation.rsv_birth,
        manager_idx: data.reservation.manager_idx,
        rsv_status: data.reservation.rsv_status,
        people: data.people,
      });
    }
  }, [data]);
  const korean = <div className={styles.korean}>한국기준</div>;
  const abroad = <div className={styles.aborad}>현지기준</div>;
  if (!idx || !data || !NewData) return <div />;
  const peopleChanger = (newIdx: number, type: keyof typeof NewData.people[0], newValue: any) => {
    const newInfo = { ...NewData.people[newIdx] };
    (newInfo[type] as any as unknown) = newValue;
    setNewData({
      ...NewData,
      people: [
        ...NewData.people.slice(0, newIdx),
        newInfo,
        ...NewData.people.slice(newIdx + 1),
      ],
    });
  };
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>예약내역</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={100}>
        <div>
          <div>
            <div>예약번호</div>
            <div><ThemeInput disabled value={data.reservation.rsv_number} style={{ width: '180px', height: '36px' }} /></div>
          </div>
          <div>
            <div>아이디</div>
            <div><ThemeInput disabled value={data.reservation.userid} style={{ width: '180px', height: '36px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>예약자명</div>
            <div><ThemeInput value={NewData.rsv_name} onChange={(e) => setNewData({ ...NewData, rsv_name: e.target.value })} style={{ width: '180px', height: '36px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>전화번호</div>
            <div><ThemeInput type="tel" value={NewData.rsv_phone} onChange={(e) => setNewData({ ...NewData, rsv_phone: e.target.value })} style={{ width: '180px', height: '36px' }} /></div>
          </div>
          <div>
            <div>이메일</div>
            <div><ThemeInput type="email" value={NewData.rsv_email} onChange={(e) => setNewData({ ...NewData, rsv_email: e.target.value })} style={{ width: '180px', height: '36px' }} /></div>
          </div>
        </div>
        <div>
          <div>
            <div>예약상태</div>
            <div>
              <select
                value={NewData.rsv_status}
                onChange={(e) => setNewData({
                  ...NewData,
                  rsv_status: parseInt(e.target.value, 10),
                })}
              >
                {
                    reserveState?.idxList.map((i) => (
                      <option value={i}>{reserveState[i].status}</option>))
                }
              </select>
            </div>
          </div>
          <div>
            <div>담당자</div>
            <div>
              <select
                value={NewData.manager_idx}
                onChange={(e) => setNewData({
                  ...NewData,
                  manager_idx: parseInt(e.target.value, 10),
                })}
              >
                {
                    adminData?.idxList.map((i) => (
                      <option value={i}>{adminData[i].name}</option>))
                }
              </select>
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={80} />
      <InnerTitle>상품정보</InnerTitle>
      <BlankMaker height={24} />
      <div className={styles.leftBlank}>
        <LeftRight leftWidth={80}>
          <div>
            <div>
              <div>상품명</div>
              <div>{data.good.title}</div>
            </div>
          </div>
          <div>
            <div>
              <div>여행기간</div>
              <div>{`${data.good.nights}박 ${data.good.days}일`}</div>
            </div>
          </div>
          <div>
            <div>
              <div>출발 일정</div>
              <div>
                {data.good.departure_start_time_korea === 1 ? korean : abroad}
                <BlankMaker width={10} />
                <div>{data.good.departure_start_time}</div>
                <BlankMaker width={15} />
                <div>~</div>
                <BlankMaker width={15} />
                {data.good.departure_end_time_korea === 1 ? korean : abroad}
                <BlankMaker width={10} />
                <div>{data.good.departure_end_time}</div>
                <BlankMaker width={30} />
                <div>{data.good.departure_transportation}</div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>도착 일정</div>
              <div>
                {data.good.arrival_start_time_korea === 1 ? korean : abroad}
                <BlankMaker width={10} />
                <div>{data.good.arrival_start_time}</div>
                <BlankMaker width={15} />
                <div>~</div>
                <BlankMaker width={15} />
                {data.good.arrival_end_time_korea === 1 ? korean : abroad}
                <BlankMaker width={10} />
                <div>{data.good.arrival_end_time}</div>
                <BlankMaker width={30} />
                <div>{data.good.arrival_transportation}</div>
              </div>
            </div>
          </div>
        </LeftRight>
      </div>
      <BlankMaker height={80} />
      <InnerTitle>옵션 정보</InnerTitle>
      <BlankMaker height={24} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>옵션명</td>
            <td>성인</td>
            <td>소아</td>
            <td>유아</td>
            <td>영아</td>
          </tr>
        </thead>
        <tbody>
          {
            data.option.map((d) => (
              <tr>
                <td>{d.option_name}</td>
                <td>{d.adult_count === 0 ? '' : d.adult_count}</td>
                <td>{d.child_count === 0 ? '' : d.child_count}</td>
                <td>{d.infant_count === 0 ? '' : d.infant_count}</td>
                <td>{d.baby_count === 0 ? '' : d.baby_count}</td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      {
        data.additional_option.length > 0 && (
          <>
            <BlankMaker height={80} />
            <InnerTitle>추가옵션 정보</InnerTitle>
            <BlankMaker height={24} />
            <CustomTable>
              <thead>
                <tr>
                  <td>옵션명</td>
                  <td>개수</td>
                </tr>
              </thead>
              <tbody>
                {
                data.additional_option.map((d) => (
                  <tr>
                    <td>{d.option_name}</td>
                    <td>{d.count}</td>
                  </tr>
                ))
              }
              </tbody>
            </CustomTable>
          </>
        )
      }
      <BlankMaker height={80} />
      <InnerTitle>여행자 정보</InnerTitle>
      <BlankMaker height={24} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>구분</td>
            <td>성별</td>
            <td>한글이름</td>
            <td>영문 (성/이름)</td>
            <td>전화번호</td>
            <td>생년월일</td>
            <td>여권번호</td>
            <td>여권만료일</td>
            <td>비고</td>
          </tr>
        </thead>
        <tbody>
          {
            NewData.people.map((d, i) => (
              <tr>
                <td>{data.people[i].age_type}</td>
                <td>
                  <select value={d.gender} onChange={(e) => peopleChanger(i, 'gender', e.target.value)}>
                    <option value="1">남</option>
                    <option value="0">여</option>
                  </select>
                </td>
                <td>
                  <ThemeInput value={d.korean_name} onChange={(e) => peopleChanger(i, 'korean_name', e.target.value)} style={{ width: '75px', height: '36px', textAlign: 'center' }} />
                </td>
                <td>
                  <ThemeInput
                    value={d.english_last_name}
                    onChange={(e) => peopleChanger(i, 'english_last_name', e.target.value)}
                    style={{
                      width: '60px', height: '36px', marginRight: '6px', textAlign: 'center',
                    }}
                  />
                  <ThemeInput value={d.english_first_name} onChange={(e) => peopleChanger(i, 'english_first_name', e.target.value)} style={{ width: '100px', height: '36px', textAlign: 'center' }} />
                </td>
                <td>
                  <ThemeInput value={d.phone} onChange={(e) => peopleChanger(i, 'phone', e.target.value)} type="tel" style={{ width: '120px', height: '36px', textAlign: 'center' }} />
                </td>
                <td>
                  <ThemeInput
                    value={d.birth}
                    onChange={(e) => peopleChanger(i, 'birth', e.target.value)}
                    type="date"
                    style={{
                      width: '140px', height: '36px', textAlign: 'center', fontSize: '11px', fontFamily: 'inherit',
                    }}
                  />
                </td>
                <td>
                  <ThemeInput value={d.passport_number || ''} onChange={(e) => peopleChanger(i, 'passport_number', e.target.value)} style={{ width: '100px', height: '36px', textAlign: 'center' }} />
                </td>
                <td>
                  <ThemeInput
                    value={d.passport_expired_date || ''}
                    onChange={(e) => peopleChanger(i, 'passport_expired_date', e.target.value)}
                    type="date"
                    style={{
                      width: '140px', height: '36px', textAlign: 'center', fontSize: '11px', fontFamily: 'inherit',
                    }}
                  />
                </td>
                <td>
                  <ThemeInput value={d.comments || ''} onChange={(e) => peopleChanger(i, 'comments', e.target.value)} style={{ width: '100px', height: '36px', textAlign: 'center' }} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      {
        data.payment && (
          <>
            <BlankMaker height={80} />
            <InnerTitle>결제 내역</InnerTitle>
            <BlankMaker height={24} />
            <div className={styles.leftBlank}>
              <LeftRight leftWidth={100}>
                <div>
                  <div>
                    <div>결제 상태</div>
                    <div>{data.payment.state}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>총 결제 금액</div>
                    <div>{`${data.payment.good_price.toLocaleString('ko-KR')}원`}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>사용 적립금</div>
                    <div>{`${data.payment.mileage.toLocaleString('ko-KR')}원`}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>결제 금액</div>
                    <div>{`${data.payment.total_price.toLocaleString('ko-KR')}원`}</div>
                  </div>
                </div>
                {
                  data.payment.payment_info ? (
                    <div>
                      <div>
                        <div>가상계좌</div>
                        <div>{data.payment.payment_info}</div>
                      </div>
                    </div>
                  ) : ''
                }
                {
                  data.payment.payment_date ? (
                    <div>
                      <div>
                        <div>결제일시</div>
                        <div>{data.payment.payment_date}</div>
                      </div>
                    </div>
                  ) : ''
                }
              </LeftRight>
            </div>
          </>
        )
      }
      <BlankMaker height={60} />
      <div className={styles.buttons}>
        <DarkButton
          bg="#3386FF"
          onClick={async () => {
            try {
              const res = await axios.put<{statusCode: number}>(`/api/admin/reservation/${idx}`, NewData, getLoginConfig());
              const code = res.data.statusCode;
              if (code !== 201) throw Error;
              alert('수정했습니다.');
              mutate();
            } catch {
              alert('오류가 발생했습니다.');
            }
          }}
        >
          예약 수정
        </DarkButton>
      </div>
    </div>
  );
}

export default ReservationInfo;
