import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import styles from '../../Css/GoodInfo.module.css';
import useLogin from '../../Middle/useLogin';

function GoodInfoTable(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const { data, mutate } = useSWR(`/api/admin/good/${idx}/schedule-table`, async (url) => {
    const res = await axios.get<{result: {schedule_text: string|null}}>(url, getLoginConfig());
    return res.data.result.schedule_text;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [NewData, setNewData] = useState('');
  useEffect(() => {
    if (data || data === null) setNewData(data || '');
  }, [data]);
  return (
    <div className={`${styles.innerMain}`}>
      <CustomEditor data={NewData} onChange={(event, editor) => setNewData(editor.getData())} />
      <BlankMaker height={50} />
      <DarkButton onClick={async () => {
        try {
          const res = await axios.put(`/api/admin/good/${idx}/schedule-table`, { text: NewData }, getLoginConfig());
          if (res.data.statusCode !== 201) throw Error;
          alert('수정했습니다.');
          mutate();
        } catch {
          alert('오류가 발생했습니다.');
        }
      }}
      >
        저장
      </DarkButton>
    </div>
  );
}

export default GoodInfoTable;
