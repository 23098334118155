/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ModalWrapper from '../../Components/ModalWrapper';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/ExpenseList.module.css';
import getDateString from '../../Middle/getDateString';
import useLogin from '../../Middle/useLogin';

function ExpenseList() {
  const { getLoginConfig } = useLogin();
  const today = new Date();
  const ago = new Date();
  ago.setMonth(ago.getMonth() - 1);
  const [URL, setURL] = useState(`/api/admin/expense?start_date=${getDateString(ago)}&end_date=${getDateString(today)}`);
  const [Show, setShow] = useState(false);
  const [SearchParam, setSearchParam] = useState({
    start_date: getDateString(ago),
    end_date: getDateString(today),
  });
  const [AddParam, setAddParam] = useState({
    is_expense: false,
    price: 0,
    content: '',
    date: getDateString(today),
    note: '',
  });
  const { data, mutate } = useSWR(URL, async (url) => {
    const res = await axios.get<{result: {
      'income': {
        'idx': number,
        'price': number,
        'content': string,
        'created_at': string,
        'note': string
      }[],
      'expense': {
        'idx': number,
        'price': number,
        'content': string,
        'created_at': string,
        'note': string
      }[],
      'total_income': number,
      'total_expense': number,
      'total_sum': number
    }}>(url, getLoginConfig());
    return res.data.result;
  });
  useEffect(() => {
    setAddParam({
      is_expense: false,
      price: 0,
      content: '',
      date: getDateString(today),
      note: '',
    });
  }, [Show]);
  const makeURL = () => {
    const { start_date, end_date } = SearchParam;
    if (start_date.length === 0 || end_date.length === 0) {
      alert('시작일과 종료일 모두 지정되어야 합니다.');
      return;
    }
    setURL(`/api/admin/expense?start_date=${start_date}&end_date=${end_date}`);
  };
  if (!data) return <div />;
  return (
    <div className={styles.expenseListDiv}>
      <ModalWrapper Show={Show} onShowOff={() => setShow(false)}>
        <div className={styles.modal}>
          <LeftRight leftWidth={50}>
            <div>
              <div>
                <div>구분</div>
                <div>
                  <select value={AddParam.is_expense ? 'true' : 'false'} onChange={(e) => setAddParam({ ...AddParam, is_expense: e.target.value === 'true' })}>
                    <option value="false">매출</option>
                    <option value="true">지출</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>금액</div>
                <div>
                  <ThemeInput type="number" style={{ width: '200px', height: '36px' }} value={AddParam.price} onChange={(e) => setAddParam({ ...AddParam, price: Math.abs(parseInt(e.target.value, 10)) })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>항목</div>
                <div>
                  <ThemeInput type="text" style={{ width: '200px', height: '36px' }} value={AddParam.content} onChange={(e) => setAddParam({ ...AddParam, content: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>일자</div>
                <div>
                  <ThemeInput type="date" style={{ width: '200px', height: '36px' }} value={AddParam.date} onChange={(e) => setAddParam({ ...AddParam, date: e.target.value })} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>비고</div>
                <div>
                  <ThemeInput type="text" style={{ width: '400px', height: '36px' }} value={AddParam.note} onChange={(e) => setAddParam({ ...AddParam, note: e.target.value })} />
                </div>
              </div>
            </div>
          </LeftRight>
          <BlankMaker height={30} />
          <DarkButton onClick={async () => {
            if (AddParam.date.length === 0) {
              alert('일자를 지정해주세요.');
              return;
            }
            try {
              const res = await axios.post<{statusCode: number}>('/api/admin/expense', AddParam, getLoginConfig());
              const { statusCode } = res.data;
              if (statusCode !== 201) throw Error;
              alert('추가했습니다.');
              setShow(false);
              mutate();
            } catch {
              alert('오류가 발생했습니다.');
            }
          }}
          >
            추가
          </DarkButton>
        </div>
      </ModalWrapper>
      <InnerTitle>정산조회</InnerTitle>
      <div className={styles.optionListDiv}>
        <ThemeInput type="date" style={{ width: '180px', height: '40px' }} value={SearchParam.start_date} onChange={(e) => setSearchParam({ ...SearchParam, start_date: e.target.value })} />
        <div>~</div>
        <ThemeInput type="date" style={{ width: '180px', height: '40px' }} value={SearchParam.end_date} onChange={(e) => setSearchParam({ ...SearchParam, end_date: e.target.value })} />
        <DarkButton width={60} height={40} onClick={() => makeURL()}>검색</DarkButton>
        <button className={styles.blueButton} onClick={() => setShow(true)} type="button">내역 추가하기</button>
      </div>
      <div className={styles.infoListDiv}>
        <div>
          <div>통계</div>
          <div style={{ width: '350px' }}>
            <SelectWindow leftWidth={80}>
              <div>
                <div>
                  <div>총 매출액</div>
                  <div>{`${data.total_income.toLocaleString('ko-KR')}원`}</div>
                </div>
              </div>
              <div>
                <div>
                  <div>총 지출액</div>
                  <div>{`${data.total_expense.toLocaleString('ko-KR')}원`}</div>
                </div>
              </div>
              <div>
                <div>
                  <div>합계</div>
                  <div>{`${data.total_sum > 0 ? '+' : ''}${data.total_sum.toLocaleString('ko-KR')}원`}</div>
                </div>
              </div>
            </SelectWindow>

          </div>
        </div>

        <div>
          <div>매출 내역</div>
          <div>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>No.</td>
                  <td>금액</td>
                  <td>항목</td>
                  <td>일자</td>
                  <td>비고</td>
                </tr>
              </thead>
              <tbody>
                {
                  data.income.map((d) => (
                    <tr>
                      <td>{d.idx}</td>
                      <td>{`${d.price.toLocaleString('ko-KR')}원`}</td>
                      <td>{d.content}</td>
                      <td>{d.created_at}</td>
                      <td>{d.note}</td>
                    </tr>
                  ))
                }
              </tbody>
            </CustomTable>
          </div>
        </div>
        <div>
          <div>지출 내역</div>
          <div>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>No.</td>
                  <td>금액</td>
                  <td>항목</td>
                  <td>일자</td>
                  <td>비고</td>
                </tr>
              </thead>
              <tbody>
                {
                  data.expense.map((d) => (
                    <tr>
                      <td>{d.idx}</td>
                      <td>{`${d.price.toLocaleString('ko-KR')}원`}</td>
                      <td>{d.content}</td>
                      <td>{d.created_at}</td>
                      <td>{d.note}</td>
                    </tr>
                  ))
                }
              </tbody>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseList;
