import React, { ReactNode } from 'react';
import styles from '../Css/LinkList.module.css';

function LinkList(props: {
  children: ReactNode[] | ReactNode
}) {
  const { children } = props;
  return (
    <div className={styles.mainDiv}>
      {children}
    </div>
  );
}

export default LinkList;
