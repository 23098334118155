/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodInfoAdd.module.css';
import getDateString from '../../Middle/getDateString';
import { useAirline, useGoodState, useTransportation } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import { GoodInfoAddI } from './GoodInfoAdd';

const days = '일월화수목금토';

export interface GoodInfoDateI {
  'schedule': [
      {
          idx: number
          departure_start_time: string
          departure_start_time_korea: number
          departure_end_time: string
          departure_end_time_korea: number
          departure_end_next_day: number
          arrival_start_time: string
          arrival_start_time_korea: number
          arrival_end_time: string
          arrival_end_time_korea: number
          arrival_end_next_day: number
          is_airline: number
          departure_transportation_idx: number
          departure_transportation_name: string | null | undefined
          arrival_transportation_idx: number
          arrival_transportation_name: string | null | undefined
          maximum: number
          minimum: number
          current: number
          state: number
      }
  ],
  'options': {
    idx: number
    option_name: string
    adult_price: number
    child_price: number | null | undefined
    infant_price: number | null | undefined
    baby_price: number | null | undefined
    maximum: number | null | undefined
  }[]
  'additionalOptions': {
    idx: number
    option_name: string
    price: number
    maximum: number | null | undefined
  }[] | null | undefined
}

function GoodInfoDate() {
  const { idx: pageIdx, date: pageDate } = useParams();
  const today = new Date();
  const { getLoginConfig } = useLogin();
  const { data: Data, mutate } = useSWR(`/api/admin/good/${pageIdx}/schedule?date=${pageDate}`, async (url: string) => {
    const res = await axios.get<{result: GoodInfoDateI}>(url, getLoginConfig());
    return {
      ...res.data.result.schedule[0],
      options: res.data.result.options,
      additional_options: res.data.result.additionalOptions,
    };
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const setData = (d: any) => {};
  const { data: airlineData } = useAirline();
  const { data: transData } = useTransportation();
  const { data: goodStateData } = useGoodState();
  const navigate = useNavigate();
  const [DateState, setDateState] = useState({
    type: 0,
    startDate: getDateString(today),
    endDate: getDateString(today),
    days: [false, false, false, false, false, false, false],
  });
  const [NewData, setNewData] = useState<{
    maximum: number
    minimum: number
    current: number
    state: number
  } | null>(null);
  useEffect(() => {
    if (Data) {
      setNewData({
        maximum: Data.maximum,
        minimum: Data.minimum,
        current: Data.current,
        state: Data.state,
      });
    }
  }, [Data]);
  if (!Data || !NewData) return <div />;
  const nowTransData = Data.is_airline === 1 ? airlineData : transData;
  const dayCheck = (idx: number) => {
    const tmpDays = [...DateState.days];
    tmpDays[idx] = !tmpDays[idx];
    setDateState({ ...DateState, days: tmpDays });
  };
  const changeDate = (idx: number, date: string) => {
    // const tmpDates = [...Data.departure_dates];
    // tmpDates[idx] = date;
    // setData({ ...Data, departure_dates: tmpDates });
  };
  const deleteDate = (idx: number) => {
    // const tmpDates = Data.departure_dates;
    // setData({
    //   ...Data,
    //   departure_dates: [...tmpDates.slice(0, idx), ...tmpDates.slice(idx + 1)],
    // });
  };
  const isDayAllCheck = () => DateState.days.reduce((p, c) => (p && c), true);
  const addOption = () => {
    // const tmpOption = [...Data.options];
    // tmpOption.push({
    //   option_name: '옵션명',
    //   adult_price: 1000,
    //   child_price: null,
    //   infant_price: null,
    //   baby_price: null,
    //   maximum: null,
    // });
    // setData({ ...Data, options: tmpOption });
  };
  const addAddOption = () => {
    // const tmpOption = Data.additional_options ? [...Data.additional_options] : [];
    // tmpOption.push({
    //   option_name: '추가옵션명',
    //   price: 1000,
    //   maximum: null,
    // });
    // setData({ ...Data, additional_options: tmpOption });
  };
  const deleteOption = (idx: number) => {
    // const tmpOption = Data.options;
    // setData({ ...Data, options: [...tmpOption.slice(0, idx), ...tmpOption.slice(idx + 1)] });
  };
  const deleteAddOption = (idx: number) => {
    // if (!Data.additional_options) return;
    // const tmpOption = Data.additional_options;
    // setData({
    //   ...Data,
    //   additional_options: [...tmpOption.slice(0, idx), ...tmpOption.slice(idx + 1)],
    // });
  };
  const changeOption = (idx: number, target: keyof GoodInfoAddI['options'][0], newData: any) => {
    // const tmpOption = [...Data.options];
    // const tmpEach = { ...tmpOption[idx] };
    // (tmpEach[target] as any) = newData;
    // tmpOption[idx] = tmpEach;
    // setData({ ...Data, options: tmpOption });
  };
  const changeAddOption = (idx: number, target: 'option_name'|'price'|'maximum', newData: any) => {
    // if (!Data.additional_options) return;
    // const tmpOption = [...Data.additional_options];
    // const tmpEach = { ...tmpOption[idx] };
    // (tmpEach[target] as any) = newData;
    // tmpOption[idx] = tmpEach;
    // setData({ ...Data, additional_options: tmpOption });
  };
  const getDateList = () => {
    // if (DateState.type === 1) return Data.departure_dates;
    // const returnLis = [];
    // const start = new Date(DateState.startDate);
    // const end = new Date(DateState.endDate);
    // const now = new Date(DateState.startDate);
    // while (start <= now && now <= end) {
    //   if (DateState.days[now.getDay()]) returnLis.push(getDateString(now));
    //   now.setDate(now.getDate() + 1);
    // }
    // return returnLis;
  };
  if (!pageIdx || !pageDate) return <div />;
  const onNext = async () => {
    try {
      const res = await axios.put<{statusCode: number}>(`/api/admin/good/${pageIdx}/schedule?date=${pageDate}`, NewData, getLoginConfig());
      const { statusCode } = res.data;
      if (statusCode !== 201) throw Error;
      alert('수정했습니다.');
      mutate();
      // navigate(-1);
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.mainDiv}>
      <InnerTitle>출발일정 등록</InnerTitle>
      <BlankMaker height={50} />
      <LeftRight leftWidth={70} haveLine>
        <div>
          <div>
            <div>일정</div>
            <div className={styles.lines}>
              <div className={`${styles.line} ${styles.schedule}`}>
                <div className={styles.mini} style={{ background: '#3386FF' }}>출발</div>
                출발시간
                <BlankMaker width={10} />
                <ThemeInput type="time" value={Data.departure_start_time} onChange={(e) => setData({ ...Data, departure_start_time: e.target.value })} style={{ height: '36px' }} />
                <select value={Data.departure_start_time_korea === 1 ? 'kor' : 'not'} onChange={(e) => setData({ ...Data, departure_start_time_korea: e.target.value === 'kor' ? 1 : 0 })}>
                  <option value="kor">한국기준</option>
                  <option value="not">현지기준</option>
                </select>
                <BlankMaker width={10} />
                →
                <BlankMaker width={10} />
                도착시간
                <BlankMaker width={10} />
                <input type="checkbox" checked={Data.departure_end_next_day === 1} onClick={() => setData({ ...Data, departure_end_next_day: Data.departure_end_next_day === 0 ? 1 : 0 })} />
                익일
                <ThemeInput type="time" value={Data.departure_end_time} onChange={(e) => setData({ ...Data, departure_end_time: e.target.value })} style={{ height: '36px' }} />
                <select value={Data.departure_end_time_korea === 1 ? 'kor' : 'not'} onChange={(e) => setData({ ...Data, departure_end_time_korea: e.target.value === 'kor' ? 1 : 0 })}>
                  <option value="kor">한국기준</option>
                  <option value="not">현지기준</option>
                </select>
              </div>
              <BlankMaker height={20} />
              <div className={`${styles.line} ${styles.schedule}`}>
                <div className={styles.mini} style={{ background: '#FF4E40' }}>도착</div>
                출발시간
                <BlankMaker width={10} />
                <ThemeInput type="time" value={Data.arrival_start_time} onChange={(e) => setData({ ...Data, arrival_start_time: e.target.value })} style={{ height: '36px' }} />
                <select value={Data.arrival_start_time_korea === 1 ? 'kor' : 'not'} onChange={(e) => setData({ ...Data, arrival_start_time_korea: e.target.value === 'kor' ? 1 : 0 })}>
                  <option value="kor">한국기준</option>
                  <option value="not">현지기준</option>
                </select>
                <BlankMaker width={10} />
                →
                <BlankMaker width={10} />
                도착시간
                <BlankMaker width={10} />
                <input type="checkbox" checked={Data.arrival_end_next_day === 1} onClick={() => setData({ ...Data, arrival_end_next_day: Data.arrival_end_next_day === 0 ? 1 : 0 })} />
                익일
                <ThemeInput type="time" value={Data.arrival_end_time} onChange={(e) => setData({ ...Data, arrival_end_time: e.target.value })} style={{ height: '36px' }} />
                <select value={Data.arrival_end_time_korea === 1 ? 'kor' : 'not'} onChange={(e) => setData({ ...Data, arrival_end_time_korea: e.target.value === 'kor' ? 1 : 0 })}>
                  <option value="kor">한국기준</option>
                  <option value="not">현지기준</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>교통편</div>
            <div className={`${styles.line} ${styles.schedule}`}>
              <select
                className={styles.isAir}
                value={Data.is_airline === 1 ? 'air' : 'etc'}
                onChange={(e) => setData({
                  ...Data, is_airline: e.target.value === 'air' ? 1 : 0, departure_transportation_idx: 0, arrival_transportation_idx: 0,
                })}
              >
                <option value="air">항공</option>
                <option value="etc">기타</option>
              </select>
              <BlankMaker width={10} />
              출발편
              <select
                className={styles.trans}
                value={Data.departure_transportation_idx}
                onChange={(e) => setData({
                  ...Data, departure_transportation_idx: parseInt(e.target.value, 10),
                })}
              >
                {
                  nowTransData?.idxList.map((d) => (
                    <option value={d}>{nowTransData[d].title}</option>
                  ))
                }
              </select>
              출발편명
              <ThemeInput style={{ width: '80px', height: '36px' }} value={Data.departure_transportation_name || ''} onChange={(e) => setData({ ...Data, departure_transportation_name: e.target.value })} />
              <BlankMaker width={10} />
              →
              <BlankMaker width={10} />
              도착편
              <select
                className={styles.trans}
                value={Data.arrival_transportation_idx}
                onChange={(e) => setData({
                  ...Data, arrival_transportation_idx: parseInt(e.target.value, 10),
                })}
              >
                {
                  nowTransData?.idxList.map((d) => (
                    <option value={d}>{nowTransData[d].title}</option>
                  ))
                }
              </select>
              도착편명
              <ThemeInput style={{ width: '80px', height: '36px' }} value={Data.arrival_transportation_name || ''} onChange={(e) => setData({ ...Data, arrival_transportation_name: e.target.value })} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>인원</div>
            <div>
              <LeftRight leftWidth={120}>
                {[
                  <div>
                    <div>
                      <div>정원</div>
                      <div style={{ marginLeft: '-50px' }}>
                        <ThemeInput type="number" value={NewData.maximum} onChange={(e) => setNewData({ ...NewData, maximum: parseInt(e.target.value, 10) })} style={{ width: '60px', height: '36px' }} />
                        <BlankMaker width={10} />
                        명
                      </div>
                    </div>
                    <div>
                      <div>최소출발인원</div>
                      <div>
                        <ThemeInput type="number" value={NewData.minimum} onChange={(e) => setNewData({ ...NewData, minimum: parseInt(e.target.value, 10) })} style={{ width: '60px', height: '36px' }} />
                        <BlankMaker width={10} />
                        명
                      </div>
                    </div>
                    <div>
                      <div>현재인원</div>
                      <div>
                        <ThemeInput type="number" value={NewData.current} onChange={(e) => setNewData({ ...NewData, current: parseInt(e.target.value, 10) })} style={{ width: '60px', height: '36px' }} />
                        <BlankMaker width={10} />
                        명
                      </div>
                    </div>
                  </div>,
                ]}
              </LeftRight>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상태</div>
            <div>
              <select
                value={NewData.state}
                onChange={(e) => setNewData({
                  ...NewData, state: parseInt(e.target.value, 10),
                })}
              >
                {
                  goodStateData?.idxList.map((d) => (
                    <option value={d}>{goodStateData[d].title}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>옵션</div>
            <div>
              <CustomTable haveVerticalLine>
                <thead>
                  <tr>
                    <td>옵션명</td>
                    <td>성인</td>
                    <td>소아</td>
                    <td>유아</td>
                    <td>영아</td>
                    <td>최대인원</td>
                    {/* <td>삭제</td> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    Data.options.map((d, i) => (
                      <tr>
                        <td><ThemeInput value={d.option_name} onChange={(e) => changeOption(i, 'option_name', e.target.value)} style={{ width: '150px', height: '36px' }} /></td>
                        <td>
                          <ThemeInput value={d.adult_price} onChange={(e) => changeOption(i, 'adult_price', parseInt(e.target.value, 10))} style={{ width: '100px', height: '36px' }} />
                        </td>
                        {
                          (['child_price', 'infant_price', 'baby_price'] as (keyof GoodInfoAddI['options'][0])[]).map((str) => (
                            <td>
                              {
                                typeof d[str] === 'number'
                                  ? (
                                    <div className={styles.inTable}>
                                      <ThemeInput value={d[str] || 0} onChange={(e) => changeOption(i, str, parseInt(e.target.value, 10))} style={{ width: '80px', height: '36px' }} />
                                      {/* <DarkButton
                                        width={36}
                                        height={36}
                                        onClick={() => changeOption(i, str, null)}
                                      >
                                        X
                                      </DarkButton> */}
                                    </div>
                                  )
                                  : (
                                    // <DarkButton
                                    //   width={100}
                                    //   height={36}
                                    //   onClick={() => changeOption(i, str, 1000)}
                                    // >
                                    //   추가
                                    // </DarkButton>
                                    <div />
                                  )
                              }
                            </td>
                          ))
                        }
                        <td>
                          {
                                typeof d.maximum === 'number'
                                  ? (
                                    <div className={styles.inTable}>
                                      <ThemeInput value={d.maximum || 0} onChange={(e) => changeOption(i, 'maximum', parseInt(e.target.value, 10))} style={{ width: '50px', height: '36px' }} />
                                      {/* <DarkButton
                                        width={36}
                                        height={36}
                                        onClick={() => changeOption(i, 'maximum', null)}
                                      >
                                        X
                                      </DarkButton> */}
                                    </div>
                                  )
                                  : (
                                    // <DarkButton
                                    //   width={100}
                                    //   height={36}
                                    //   onClick={() => changeOption(i, 'maximum', 1)}
                                    // >
                                    //   추가
                                    // </DarkButton>
                                    <div />
                                  )
                              }
                        </td>
                        {/* <td>
                          <DarkButton
                            width={100}
                            height={36}
                            onClick={() => deleteOption(i)}
                          >
                            삭제
                          </DarkButton>
                        </td> */}
                      </tr>
                    ))
                  }
                  {/* <tr>
                    <td colSpan={7}><DarkButton onClick={addOption}>옵션 추가</DarkButton></td>
                  </tr> */}
                </tbody>
              </CustomTable>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>추가옵션</div>
            <div>
              <CustomTable haveVerticalLine>
                <thead>
                  <tr>
                    <td>옵션명</td>
                    <td>가격</td>
                    <td>최대인원</td>
                    {/* <td>삭제</td> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    Data.additional_options?.map((d, i) => (
                      <tr>
                        <td><ThemeInput value={d.option_name} onChange={(e) => changeAddOption(i, 'option_name', e.target.value)} style={{ width: '150px', height: '36px' }} /></td>
                        <td><ThemeInput value={d.price} onChange={(e) => changeAddOption(i, 'price', e.target.value)} style={{ width: '150px', height: '36px' }} /></td>
                        <td>
                          {
                            typeof d.maximum === 'number'
                              ? (
                                <div className={styles.inTable}>
                                  <ThemeInput value={d.maximum || 0} onChange={(e) => changeAddOption(i, 'maximum', parseInt(e.target.value, 10))} style={{ width: '50px', height: '36px' }} />
                                  {/* <DarkButton
                                    width={36}
                                    height={36}
                                    onClick={() => changeAddOption(i, 'maximum', null)}
                                  >
                                    X
                                  </DarkButton> */}
                                </div>
                              )
                              : (
                                // <DarkButton
                                //   width={100}
                                //   height={36}
                                //   onClick={() => changeAddOption(i, 'maximum', 1)}
                                // >
                                //   추가
                                // </DarkButton>
                                <div />
                              )
                          }
                        </td>
                        {/* <td>
                          <DarkButton
                            width={100}
                            height={36}
                            onClick={() => deleteAddOption(i)}
                          >
                            삭제
                          </DarkButton>
                        </td> */}
                      </tr>
                    ))
                  }
                  {/* <tr>
                    <td colSpan={4}><DarkButton onClick={addAddOption}>옵션 추가</DarkButton></td>
                  </tr> */}
                </tbody>
              </CustomTable>
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={onNext}>수정</DarkButton>
    </div>
  );
}

export default GoodInfoDate;
