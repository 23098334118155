/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import useCategory from '../../Middle/useCategory';
import { useCountry, useCity, useAdminList } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import { goodDefaultInfo } from './GoodInfo';
import styles from '../../Css/GoodInfo.module.css';
import InnerTitle from '../../Components/InnerTitle';
import ImageInput from '../../Components/ImageInput';

function GoodRegister() {
  const { getLoginConfig } = useLogin();
  const category = useCategory();
  const navigate = useNavigate();
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const { data: adminData } = useAdminList();
  const [NowInfoData, setNowInfoData] = useState<goodDefaultInfo & {thumb_image: string}
  | undefined | null>({
    first_category: -1,
    second_category: null,
    country: -1,
    city: null,
    title: '',
    description: '',
    price: 0,
    domestic: 1,
    stay: 0,
    nights: 0,
    days: 1,
    description_perks: null,
    description_include: null,
    description_exclude: null,
    description_notice: null,
    staff: -1,
    thumb_image: 'https://alps-tour.s3.ap-northeast-2.amazonaws.com/goods/1646059905387_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5%20%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5.png',
  });
  if (!NowInfoData || category.firstCategoryIdxList.length === 0) return <div />;
  const checkData = () => {
    const dataToCheck = { ...NowInfoData };
    const error = {
      isError: false,
      reason: '',
    };
    const setError = (where: string) => {
      if (!error.isError) {
        error.isError = true;
        error.reason = `${where}을(를) 확인해주세요`;
      }
    };
    if (dataToCheck.first_category === -1) setError('1차 카테고리');
    if (dataToCheck.second_category === -1) dataToCheck.second_category = null;
    if (dataToCheck.country === -1) setError('국가');
    if (dataToCheck.city === -1) dataToCheck.city = null;
    if (Number.isNaN(dataToCheck.price)) setError('가격');
    if (dataToCheck.staff === -1) setError('담당자');
    if (Number.isNaN(dataToCheck.nights)) setError('여행기간 - 박수');
    if (Number.isNaN(dataToCheck.days)) setError('여행기간 - 일수');
    return {
      error,
      data: {
        title: dataToCheck.title,
        description: dataToCheck.description,
        cost: dataToCheck.price,
        first_category_idx: dataToCheck.first_category,
        second_category_idx: dataToCheck.second_category,
        country_idx: dataToCheck.country,
        city_idx: dataToCheck.city,
        domestic: dataToCheck.domestic,
        stay: dataToCheck.stay,
        days: dataToCheck.days,
        nights: dataToCheck.nights,
        description_perks: dataToCheck.description_perks,
        description_include: dataToCheck.description_include,
        description_exclude: dataToCheck.description_exclude,
        description_notice: dataToCheck.description_notice,
        manager_idx: dataToCheck.staff,
        thumb_image: dataToCheck.thumb_image,
      },
    };
  };
  const onNext = async () => {
    const { error, data } = checkData();
    if (error.isError) {
      alert(error.reason);
      return;
    }
    try {
      const res = await axios.post<{statusCode: number, result: {insertId: number}}>('/api/admin/good', data, getLoginConfig());
      if (res.data.statusCode === 201) {
        alert('등록했습니다.');
        navigate(`/good/list/${res.data.result.insertId}`);
      } else {
        throw Error;
      }
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.innerMain}`}>
      <InnerTitle>여행등록</InnerTitle>
      <BlankMaker height={30} />
      <LeftRight leftWidth={100}>
        <div>
          <div>
            <div>카테고리</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.first_category}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  first_category: parseInt(e.target.value, 10),
                  second_category: null,
                })}
              >
                <option value={-1}>1차 카테고리</option>
                {
                  category.firstCategoryIdxList.map((num) => (
                    <option value={`${category[num].idx}`}>{category[num].title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.second_category || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  second_category: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>2차 카테고리</option>
                {
                  NowInfoData.first_category !== -1
                  && category[NowInfoData.first_category].secondCategoryIdxList.map((num) => (
                    <option
                      value={category[NowInfoData.first_category].secondCategory[num].idx}
                    >
                      {category[NowInfoData.first_category].secondCategory[num].title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>국가</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.country}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  country: parseInt(e.target.value, 10),
                  city: null,
                })}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.city || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  city: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${NowInfoData.country}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상품명</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.title}
                onChange={(e) => setNowInfoData({ ...NowInfoData, title: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상품설명</div>
            <div>
              <ThemeInput
                style={{
                  width: '100%',
                  height: '135px',
                }}
                value={NowInfoData.description}
                onChange={(e) => setNowInfoData({ ...NowInfoData, description: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>대표 가격</div>
            <div>
              <ThemeInput
                type="number"
                style={{
                  width: '200px',
                  height: '40px',
                }}
                value={NowInfoData.price}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  price: parseInt(e.target.value, 10),
                })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>여행기간</div>
            <div>
              <div className={styles.length}>
                <div>
                  <input type="radio" checked={NowInfoData.domestic === 0} onClick={() => setNowInfoData({ ...NowInfoData, domestic: 0 })} />
                  <label>해외여행</label>
                  <input type="radio" checked={NowInfoData.domestic === 1} onClick={() => setNowInfoData({ ...NowInfoData, domestic: 1 })} />
                  <label>국내여행</label>
                </div>
                <div>
                  <input
                    type="radio"
                    checked={NowInfoData.stay === 0}
                    onClick={() => setNowInfoData({
                      ...NowInfoData, stay: 0, nights: 0, days: 1,
                    })}
                  />
                  <label>당일</label>
                  <input
                    type="radio"
                    checked={NowInfoData.stay === 1}
                    onClick={() => setNowInfoData({
                      ...NowInfoData, stay: 1, nights: 0, days: 1,
                    })}
                  />
                  <label>무박</label>
                  <input type="radio" checked={NowInfoData.stay === 2} onClick={() => setNowInfoData({ ...NowInfoData, stay: 2 })} />
                  <label>숙박</label>
                  <div>
                    <ThemeInput type="number" disabled={NowInfoData.stay !== 2} style={{ width: '50px', height: '40px', textAlign: 'center' }} value={NowInfoData.nights} onChange={(e) => setNowInfoData({ ...NowInfoData, nights: parseInt(e.target.value, 10) })} />
                    <div>박</div>
                    <ThemeInput type="number" disabled={NowInfoData.stay !== 2} style={{ width: '50px', height: '40px', textAlign: 'center' }} value={NowInfoData.days} onChange={(e) => setNowInfoData({ ...NowInfoData, days: parseInt(e.target.value, 10) })} />
                    <div>일</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>이용 특전</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_perks || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_perks: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>포함사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_include || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_include: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>불포함사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_exclude || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_exclude: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>유의사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_notice || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_notice: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>담당자</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.staff}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  staff: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>담당자 선택</option>
                {
                  adminData?.idxList.map((num) => (
                    <option value={`${adminData[num].idx}`}>{adminData[num].name}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>대표 이미지</div>
            <div className={styles.imageDiv}>
              <img src={NowInfoData.thumb_image} alt="" />
              <BlankMaker width={40} />
              <ImageInput onChange={async (e) => {
                if (!e.target.files) return;
                try {
                  const formData = new FormData();
                  formData.append('image', e.target.files[0]);
                  const res = await axios.post<{statusCode: number, result: string}>('/api/admin/good/thumb-image', formData);
                  if (res.data.statusCode !== 201) throw Error;
                  setNowInfoData({ ...NowInfoData, thumb_image: res.data.result });
                  alert('이미지를 업로드했습니다.');
                } catch {
                  alert('오류가 발생했습니다.');
                }
              }}
              >
                <DarkButton bg="#3386FF" width={140}>이미지 업로드</DarkButton>
              </ImageInput>
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={() => onNext()}>등록</DarkButton>
    </div>
  );
}

export default GoodRegister;
