import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/ConsultingList.module.css';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function ConsultingList() {
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/consulting?limit=${PAGE_SIZE}`);
  const [SearchParam, setSearchParam] = useState({
    name: '',
    date: '',
  });
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'title': string,
    'state': string,
    'user_name': string,
    'phone_number': string,
    'created_at': string,
    'manager': string,
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'consulting',
    PAGE_SIZE,
  );
  const makeURL = () => {
    const query = new URLSearchParams();
    const {
      date, name,
    } = SearchParam;
    query.append('limit', `${PAGE_SIZE}`);
    if (name.trim().length > 0) query.append('name', name.trim());
    if (date.trim().length > 0) query.append('date', date.trim());
    setURL(`/api/consulting?${query.toString()}`);
  };
  return (
    <div className={styles.consultingListDiv}>
      <InnerTitle>상담내역</InnerTitle>
      <div className={styles.optionSelectDiv}>
        <div className={styles.eachOptionDiv}>
          <div>고객명</div>
          <ThemeInput value={SearchParam.name} onChange={(e) => setSearchParam({ ...SearchParam, name: e.target.value })} style={{ width: '150px', height: '40px' }} type="text" />
        </div>
        <div className={styles.eachOptionDiv}>
          <div>상담일</div>
          <ThemeInput value={SearchParam.date} onChange={(e) => setSearchParam({ ...SearchParam, date: e.target.value })} style={{ width: '200px', height: '40px' }} type="date" />
        </div>
        <DarkButton width={80} height={40} onClick={() => setSearchParam({ name: '', date: '' })}>지우기</DarkButton>
        <DarkButton width={60} height={40} onClick={() => makeURL()}>검색</DarkButton>
      </div>
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>상태</td>
            <td>제목</td>
            <td>고객명</td>
            <td>연락처</td>
            <td>상담일</td>
            <td>담당자</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((d) => (
              <tr>
                <td>{d.idx}</td>
                <td>{d.state}</td>
                <td><Link to={`./${d.idx}`}>{d.title}</Link></td>
                <td>{d.user_name}</td>
                <td>{d.phone_number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</td>
                <td>{d.created_at}</td>
                <td>{d.manager}</td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default ConsultingList;
