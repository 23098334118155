/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../../Css/NewUserInfoEdit.module.css';
import useNewDropdown, { useNewDropdownReturnI } from '../../Middle/useNewDropdown';
import DarkButton from '../../Components/DarkButton';
import CustomTable from '../../Components/CustomTable';
import useLogin from '../../Middle/useLogin';
import deleteBlank, { deleteBlankType } from '../../Middle/deleteBlank';
import AddTravel, { TravelInfo } from '../../Components/AddTravel';
import ModalWrapper from '../../Components/ModalWrapper';
import InnerTitle from '../../Components/InnerTitle';

const deleteBlankTypeThis: deleteBlankType = {
  num: ['gender', 'region_idx', 'theme_idx', 'channel_idx', 'group_idx'],
  str: ['name', 'mobile', 'birth', 'email', 'address', 'memo'],
};

export interface NewUserInfoI {
  name: string,
  mobile?: string,
  gender?: number,
  birth?: string,
  region_idx?: number,
  group_idx?: number,
  theme_idx?: number,
  channel_idx?: number,
  email?: string,
  address?: string,
  memo?: string,
  travel_arr?: {travel_date: string, travel_name: string}[]
}

interface NewUserAddVIEWProps {
  info: NewUserInfoI
  onChange: React.FormEventHandler<HTMLFormElement>
  onEdit: () => void
  onTravelAdd: () => void
  dropdownData: useNewDropdownReturnI
  isPopup: boolean
  onTravelDelete: (deleteIdx: number) => void
}

function NewUserAddVIEW(props: NewUserAddVIEWProps) {
  const {
    info, onChange, onEdit, onTravelAdd, dropdownData, isPopup, onTravelDelete,
  } = props;

  return (
    <div className={styles.NewUserInfoEditDiv}>
      <InnerTitle>회원추가</InnerTitle>
      <div className={styles.InfoWrapper}>
        <form onSubmit={(e) => e.preventDefault()} onChange={onChange}>
          <div>이름 *</div>
          <input type="text" value={info.name} name="name" />
          <div>전화번호</div>
          <input type="tel" value={info?.mobile || ''} name="mobile" />
          <div>성별</div>
          <select name="gender" value={info?.gender || 'ALL'}>
            <option value="ALL">성별</option>
            <option value="1">남</option>
            <option value="0">여</option>
          </select>
          <div>생년월일</div>
          <input type="date" value={info?.birth || ''} name="birth" />
          <div>지역</div>
          <select name="region_idx" value={info?.region_idx || 'ALL'}>
            <option value="ALL">지역</option>
            {
              dropdownData.region.map((t) => (
                <option value={t.idx} key={t.region}>{t.region}</option>
              ))
            }
          </select>
          <div>여행테마</div>
          <select name="theme_idx" value={info?.theme_idx || 'ALL'}>
            <option value="ALL">여행테마</option>
            {
              dropdownData.theme.map((t) => (
                <option value={t.idx} key={t.theme}>{t.theme}</option>
              ))
            }
          </select>
          <div>유입경로</div>
          <select name="channel_idx" value={info?.channel_idx || 'ALL'}>
            <option value="ALL">유입경로</option>
            {
              dropdownData.channels.map((t) => (
                <option value={t.idx} key={t.channels}>{t.channels}</option>
              ))
            }
          </select>
          <div>이메일</div>
          <input type="email" value={info?.email || ''} name="email" />
          <div>그룹</div>
          <select name="group_idx" value={info?.group_idx || 'ALL'} className={styles.wide}>
            <option value="ALL">그룹</option>
            {
              dropdownData.group.map((t) => (
                <option value={t.idx} key={t.title}>{t.title}</option>
              ))
            }
          </select>
          <div>주소</div>
          <input type="text" value={info?.address || ''} name="address" className={styles.wide} />
          <div>메모</div>
          <textarea rows={4} value={info?.memo || ''} name="memo" className={styles.wide} />
        </form>
      </div>
      {
        !isPopup && (
          <div className={styles.TravelWrapper}>
            <CustomTable haveVerticalLine>
              <thead>
                <tr>
                  <td>여행일</td>
                  <td>상품명</td>
                  <td>삭제</td>
                </tr>
              </thead>
              <tbody>
                {
                  info?.travel_arr && info.travel_arr.map((t, i) => (
                    <tr>
                      <td>{t.travel_date}</td>
                      <td>{t.travel_name}</td>
                      <td>
                        <DarkButton
                          onClick={
                      () => onTravelDelete(i)
                    }
                          width={60}
                        >
                          삭제
                        </DarkButton>
                      </td>
                    </tr>
                  ))
                }
                {
                  (!info?.travel_arr || info.travel_arr.length === 0) && (
                    <tr>
                      <td colSpan={3}>여행기록 없음</td>
                    </tr>
                  )
                }
              </tbody>
            </CustomTable>
            <DarkButton onClick={onTravelAdd} width={140}>여행기록 추가</DarkButton>
          </div>
        )
      }
      <div className={styles.buttonDiv}>
        <DarkButton bg="#3386FF" onClick={onEdit}>회원추가</DarkButton>
      </div>
    </div>
  );
}

export function NewUserAdd({ idx = 1, onMutate = () => {}, onAdd }:
{idx?: number, onMutate?: () => void, onAdd?: (name: string, phone: string, id: number) => void}) {
  const navigate = useNavigate();
  const { getLoginConfig } = useLogin();
  const dropdownData = useNewDropdown();
  const [Info, setInfo] = useState<NewUserInfoI>({ name: '', travel_arr: [] });
  const [ShowModal, setShowModal] = useState<
    null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number}
  >(null);

  const onChange: NewUserAddVIEWProps['onChange'] = (e) => {
    const { target } = e;
    if (!target) return;
    setInfo((t) => ({
      ...t,
      [(target as HTMLInputElement).name]: (target as HTMLInputElement).value,
    }));
  };

  const onEdit = async () => {
    const deleted = {
      ...deleteBlank(deleteBlankTypeThis, Info),
      travel_arr: Info.travel_arr,
    } as NewUserInfoI;
    if (!deleted?.name) { alert('이름은 공란으로 둘 수 없습니다.'); return; }
    const data = await axios.post<{statusCode: number, result: { insertId: number }}>('/api/admin/offuser', deleted, getLoginConfig());
    const code = data.data.statusCode;
    if (code !== 201) {
      alert('오류가 발생했습니다.');
      return;
    }
    alert('회원추가했습니다.');
    if (onAdd) {
      onAdd(deleted.name, deleted?.mobile || '', data.data.result.insertId);
    } else {
      navigate('/user/list');
    }
  };

  const AddTravelHandler = async (
    show: null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number},
    info: TravelInfo,
  ) => {
    setShowModal(null);
    setInfo((t) => ({ ...t, travel_arr: [...(t?.travel_arr || []), info] }));
  };

  const onTravelDelete = (deleteIdx: number) => {
    setInfo((t) => ({ ...t, travel_arr: t.travel_arr?.filter?.((_, i) => i !== deleteIdx) }));
  };

  const subProps: NewUserAddVIEWProps = {
    info: Info,
    onChange,
    onEdit,
    onTravelAdd: () => setShowModal({ isEdit: false }),
    dropdownData,
    isPopup: !!onAdd,
    onTravelDelete,
  };
  return (
    <>
      <NewUserAddVIEW {...subProps} />
      <ModalWrapper Show={ShowModal !== null} onShowOff={() => setShowModal(null)}>
        <AddTravel Show={ShowModal} handler={AddTravelHandler} />
      </ModalWrapper>
    </>
  );
}

export default NewUserAdd;
