function getTwoDigit(num: number) {
  if (num >= 10) return `${num}`;
  return `0${num}`;
}

export function getDateString(date: Date) {
  return `${date.getFullYear()}-${getTwoDigit(date.getMonth() + 1)}-${getTwoDigit(date.getDate())}`;
}

export default getDateString;
