/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import InnerTitle from '../../Components/InnerTitle';
import LoadMoreButton from '../../Components/LoadMoreButton';
import SelectWindow from '../../Components/SelectWindow';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodList.module.css';
import useCategory from '../../Middle/useCategory';
import { useCity, useCountry } from '../../Middle/useETC';
import useInfiniteLoad from '../../Middle/useInfiniteLoad';
import useLogin from '../../Middle/useLogin';

const PAGE_SIZE = 10;

function GoodList() {
  const category = useCategory();
  const { getLoginConfig } = useLogin();
  const [URL, setURL] = useState(`/api/admin/good?limit=${PAGE_SIZE}`);
  const [SearchParam, setSearchParam] = useState({
    keyword: '',
    first_category_idx: -1,
    second_category_idx: -1,
    country_idx: -1,
    city_idx: -1,
  });
  const [assd, setAssd] = useState('<p>Hello, World</p>');
  const {
    issues, FullSize, isLoadingMore, isReachingEnd, setSize, mutate,
  } = useInfiniteLoad<{
    'idx': number,
    'title': string,
    'thumb_image': string,
    'views': number,
    'created_at': string,
    'in_use': number
  }>(
    (index) => `${URL}&page=${index + 1}`,
    'good',
    PAGE_SIZE,
  );
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const makeURL = () => {
    const query = new URLSearchParams();
    const {
      keyword, first_category_idx, second_category_idx, country_idx, city_idx,
    } = SearchParam;
    query.append('limit', `${PAGE_SIZE}`);
    if (keyword.trim().length > 0) query.append('keyword', keyword.trim());
    if (first_category_idx !== -1) query.append('first_category_idx', `${first_category_idx}`);
    if (second_category_idx !== -1) query.append('second_category_idx', `${second_category_idx}`);
    if (country_idx !== -1) query.append('country_idx', `${country_idx}`);
    if (city_idx !== -1) query.append('city_idx', `${city_idx}`);
    setURL(`/api/admin/good?${query.toString()}`);
  };
  const deleteGood = async (idx: number, title: string) => {
    if (confirm(`${title} 상품을 삭제하시겠습니까?`)) {
      try {
        const res = await axios.delete(`/api/admin/good/${idx}`, getLoginConfig());
        alert('삭제했습니다.');
        mutate();
      } catch {
        alert('오류가 발생했습니다.');
      }
    }
  };
  const changeState = async (idx: number, title: string, now: number) => {
    if (confirm(`${title} 상품을 사용 여부를 [${now === 0 ? '사용' : '사용 안함'}]으로 변경하시겠습니까?`)) {
      try {
        const res = await axios.put(`/api/admin/good/${idx}/state`, {}, getLoginConfig());
        alert('변경했습니다.');
        mutate();
      } catch {
        alert('오류가 발생했습니다.');
      }
    }
  };
  return (
    <div className={styles.goodListDiv}>
      <InnerTitle>여행관리</InnerTitle>
      <BlankMaker height={24} />
      <SelectWindow leftWidth={70}>
        <div>
          <div>
            <div>상품명</div>
            <div>
              <ThemeInput
                type="text"
                value={SearchParam.keyword}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, keyword: e.target.value,
                }))}
                style={{ width: '200px', height: '36px' }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>카테고리</div>
            <div>
              <select
                value={SearchParam.first_category_idx}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, first_category_idx: parseInt(e.target.value, 10), second_category_idx: -1,
                }))}
              >
                <option value={-1}>1차 카테고리</option>
                {
                  category.firstCategoryIdxList.map((num) => (
                    <option value={`${category[num].idx}`}>{category[num].title}</option>
                  ))
                }
              </select>
              <select
                value={SearchParam.second_category_idx}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, second_category_idx: parseInt(e.target.value, 10),
                }))}
              >
                <option value={-1}>2차 카테고리</option>
                {
                  SearchParam.first_category_idx !== -1
                  && category[SearchParam.first_category_idx].secondCategoryIdxList.map((num) => (
                    <option
                      value={category[SearchParam.first_category_idx].secondCategory[num].idx}
                    >
                      {category[SearchParam.first_category_idx].secondCategory[num].title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>국가</div>
            <div>
              <select
                value={SearchParam.country_idx}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, country_idx: parseInt(e.target.value, 10), city_idx: -1,
                }))}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                value={SearchParam.city_idx}
                onChange={(e) => setSearchParam((v) => ({
                  ...v, city_idx: parseInt(e.target.value, 10),
                }))}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${SearchParam.country_idx}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
      </SelectWindow>
      <BlankMaker height={30} />
      <DarkButton onClick={() => makeURL()}>검색</DarkButton>
      <BlankMaker height={100} />
      <CustomTable haveVerticalLine>
        <thead>
          <tr>
            <td>No.</td>
            <td>이미지</td>
            <td>상품명</td>
            <td>조회수</td>
            <td>등록일</td>
            <td>사용</td>
            <td>삭제</td>
          </tr>
        </thead>
        <tbody>
          {
            issues.map((lis) => (
              <tr>
                <td>{lis.idx}</td>
                <td><DivInTable><img src={`${lis.thumb_image}`} alt={`${lis.title}`} /></DivInTable></td>
                <td><Link to={`./${lis.idx}/info`}>{lis.title}</Link></td>
                <td>{lis.views}</td>
                <td>{lis.created_at}</td>
                <td onClick={() => changeState(lis.idx, lis.title, lis.in_use)}>{lis.in_use === 1 ? '✅' : '🚫'}</td>
                <td><DivInTable><button type="button" onClick={() => deleteGood(lis.idx, lis.title)} className={styles.deleteButton}>삭제</button></DivInTable></td>
              </tr>
            ))
          }
        </tbody>
      </CustomTable>
      <LoadMoreButton {...{
        isLoadingMore, isReachingEnd, FullSize, length: issues.length, setSize,
      }}
      />
    </div>
  );
}

export default GoodList;
