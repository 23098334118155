import React from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// @ts-ignore
import '../Css/CustomEditor.css';

function CustomEditor(props: {
  data: string,
  onChange: (event: any, editor: any) => void,
}) {
  const { data, onChange } = props;
  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={{
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: 'https://api.alpstour.co.kr/api/board/upload/image',
        },
      }}
      onChange={onChange}
    />
  );
}

export default CustomEditor;
