/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomTable from '../../Components/CustomTable';
import DarkButton from '../../Components/DarkButton';
import DivInTable from '../../Components/DivInTable';
import InnerTitle from '../../Components/InnerTitle';
import styles from '../../Css/GoodInfo.module.css';
import { useCountry } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';

function GoodInfoHotel(props: { idx: number }) {
  const { idx } = props;
  const [Country, setCountry] = useState(-1);
  const [Hotel, setHotel] = useState(-1);
  const { getLoginConfig } = useLogin();
  const { data, mutate } = useSWR(`/api/admin/good/${idx}/hotel`, async (url) => {
    const res = await axios.get<{result: {
      idx: number, hotel_idx: number, title: string, region: string
    }[]}>(url, getLoginConfig());
    return res.data.result;
  });
  const { data: countryData } = useCountry();
  const { data: hotelData } = useSWR(Country !== -1 && `/api/hotel?page=1&limit=999999&country=${Country}`, async (url) => {
    const res = axios.get<{result: {hotel: {
      idx: number, thumb_image: string, title: string, in_use: number,
    }[]}}>(url, getLoginConfig());
    return (await res).data.result.hotel;
  });
  return (
    <div className={`${styles.innerMain}`}>
      <div className={styles.leftRightInner}>
        <div>
          <CustomTable haveVerticalLine>
            <thead>
              <tr>
                <td>지역</td>
                <td>호텔명</td>
                <td>삭제</td>
              </tr>
            </thead>
            <tbody>
              {
                data?.map((d) => (
                  <tr>
                    <td>{d.region}</td>
                    <td>{d.title}</td>
                    <td>
                      <DivInTable>
                        <DarkButton
                          width={50}
                          onClick={async () => {
                            if (!confirm('삭제하시겠습니까?')) return;
                            try {
                              const res = await axios.delete<{statusCode: number}>(`/api/admin/good/${idx}/hotel/${d.hotel_idx}`, getLoginConfig());
                              if (res.data.statusCode !== 201) throw Error;
                              alert('삭제했습니다.');
                              mutate();
                            } catch {
                              alert('오류가 발생했습니다.');
                            }
                          }}
                        >
                          삭제
                        </DarkButton>
                      </DivInTable>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </CustomTable>
        </div>
        <div className={styles.hotelListDiv}>
          <InnerTitle>호텔 선택</InnerTitle>
          <BlankMaker height={20} />
          <select
            className={styles.defaultSelect}
            value={Country}
            onChange={(e) => {
              setCountry(parseInt(e.target.value, 10));
              setHotel(-1);
            }}
          >
            <option value={-1}>국가</option>
            {
              countryData?.map((d) => (
                <option value={d.idx}>{d.title}</option>
              ))
            }
          </select>
          <select
            className={styles.defaultSelect}
            value={Hotel}
            onChange={(e) => setHotel(parseInt(e.target.value, 10))}
          >
            <option value={-1}>호텔</option>
            {
              hotelData?.map((d) => d.in_use === 1 && (
                <option value={d.idx}>{d.title}</option>
              ))
            }
          </select>
          <BlankMaker height={20} />
          <DarkButton
            bg="#3386FF"
            onClick={async () => {
              if (Hotel === -1 || isNaN(Hotel)) {
                alert('호텔을 선택해주세요');
                return;
              }
              if (data?.map((d) => d.hotel_idx).indexOf(Hotel) !== -1) {
                alert('이미 추가되어 있는 호텔입니다.');
                return;
              }
              if (!confirm('추가하시겠습니까?')) return;
              try {
                const res = await axios.post<{statusCode: number}>(`/api/admin/good/${idx}/hotel/${Hotel}`, {}, getLoginConfig());
                if (res.data.statusCode !== 201) throw Error;
                alert('추가했습니다.');
                mutate();
              } catch {
                alert('오류가 발생했습니다.');
              }
            }}
          >
            추가하기
          </DarkButton>
        </div>
      </div>
    </div>
  );
}

export default GoodInfoHotel;
