import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import LeftRight from '../../Components/LeftRight';
import ThemeInput from '../../Components/ThemeInput';
import useCategory from '../../Middle/useCategory';
import { useCountry, useCity, useAdminList } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import { goodDefaultInfo } from './GoodInfo';
import styles from '../../Css/GoodInfo.module.css';

function GoodInfoDefault(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const { data: infoRawData, mutate } = useSWR(`/api/admin/good/${idx}/info`, async (url) => {
    const res = await axios.get<{result: goodDefaultInfo}>(url, getLoginConfig());
    return res.data.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const category = useCategory();
  const { data: countryData } = useCountry();
  const { data: cityData } = useCity();
  const { data: adminData } = useAdminList();
  const [NowInfoData, setNowInfoData] = useState<goodDefaultInfo | undefined | null>(null);
  useEffect(() => {
    setNowInfoData(infoRawData);
  }, [infoRawData]);
  if (!NowInfoData || category.firstCategoryIdxList.length === 0) return <div />;
  const checkData = () => {
    const dataToCheck = { ...NowInfoData };
    const error = {
      isError: false,
      reason: '',
    };
    const setError = (where: string) => {
      if (!error.isError) {
        error.isError = true;
        error.reason = `${where}을(를) 확인해주세요`;
      }
    };
    if (dataToCheck.first_category === -1) setError('1차 카테고리');
    if (dataToCheck.second_category === -1) dataToCheck.second_category = null;
    if (dataToCheck.country === -1) setError('국가');
    if (dataToCheck.city === -1) dataToCheck.city = null;
    if (Number.isNaN(dataToCheck.price)) setError('가격');
    if (dataToCheck.staff === -1) setError('담당자');
    return {
      error,
      data: {
        first_category_idx: dataToCheck.first_category,
        second_category_idx: dataToCheck.second_category,
        country_idx: dataToCheck.country,
        city_idx: dataToCheck.city,
        title: dataToCheck.title,
        description: dataToCheck.description,
        cost: dataToCheck.price,
        description_perks: dataToCheck.description_perks,
        description_include: dataToCheck.description_include,
        description_exclude: dataToCheck.description_exclude,
        description_notice: dataToCheck.description_notice,
        manager_idx: dataToCheck.staff,
      },
    };
  };
  const onNext = async () => {
    const { error, data } = checkData();
    if (error.isError) {
      alert(error.reason);
      return;
    }
    try {
      const res = await axios.put<{statusCode: number}>(`/api/admin/good/${idx}/info`, data, getLoginConfig());
      if (res.data.statusCode === 201) {
        alert('수정했습니다.');
        mutate();
      } else {
        throw Error;
      }
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={`${styles.innerMain}`}>
      <LeftRight leftWidth={90}>
        <div>
          <div>
            <div>카테고리</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.first_category}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  first_category: parseInt(e.target.value, 10),
                  second_category: null,
                })}
              >
                <option value={-1}>1차 카테고리</option>
                {
                  category.firstCategoryIdxList.map((num) => (
                    <option value={`${category[num].idx}`}>{category[num].title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.second_category || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  second_category: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>2차 카테고리</option>
                {
                  NowInfoData.first_category !== -1
                  && category[NowInfoData.first_category].secondCategoryIdxList.map((num) => (
                    <option
                      value={category[NowInfoData.first_category].secondCategory[num].idx}
                    >
                      {category[NowInfoData.first_category].secondCategory[num].title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>국가</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.country}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  country: parseInt(e.target.value, 10),
                  city: null,
                })}
              >
                <option value={-1}>국가</option>
                {
                  countryData?.map((country) => (
                    <option value={`${country.idx}`}>{country.title}</option>
                  ))
                }
              </select>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.city || '-1'}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  city: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>도시</option>
                {
                  cityData?.map((city) => `${NowInfoData.country}` === `${city.country}` && (
                    <option
                      value={city.idx}
                    >
                      {city.title}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상품명</div>
            <div>
              <ThemeInput
                style={{
                  width: '500px',
                  height: '40px',
                }}
                value={NowInfoData.title}
                onChange={(e) => setNowInfoData({ ...NowInfoData, title: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상품설명</div>
            <div>
              <ThemeInput
                style={{
                  width: '100%',
                  height: '135px',
                }}
                value={NowInfoData.description}
                onChange={(e) => setNowInfoData({ ...NowInfoData, description: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>대표 가격</div>
            <div>
              <ThemeInput
                type="number"
                style={{
                  width: '200px',
                  height: '40px',
                }}
                value={NowInfoData.price}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  price: parseInt(e.target.value, 10),
                })}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>이용 특전</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_perks || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_perks: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>포함사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_include || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_include: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>불포함사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_exclude || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_exclude: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>유의사항</div>
            <div>
              <CustomEditor
                data={NowInfoData.description_notice || ''}
                onChange={(event, editor) => {
                  setNowInfoData({ ...NowInfoData, description_notice: editor.getData() });
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>담당자</div>
            <div>
              <select
                className={styles.defaultSelect}
                value={NowInfoData.staff}
                onChange={(e) => setNowInfoData({
                  ...NowInfoData,
                  staff: parseInt(e.target.value, 10),
                })}
              >
                <option value={-1}>담당자 선택</option>
                {
                  adminData?.idxList.map((num) => (
                    <option value={`${adminData[num].idx}`}>{adminData[num].name}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
      </LeftRight>
      <BlankMaker height={50} />
      <DarkButton onClick={() => onNext()}>수정</DarkButton>
    </div>
  );
}

export default GoodInfoDefault;
