import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Menu from '../Components/Menu';
import styles from '../Css/Layout.module.css';
import pageMap from '../Meta/PageMap';
import Main from './Main';
import GoodInfo from './Good/GoodInfo';
import HotelInfo from './Hotel/HotelInfo';
import SightInfo from './Sight/SightInfo';
import GoodInfoAdd from './Good/GoodInfoAdd';
import GoodInfoDate from './Good/GoodInfoDate';
import ConsultingInfo from './Reservation/ConsultingInfo';
import ReservationInfo from './Reservation/ReservationInfo';
import UserInfo from './User/UserInfo';
import NoticeLinker from '../Pages/Board/Notice/Linker';
import FAQLinker from '../Pages/Board/FAQ/Linker';
import ReviewLinker from '../Pages/Board/Review/Linker';
import QNALinker from '../Pages/Board/QNA/Linker';
import EmployeeBoardContent from './Employee/EmployeeBoardContent';
import EmployeeBoardEdit from './Employee/EmployeeBoardEdit';
import EmployeeBoardNew from './Employee/EmployeeBoardNew';
import EmployeeScheduleInfo from './Employee/EmployeeScheduleInfo';
import Logout from './Logout';
import Sidebar from '../Components/Sidebar';

export function Layout() {
  return (
    <div className={styles.topDiv}>
      <div className={styles.sidebarDiv}>
        <div className={styles.sidebarInner}>
          <Sidebar />
        </div>
      </div>
      <div className={styles.contentDiv}>
        <Routes>
          <Route path="/" element={<Main />} />
          {
                  pageMap.map((cate) => (
                    cate.bigSubList.map((bigSub) => (
                      bigSub.subList.map((sub) => (
                        <Route path={`/${cate.link}/${sub.link}`} element={<sub.Component />} />
                      ))
                    ))
                  )).flat()
                }
          <Route path="/good/list/:idx" element={<GoodInfo />} />
          <Route path="/good/list/:idx/:page" element={<GoodInfo />} />
          <Route path="/hotel/list/:idx" element={<HotelInfo />} />
          <Route path="/hotel/list/:idx/:page" element={<HotelInfo />} />
          <Route path="/sight/list/:idx" element={<SightInfo />} />
          <Route path="/sight/list/:idx/:page" element={<SightInfo />} />
          <Route path="/good/list/:idx/schedule/add" element={<GoodInfoAdd />} />
          <Route path="/good/list/:idx/schedule/:date" element={<GoodInfoDate />} />
          <Route path="/reservation/consulting/list/:idx" element={<ConsultingInfo />} />
          <Route path="/reservation/list/:idx" element={<ReservationInfo />} />
          <Route path="/webuser/list/:idx" element={<UserInfo />} />
          {
                  ['notice', 'faq', 'review'].map((t) => {
                    let Target = NoticeLinker;
                    if (t === 'notice') Target = NoticeLinker;
                    if (t === 'faq') Target = FAQLinker;
                    if (t === 'review') Target = ReviewLinker;
                    return (
                      <>
                        <Route path={`/board/${t}/:idx`} element={<Target type="content" />} />
                        <Route path={`/board/${t}/:idx/edit`} element={<Target type="edit" />} />
                        <Route path={`/board/${t}/new`} element={<Target type="new" />} />
                      </>
                    );
                  })
                }
          <Route path="/board/qna/:idx" element={<QNALinker type="content" />} />
          <Route path="/board/qna/:idx/edit" element={<QNALinker type="edit" />} />
          <Route path="/board/qna/:idx/answer" element={<QNALinker type="new" />} />
          <Route path="/employee/board/:idx" element={<EmployeeBoardContent />} />
          <Route path="/employee/board/:idx/edit" element={<EmployeeBoardEdit />} />
          <Route path="/employee/board/new" element={<EmployeeBoardNew />} />
          <Route path="/employee/schedule/:date" element={<EmployeeScheduleInfo />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
