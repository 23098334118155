/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../Css/Menu.module.css';
import pageMap from '../Meta/PageMap';

function Menu() {
  const location = useLocation();
  const nowUse = pageMap.map((cate) => (location.pathname.startsWith(`/${cate.link}`) ? [cate] : [])).flat()[0] || {
    name: '메인',
    link: '',
    bigSubList: [
      {
        name: '메인',
        subList: [{
          name: '알림목록',
          link: '',
        }],
      },
    ],
  };
  return (
    <div className={`${styles.menuDiv}`}>
      {
        nowUse.bigSubList.map((bigSub) => (
          <div className={styles.superLisDiv}>
            <div className={styles.superTitleDiv}><Link to="#">{bigSub.name}</Link></div>
            {
              bigSub.subList.map((sub, subIdx) => (
                <div className={`${styles.subTitleDiv} ${(location.pathname.startsWith(`/${nowUse.link}/${sub.link}`) || location.pathname === '/') && styles.selected}`}><Link to={`/${nowUse.link}/${sub.link}`}>{sub.name}</Link></div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
}

export default Menu;
