/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import CustomEditor from '../../Components/CustomEditor';
import DarkButton from '../../Components/DarkButton';
import ImageInput from '../../Components/ImageInput';
import InnerTitle from '../../Components/InnerTitle';
import LeftRight from '../../Components/LeftRight';
import LinkList from '../../Components/LinkList';
import ThemeInput from '../../Components/ThemeInput';
import styles from '../../Css/GoodInfo.module.css';
import useCategory from '../../Middle/useCategory';
import { useAdminList, useCity, useCountry } from '../../Middle/useETC';
import useLogin from '../../Middle/useLogin';
import SightInfoDefault from './SightInfoDefault';
import SightInfoImage from './SightInfoImage';

type screenType = 'info'|'image';

const stateToComponent = {
  info: SightInfoDefault,
  image: SightInfoImage,
};

function SightInfo() {
  const { idx, page } = useParams();
  const Screen = page || 'info';
  const NowComponent = stateToComponent[Screen as screenType];
  const navigate = useNavigate();
  const setScreen = (a: string) => navigate(`/sight/list/${idx}/${a}`);
  if (!idx) return <div />;
  return (
    <div className={styles.goodInfoDiv}>
      <InnerTitle>관광지관리</InnerTitle>
      <BlankMaker height={30} />
      <LinkList>
        <button
          type="button"
          onClick={() => { if (Screen !== 'info') setScreen('info'); }}
          className={`${Screen === 'info' && styles.selected}`}
        >
          기본정보
        </button>
        <button
          type="button"
          onClick={() => { if (Screen !== 'image') setScreen('image'); }}
          className={`${Screen === 'image' && styles.selected}`}
        >
          이미지
        </button>
      </LinkList>
      <BlankMaker height={30} />
      <NowComponent idx={parseInt(idx, 10)} />
    </div>
  );
}

export default SightInfo;
