/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import styles from '../Css/NewUserInfoEdit.module.css';
import DarkButton from './DarkButton';
import CustomTable from './CustomTable';
import useLogin from '../Middle/useLogin';
import deleteBlank, { deleteBlankType } from '../Middle/deleteBlank';
import useNewDropdown, { useNewDropdownReturnI } from '../Middle/useNewDropdown';
import ModalWrapper from './ModalWrapper';
import AddTravel, { TravelInfo } from './AddTravel';

const deleteBlankTypeThis: deleteBlankType = {
  num: ['gender', 'region_idx', 'theme_idx', 'channel_idx', 'group_idx'],
  str: ['name', 'mobile', 'birth', 'email', 'address', 'memo'],
};

export interface NewUserInfoI {
  idx: number,
  name: string,
  mobile?: string,
  gender?: number,
  birth?: string,
  region_idx?: number,
  group_idx?: number,
  theme_idx?: number,
  channel_idx?: number,
  email?: string,
  address?: string,
  travel_count: number,
  memo?: string,
  travel_arr?: { date: string, name: string, idx: number }[]
}

const dummyData: NewUserInfoI = {
  idx: 2,
  name: '김이박',
  mobile: '01056785678',
  gender: 0,
  birth: '1980-05-27',
  region_idx: 1,
  theme_idx: 2,
  channel_idx: 2,
  email: 'mail@mail.com',
  address: '서울시 중구',
  travel_count: 4,
  memo: 'memo',
  travel_arr: [
    {
      date: '2017-12-01',
      name: '하와이(자사)',
      idx: 1,
    },
    {
      date: '2019-05-01',
      name: '사이판 골프(자사)',
      idx: 1,
    },
    {
      date: '2021-11-02',
      name: '춘천/남이섬(자사)',
      idx: 1,
    },
  ],
};

interface NewUserInfoEditVIEWProps {
  info: NewUserInfoI
  onChange: React.FormEventHandler<HTMLFormElement>
  onEdit: () => void
  onTravelAdd: () => void
  dropdownData: useNewDropdownReturnI
  onDeleteTravel: (deleteIdx: number) => void
  onTravelEdit: (travelIdx: number, info: TravelInfo) => void
}

function NewUserInfoEditVIEW(props: NewUserInfoEditVIEWProps) {
  const {
    info, onChange, onEdit, onTravelAdd, dropdownData, onDeleteTravel, onTravelEdit,
  } = props;

  return (
    <div className={styles.NewUserInfoEditDiv}>
      <div className={styles.InfoWrapper}>
        <form onSubmit={(e) => e.preventDefault()} onChange={onChange}>
          <div>이름</div>
          <input type="text" value={info.name} name="name" />
          <div>전화번호</div>
          <input type="tel" value={info?.mobile || ''} name="mobile" />
          <div>성별</div>
          <select name="gender" value={info?.gender || 'ALL'}>
            <option value="ALL">성별</option>
            <option value="1">남</option>
            <option value="0">여</option>
          </select>
          <div>생년월일</div>
          <input type="date" value={info?.birth || ''} name="birth" />
          <div>지역</div>
          <select name="region_idx" value={info?.region_idx || 'ALL'}>
            <option value="ALL">지역</option>
            {
              dropdownData.region.map((t) => (
                <option value={t.idx} key={t.region}>{t.region}</option>
              ))
            }
          </select>
          <div>여행테마</div>
          <select name="theme_idx" value={info?.theme_idx || 'ALL'}>
            <option value="ALL">여행테마</option>
            {
              dropdownData.theme.map((t) => (
                <option value={t.idx} key={t.theme}>{t.theme}</option>
              ))
            }
          </select>
          <div>유입경로</div>
          <select name="channel_idx" value={info?.channel_idx || 'ALL'}>
            <option value="ALL">유입경로</option>
            {
              dropdownData.channels.map((t) => (
                <option value={t.idx} key={t.channels}>{t.channels}</option>
              ))
            }
          </select>
          <div>이메일</div>
          <input type="email" value={info?.email || ''} name="email" />
          <div>그룹</div>
          <select name="group_idx" value={info?.group_idx || 'ALL'} className={styles.wide}>
            <option value="ALL">그룹</option>
            {
              dropdownData.group.map((t) => (
                <option value={t.idx} key={t.title}>{t.title}</option>
              ))
            }
          </select>
          <div>주소</div>
          <input type="text" value={info?.address || ''} name="address" className={styles.wide} />
          <div>메모</div>
          <textarea rows={4} value={info?.memo || ''} name="memo" className={styles.wide} />
        </form>
        <DarkButton onClick={onEdit}>수정</DarkButton>
      </div>
      <div className={styles.TravelWrapper}>
        <CustomTable haveVerticalLine>
          <thead>
            <tr>
              <td>여행일</td>
              <td>상품명</td>
              <td>수정</td>
              <td>삭제</td>
            </tr>
          </thead>
          <tbody>
            {
              info?.travel_arr && info.travel_arr.map((t, i) => (
                <tr>
                  <td>{t.date}</td>
                  <td>{t.name}</td>
                  <td>
                    <DarkButton
                      onClick={() => onTravelEdit(t.idx, {
                        travel_date: t.date, travel_name: t.name,
                      })}
                      width={60}
                    >
                      수정
                    </DarkButton>

                  </td>
                  <td>
                    <DarkButton
                      onClick={
                    () => onDeleteTravel(t.idx)
                  }
                      width={60}
                    >
                      삭제
                    </DarkButton>

                  </td>
                </tr>
              ))
            }
            {
              (!info?.travel_arr || info.travel_arr.length === 0) && (
                <tr>
                  <td colSpan={4}>여행기록이 없습니다.</td>
                </tr>
              )
            }
          </tbody>
        </CustomTable>
        <DarkButton onClick={onTravelAdd} width={140}>여행기록 추가</DarkButton>
      </div>
    </div>
  );
}

export function NewUserInfoEdit({ idx, onMutate = () => {} }:
{idx: number, onMutate?: () => void}) {
  const { getLoginConfig } = useLogin();
  const dropdownData = useNewDropdown();
  const { data: userData, mutate } = useSWR(`/api/admin/offuser/${idx}`, async (url) => {
    const d = await axios.get<{result: NewUserInfoI}>(url, getLoginConfig());
    return d?.data?.result;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [Info, setInfo] = useState<NewUserInfoI>(userData || { idx: -1, name: '로딩중', travel_count: 0 });
  useEffect(() => {
    if (userData) setInfo(userData);
  }, [userData]);
  const [ShowModal, setShowModal] = useState<
    null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number}
  >(null);

  const onChange: NewUserInfoEditVIEWProps['onChange'] = (e) => {
    const { target } = e;
    if (!target) return;
    setInfo((t) => ({
      ...t,
      [(target as HTMLInputElement).name]: (target as HTMLInputElement).value,
    }));
  };

  const onEdit = async () => {
    const deleted = deleteBlank(deleteBlankTypeThis, Info);
    if (!deleted?.name) { alert('이름은 공란으로 둘 수 없습니다.'); return; }
    const data = await axios.patch<{statusCode: number}>(`/api/admin/offuser/${idx}`, deleted, getLoginConfig());
    const code = data.data.statusCode;
    if (code !== 201) {
      alert('오류가 발생했습니다.');
      return;
    }
    mutate();
    onMutate();
    alert('수정했습니다.');
  };

  const onTravelEdit = (travelIdx: number, info: TravelInfo) => {
    setShowModal({ isEdit: true, info, idx: travelIdx });
  };

  const AddTravelHandler = async (
    show: null | { isEdit: false } | { isEdit: true, info: TravelInfo, idx: number},
    info: TravelInfo,
  ) => {
    setShowModal(null);
    const request = show?.isEdit ? (
      axios.patch<{ statusCode: number }>(`/api/admin/offuser/travel/${show.idx}`, info, getLoginConfig())
    ) : (
      axios.post<{ statusCode: number }>('/api/admin/offuser/travel', {
        travel_name: info.travel_name, travel_date: info.travel_date, user_arr: [idx],
      }, getLoginConfig())
    );
    const data = await request;
    if (data?.data?.statusCode !== 201) {
      alert('오류가 발생했습니다.');
      return;
    }
    mutate();
    onMutate();
    alert(`여행기록을 ${show?.isEdit ? '수정' : '추가'}했습니다.`);
  };

  const onDeleteTravel = async (deleteIdx: number) => {
    if (!confirm('이 여행기록을 삭제하시겠습니까?')) return;
    const data = await axios.delete<{ statusCode: number }>(`/api/admin/offuser/travel/${deleteIdx}`, getLoginConfig());
    if (data?.data?.statusCode !== 201) {
      alert('오류가 발생했습니다.');
      return;
    }
    mutate();
    onMutate();
    alert('여행기록을 삭제했습니다.');
  };

  const subProps: NewUserInfoEditVIEWProps = {
    info: Info,
    onChange,
    onEdit,
    onTravelAdd: () => setShowModal({ isEdit: false }),
    dropdownData,
    onDeleteTravel,
    onTravelEdit,
  };
  return (
    <>
      <NewUserInfoEditVIEW {...subProps} />
      <ModalWrapper Show={ShowModal !== null} onShowOff={() => setShowModal(null)}>
        <AddTravel Show={ShowModal} handler={AddTravelHandler} />
      </ModalWrapper>
    </>
  );
}

export default NewUserInfoEdit;
