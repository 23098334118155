/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import DarkButton from '../../Components/DarkButton';
import ImageInput from '../../Components/ImageInput';
import LeftRight from '../../Components/LeftRight';
import useLogin from '../../Middle/useLogin';
import styles from '../../Css/GoodInfo.module.css';

function HotelInfoImage(props: { idx: number }) {
  const { idx } = props;
  const { getLoginConfig } = useLogin();
  const { data, mutate } = useSWR(`/api/hotel/${idx}/image`, async (url) => {
    const res = await axios.get<{
      result: {
        thumb: string, detail: {img_idx: number, img_url: string}[]
      }
    }>(url, getLoginConfig());
    return res.data.result;
  });
  if (!data) return <div />;
  return (
    <div className={`${styles.innerMain}`}>
      <LeftRight leftWidth={90}>
        <div>
          <div>
            <div>대표이미지</div>
            <div className={styles.imageDiv}>
              <img src={data.thumb} alt="" />
              <BlankMaker width={30} />
              <ImageInput onChange={async (e) => {
                if (!e.target.files) return;
                try {
                  const formData = new FormData();
                  formData.append('image', e.target.files[0]);
                  const res = await axios.post<{statusCode: number, result: string}>(`/api/hotel/${idx}/thumb-image`, formData, getLoginConfig());
                  if (res.data.statusCode !== 201) throw Error;
                  alert('이미지를 업로드했습니다.');
                  mutate();
                } catch {
                  alert('오류가 발생했습니다.');
                }
              }}
              >
                <DarkButton bg="#3386FF" width={140}>이미지 업로드</DarkButton>
              </ImageInput>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>상세이미지</div>
            <div className={styles.detailImageDiv}>
              <div>
                {
                  data.detail.map((d) => (
                    <div>
                      <img src={d.img_url} alt="" />
                      <DarkButton
                        width="100%"
                        onClick={async () => {
                          if (!confirm('삭제하시겠습니까?')) return;
                          try {
                            const res = await axios.delete<{statusCode: number}>(`/api/hotel/${idx}/detail-image/${d.img_idx}`, getLoginConfig());
                            if (res.data.statusCode !== 201) throw Error;
                            alert('삭제했습니다.');
                            mutate();
                          } catch {
                            alert('오류가 발생했습니다.');
                          }
                        }}
                      >
                        삭제

                      </DarkButton>
                    </div>
                  ))
                }
                <ImageInput onChange={async (e) => {
                  if (!e.target.files) return;
                  try {
                    const formData = new FormData();
                    formData.append('image', e.target.files[0]);
                    const res = await axios.post<{statusCode: number, result: string}>(`/api/hotel/${idx}/detail-image`, formData, getLoginConfig());
                    if (res.data.statusCode !== 201) throw Error;
                    alert('이미지를 업로드했습니다.');
                    mutate();
                  } catch {
                    alert('오류가 발생했습니다.');
                  }
                }}
                >
                  <DarkButton width="100%" height="100%" bg="#3386FF">이미지 업로드</DarkButton>

                </ImageInput>
              </div>
            </div>
          </div>
        </div>
      </LeftRight>
    </div>
  );
}

export default HotelInfoImage;
